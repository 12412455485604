import { useEffect, useRef, useState } from 'react';
import Offcanvas, { type OffcanvasPlacement } from 'react-bootstrap/Offcanvas';
import './OffCanvas.component.scss';

function OffCanvasComponent(props: {
  placement?: OffcanvasPlacement,
  show: boolean,
  header?: boolean,
  setShow?: () => void,
  title?: string,
  icon?: string,
  children: JSX.Element,
  backdrop?: string
  customClass?: string
}): JSX.Element {

  const [show, setShow] = useState(false);
  const offcanvasRef:any = useRef(null);

  const handleClickOutside = (event: { target: any; }): void => {
    if (event.target.closest('.offcanvas-backdrop')) {
      setShow(false);
    }
  };

  useEffect(() => {
    console.log(show)
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show])

  useEffect(() => {
    setShow(props.show)
  }, [props.show])


  return (
    <>

      {
        show &&
        <Offcanvas ref={offcanvasRef} show={props.show} backdrop={props.backdrop} placement={props.placement} className={props.customClass}>
          {
            props.header &&
            <Offcanvas.Header>
              <Offcanvas.Title>
                <div className="col-12">
                  <div className="row g-1">
                    {
                      props.icon &&
                      <div className="col-auto">
                        <span className={` ${props.icon} awake-mx-8`}></span>
                      </div>
                    }
                    {
                      props.title &&
                      <div className="col-auto">
                        <h6 className="awake-fw-700">
                          {props.title}
                        </h6>
                      </div>
                    }
                  </div>
                </div>
              </Offcanvas.Title>
            </Offcanvas.Header>
          }
          <Offcanvas.Body>
            {props.children}
          </Offcanvas.Body>
        </Offcanvas>
      }
    </>
  );
}

OffCanvasComponent.defaultProps = {
  placement: 'end',
  backdrop: 'static',
  header: true,
}

export default OffCanvasComponent;
