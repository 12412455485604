import type { ToastIconPropsInterface } from '@shared/components/toast/interfaces/ToastIconProps.interface';

function ToastIconComponent(props: ToastIconPropsInterface): JSX.Element {
    return (
        <div className="text-center">
            <div className="row awake-mt-10">
                <span className="icon-account_circle"></span>
            </div>
            <div className="row awake-mt-10">
                <label className="awake-heading-5 awake-text-secondary awake-fw-700">
                    {props.message}
                </label>
            </div>
        </div>
    );
}

export default ToastIconComponent;
