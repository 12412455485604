import imgOne from '@assets/value-proposal/value_proposition_image_one.png';
import imgOneMobile from '@assets/value-proposal/value_proposition_image_one-mobile.png';
import imgOneTablet from '@assets/value-proposal/value_proposition_image_one_tablet.png';
import imgTwo from '@assets/value-proposal/value_proposition_image_two.png';
import imgTwoMobile from '@assets/value-proposal/value_proposition_image_two_mobile.png';
import imgTwoTablet from '@assets/value-proposal/value_proposition_image_two_tablet.png';
import imgThree from '@assets/value-proposal/value_proposition_image_three.png';
import imgThreeMobile from '@assets/value-proposal/value_proposition_image_three_mobile.png';
import imgThreeTablet from '@assets/value-proposal/value_proposition_image_three_tablet.png';

import { Carousel } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useResponsive } from '@shared/services/utils/Utils.services';
import { Trans, useTranslation } from 'react-i18next';
import CollapseValueProposition from './components/CollapseValueProposition.component';
import { MockValueProposition } from './mocks/MockValuesProposition.component';
import './ValueProposition.component.scss';

const mockImg = [imgOne, imgTwo, imgThree];
const mockImgMobile = [imgOneMobile, imgTwoMobile, imgThreeMobile];
const mockImgTablet = [imgOneTablet, imgTwoTablet, imgThreeTablet];

export default function ValueProposition(): JSX.Element {
	const { isMobile, isTablet, isDesktop } = useResponsive();
	const { t } = useTranslation();
	const [activeIndex, setActiveIndex] = useState(0);
	const [autoplay, setAutoplay] = useState(true);
	const dataMockText = MockValueProposition(t, isMobile!, isTablet!);

	const handleSelect = (selectedIndex: number): void => {
		setActiveIndex(selectedIndex);
	};

	const handleDescriptionClick = (index: number): void => {
		setActiveIndex(index);
		setAutoplay(false);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			if (autoplay) {
				const newIndex = (activeIndex + 1) % mockImg.length;
				setActiveIndex(newIndex);
			}
		}, 10000);
		return () => clearInterval(interval);
	}, [activeIndex, autoplay]);

	return (
		<>
			<div className="container-position-relative">
				{isMobile && (
					<>
						<Carousel
							className="banner-carousel awake-ht-750"
							activeIndex={activeIndex}
							onSelect={handleSelect}
							interval={autoplay ? null : 10000}>
							{mockImgMobile.map((exp, index) => (
								<Carousel.Item key={index}>
									<img
										className="awake-cover-image w-100"
										src={exp}
										alt="slide"
										loading="lazy"
									/>
								</Carousel.Item>
							))}
						</Carousel>
						<div className="box-position-absolute">
							<div className="awake-mb-260">
								<h3 className="awake-text-secondary awake-fw-900 col-10 text-center">
									{dataMockText.main.title}
								</h3>
								<h6 className="awake-text-secondary awake-heading-6 col-11 text-center">
									{dataMockText.main.description}
								</h6>
							</div>
						</div>
						<div className="box-position-absolute-box">
							{dataMockText.collapseProposition.map((t, index) => (
								<div
									className=""
									key={index}
									onClick={() => handleDescriptionClick(index)}>
									<CollapseValueProposition
										props={{ title: t.title, description: t.description }}
										show={index === activeIndex}
									/>
								</div>
							))}
						</div>
					</>
				)}
				{isDesktop && (
					<>
						<Carousel
							className="banner-carousel"
							activeIndex={activeIndex}
							onSelect={handleSelect}
							interval={autoplay ? null : 10000}>
							{dataMockText.img.map((exp, index) => (
								<Carousel.Item key={index}>
									<img
										className="awake-cover-image w-100 awake-ht-700"
										src={exp.image}
										alt="slide"
										loading="lazy"
									/>
								</Carousel.Item>
							))}
						</Carousel>
						<div className="position-absolute top-0 start-50">
							<h2 className="awake-text-secondary awake-fw-900 awake-mt-90">
								{dataMockText.main.title}
							</h2>
							<h5 className="awake-text-secondary awake-heading-5 col-8">
								{dataMockText.main.description}
							</h5>

							<div className="">
								{dataMockText.collapseProposition.map((t, index) => (
									<div
										className=""
										key={index}
										onClick={() => handleDescriptionClick(index)}>
										<CollapseValueProposition
											props={{
												title: t.title,
												description: t.description
											}}
											show={index === activeIndex}
										/>
									</div>
								))}
							</div>
						</div>
					</>
				)}
				{isTablet && (
					<>
						<Carousel
							className="banner-carousel"
							activeIndex={activeIndex}
							onSelect={handleSelect}
							interval={autoplay ? null : 10000}>
							{mockImgTablet.map((exp, index) => (
								<Carousel.Item key={index}>
									<img
										className="awake-ht-700"
										src={exp}
										alt="slide"
										loading="lazy"
									/>
								</Carousel.Item>
							))}
						</Carousel>
						<div className="position-absolute top-0 start-50">
							<h2 className="awake-text-secondary awake-fw-900 awake-mt-90">
								{dataMockText.main.title}
							</h2>
							<h5 className="awake-text-secondary awake-heading-5 col-8">
								{dataMockText.main.description}
							</h5>

							<div className="">
								{dataMockText.collapseProposition.map((t, index) => (
									<div
										className=""
										key={index}
										onClick={() => handleDescriptionClick(index)}>
										<CollapseValueProposition
											props={{
												title: t.title,
												description: t.description
											}}
											show={index === activeIndex}
										/>
									</div>
								))}
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}
