import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import { ProfileAPIsEnum } from '@profile/enums/ProfileAPIs.enum';
import type { ProfileFormInterface } from '@profile/components/form-profile/interfaces/ProfileForm.interface';
import type {
	ProfileInfoUserData,
	ProfileUpdatedInfoInterface,
	DeletedUserAccountResponseInterface,
	ProfileInfoReferralsUserData,
	ProfileSendReferralInterface,
	CheckIfAnReferralCodeExistsInterface,
	ReferralInvitationByEmail	
} from '@profile/interface/ProfileUserData.interface';
import type { NotificationInfoUserData } from '@root/modules/profile/interface/ProfileNotification.interface';
import type { WishListInfoInterface } from '@profile/interface/ProfileSaveExperiences.interfaces';
import type { ChangePassProfileFormInterface } from '@profile/components/change-pass-profile/interfaces/ChangePassProfileForm.interface';
import type { DeleteAccountProfileFormInterface } from '@profile/components/delete-count-profile/interfaces/DeleteAccountProfileForm.interface';
import { sendRequest } from '@shared/services/request/Request.service';
import type { RequestInterface } from '@shared/services/request/interfaces/Request.interface';
import type { PageConfigurationInterface } from '@shared/interfaces/PageConfiguration.interface';
import type{ BookingsInfoUserData, OrdersInfoUserData } from '../interface/ProfileBooking.interface';

export const getUserProfile: () => Promise<
	ProfileInfoUserData | CatchErrorInterface
> = async (): Promise<ProfileInfoUserData | CatchErrorInterface> => {
	const data: RequestInterface = {
		url: ProfileAPIsEnum.UserProfileInfoAPIUrl,
		method: 'GET'
	};

	return await sendRequest(data);
};

export const putUserProfile: (
	profileData: ProfileFormInterface
) => Promise<ProfileUpdatedInfoInterface | CatchErrorInterface> = async (
	profileData: ProfileFormInterface
): Promise<ProfileUpdatedInfoInterface | CatchErrorInterface> => {
	const data: RequestInterface = {
		url: ProfileAPIsEnum.UserProfileInfoAPIUrl,
		method: 'PUT',
		body: profileData
	};

	return await sendRequest(data);
};

export const getUserRequestBookings: (
	page: number,
	currentPageConfiguration?: PageConfigurationInterface
) => Promise<BookingsInfoUserData | CatchErrorInterface> = async (
	page: number,
	currentPageConfiguration?: PageConfigurationInterface
): Promise<BookingsInfoUserData | CatchErrorInterface> => {
	const apiUrl = `${ProfileAPIsEnum.UserRequestBookingsAPIUrl}?page=${page}`;
	const data: RequestInterface = {
		url: apiUrl,
		method: 'GET',
		currentPageConfiguration
	};
	return await sendRequest(data);
};

export const getUserOrders: (
	page: number,
	currentPageConfiguration?: PageConfigurationInterface
) => Promise<OrdersInfoUserData | CatchErrorInterface> = async (
	page: number,
	currentPageConfiguration?: PageConfigurationInterface
): Promise<OrdersInfoUserData | CatchErrorInterface> => {
	const apiUrl = `${ProfileAPIsEnum.UserOrdersAPIUrl}?page=${page}`;
	const data: RequestInterface = {
		url: apiUrl,
		method: 'GET',
		currentPageConfiguration
	};
	return await sendRequest(data);
};

export const getUserNotifications: (
	page: number,
	currentPageConfiguration?: PageConfigurationInterface
) => Promise<NotificationInfoUserData | CatchErrorInterface> = async (
	page: number,
	currentPageConfiguration?: PageConfigurationInterface
): Promise<NotificationInfoUserData | CatchErrorInterface> => {
	const data: RequestInterface = {
		url: ProfileAPIsEnum.UserNotificationsAPIUrl,
		method: 'POST',
		body: { page },
		currentPageConfiguration
	};

	return await sendRequest(data);
};

export const getUserWishList: (
	page: number,
	currentPageConfiguration?: PageConfigurationInterface
) => Promise<WishListInfoInterface | CatchErrorInterface> = async (
	page: number,
	currentPageConfiguration?: PageConfigurationInterface
): Promise<WishListInfoInterface | CatchErrorInterface> => {
	const data: RequestInterface = {
		url: ProfileAPIsEnum.UserGetWishListAPIUrl,
		method: 'POST',
		body: { page },
		currentPageConfiguration
	};

	return await sendRequest(data);
};

export const putChangePassword: (
	formData: ChangePassProfileFormInterface
) => Promise<ProfileUpdatedInfoInterface | CatchErrorInterface> = async (
	formData: ChangePassProfileFormInterface
): Promise<ProfileUpdatedInfoInterface | CatchErrorInterface> => {
	const data: RequestInterface = {
		url: ProfileAPIsEnum.UserGetWishListAPIUrl,
		method: 'PUT',
		body: { formData }
	};

	return await sendRequest(data);
};

export const deleteUserAccount: (
	formData: DeleteAccountProfileFormInterface
) => Promise<DeletedUserAccountResponseInterface | CatchErrorInterface> = async (
	formData: DeleteAccountProfileFormInterface
): Promise<DeletedUserAccountResponseInterface | CatchErrorInterface> => {
	const data: RequestInterface = {
		url: ProfileAPIsEnum.UserAccountDeleteUrl,
		method: 'DELETE',
		body: { formData }
	};

	return await sendRequest(data);
};

export const toggleNotificationRead: (
	id: number
) => Promise<ProfileUpdatedInfoInterface | CatchErrorInterface> = async (
	id: number
): Promise<ProfileUpdatedInfoInterface | CatchErrorInterface> => {
	const data: RequestInterface = {
		url: ProfileAPIsEnum.UserToggleNotificationUrl.replace('{id}', id.toString()),
		method: 'PUT'
	};

	return await sendRequest(data);
};

export const getUserReferrals: () => Promise<
	ProfileInfoReferralsUserData | CatchErrorInterface
> = async (): Promise<ProfileInfoReferralsUserData | CatchErrorInterface> => {
	const urlApi = 'users/referrals';
	const data: RequestInterface = {
		url: urlApi,
		method: 'GET'
	};
	return await sendRequest(data);
};

export const SendReferralInvitationByEmail: (
	emails: string[]
) => Promise<ReferralInvitationByEmail | CatchErrorInterface> = async (
	emails: string[]
): Promise<ReferralInvitationByEmail | CatchErrorInterface> => {
	const urlApi = 'users/referrals';
	const data: RequestInterface = {
		url: urlApi,
		method: 'POST',
		body: { emails }
	};
	return await sendRequest(data);
};

export const getUserReferralsCodeExists: (
	code: string
) => Promise<CheckIfAnReferralCodeExistsInterface | CatchErrorInterface> = async (
	code: string
): Promise<CheckIfAnReferralCodeExistsInterface | CatchErrorInterface> => {
	const apiUrlCode = `users/referrals/${code}`;
	const data: RequestInterface = {
		url: apiUrlCode,
		method: 'GET'
	};
	return await sendRequest(data);
};


