import type { FormValidationsInterface } from '@shared/interfaces/FormValidation.interface';

export const onlyLetter: FormValidationsInterface = {
    value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/
};

export const onlyAlphanumeric: FormValidationsInterface = {
    value: /^[A-Za-z0-9u\s]+$/
};
export const emailFormatValidation: FormValidationsInterface = {
    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};
export const numeric: FormValidationsInterface = {
    value: /^\d+$/
};

export const phone: FormValidationsInterface = {
    value: /^(((\+|00)[1-9+]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
};

export const passwordFormat: FormValidationsInterface = {
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/
};
