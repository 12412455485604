export enum FiltersItemsEnum {
    calendarDate = 'calendarDate',
    monthDate = 'monthDate',
    favorites = 'favorites',
    typeOfExperiences = 'typeOfExperiences',
    destination = 'destination',
    durationOfTravel = 'durationOfTravel',
    rangeOfPrice = 'rangeOfPrice',
    quantityOfTravelers = 'quantityOfTravelers',
    seals = 'seals',
    ecosystems = 'ecosystems',
    physicalDemand = 'physicalDemand',
    page = 'page',
    countFilters = 'count'
}
