import ClockLoader from 'react-spinners/ClipLoader';
import { LoadingSpinnerEnum } from '@shared/components/loading/enums/LoadingSpinner.enum';
import '@shared/components/loading/LoadLoading.component.scss';

function LoadLoadingComponent(): JSX.Element {
    return (
        <div className="loading-spinner-component awake-py-15 awake-px-700 awake-pt-200">
            <ClockLoader color={LoadingSpinnerEnum.color} size={500}></ClockLoader>
        </div>
    );
}

export default LoadLoadingComponent;
