import '@auth/Auth.page.scss';
import HeaderComponent from '@shared/components/header/Header.component';
import FooterComponent from '@shared/components/footer/Footer.component';
import ChangePasswordComponent from '@auth/components/change-password/ChangePassword.component';
import { useContext, useEffect, useState } from 'react';
import { AuthLabelsEN, AuthLabelsES } from '@auth/labels/Auth.labels';
import { AppContext } from '@shared/context/App.context';
import {
    FormValidationsLabelsEN,
    FormValidationsLabelsES
} from '@shared/labels/FormValidations.labels';
import { defaultPageConfigs } from '@shared/services/set-configs/defaultPageConfigs';

function ChangePasswordPage(): JSX.Element {
    const [authLabels, setAuthLabels] = useState(AuthLabelsES);
    const [formValidationsLabels, setFormValidationsLabels] = useState(FormValidationsLabelsES);
    const { appContextValue } = useContext(AppContext);

    useEffect(() => {
        if (
            appContextValue.currentPageConfiguration?.language.code ===
            defaultPageConfigs.language.code
        ) {
            setAuthLabels(AuthLabelsES);
            setFormValidationsLabels(FormValidationsLabelsES);
        } else {
            setAuthLabels(AuthLabelsEN);
            setFormValidationsLabels(FormValidationsLabelsEN);
        }
    }, [
        appContextValue.currentPageConfiguration
    ]);

    return (
        <div className="change-password-page">
            <ChangePasswordComponent
                authLabels={authLabels}
                formValidationsLabels={formValidationsLabels}></ChangePasswordComponent>
        </div>
    );
}

export default ChangePasswordPage;
