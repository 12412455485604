import img from '@assets/common/img-sms-wa.png';
import { HomeLabelsEN, HomeLabelsES } from '@root/modules/home/labels/Home.labels';
import { WhastAppFormValidations } from '@shared/components/whats-app-button/validations/WhastAppForm.validations';
import { AppContext } from '@shared/context/App.context';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import {
	FormValidationsLabelsEN,
	FormValidationsLabelsES
} from '@shared/labels/FormValidations.labels';
import { Start as StartConversation } from '@shared/services/conversation/Conversation.service';
import { type StartConversationResponse } from '@shared/services/conversation/interfaces/StartConversation.interface';
import { defaultPageConfigs } from '@shared/services/set-configs/defaultPageConfigs';
import { isACatchError } from '@shared/services/utils/Utils.services';
import { Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const WhatsAppVisibilityComponent = (props: { response: boolean, message?: string | undefined, placeholder?: string, messageToSend?: string }): JSX.Element => {
	const [homeLabels, setHomeLabels] = useState(HomeLabelsES);
	const [formValidationsLabels, setFormValidationsLabels] = useState(FormValidationsLabelsES);
	const { appContextValue, setAppContextMethod } = useContext(AppContext);
	const notify = (): any => toast.success(homeLabels.whatsApp.notify.success);
	const [response, setResponse] = useState(props.response);
	const params = useParams();
	const { t } = useTranslation();

	const handleClickClose = (): void => {
		sessionStorage.setItem('showWhatsAppMessage', '0');
		setAppContextMethod({
			showWhatsAppMessage: false
		});
	};

	const getMessage = (): string | undefined => {
		if (props.message !== undefined) {
			return props.message
		} else {
			return t('whatsapp.others')
		}

	};

	useEffect(() => {
		if (
			appContextValue.currentPageConfiguration?.language.code ===
			defaultPageConfigs.language.code
		) {
			setHomeLabels(HomeLabelsES);
			setFormValidationsLabels(FormValidationsLabelsES);
		} else {
			setHomeLabels(HomeLabelsEN);
			setFormValidationsLabels(FormValidationsLabelsEN);
		}
	}, [appContextValue.currentPageConfiguration?.language]);


	return (
		<>
			<div
				className={
					window.innerWidth < 1290 && window.innerWidth > 800
						? 'box-shadow awake-wt-250 awake-ht-380 awake-bg-section-background awake-br-8 awake-pb-20'
						: 'box-shadow awake-wt-341 awake-bg-section-background awake-br-8 awake-pb-20'
				}>
				<div
					className={
						window.innerWidth < 1290 && window.innerWidth > 800
							? 'row awake-bg-white awake-wt-250 awake-ht-44 awake-mb-20 background-table-one'
							: 'row awake-bg-white awake-wt-342 awake-ht-44 awake-mb-20 background-table-one'
					}>
					<div className="col-10 container_wh awake-pt-5 awake-px-32 awake-mb-20">
						<img className="awake-br-50" src={img} alt="awake" />
						<div className="awake-px-5 d-flex flex-column">
							<label className="awake-heading-5 awake-fw-700">
								{homeLabels.whatsApp.nameAwake}
							</label>
							<label className="awake-heading-7 firts-box awake-fw-700">
								{homeLabels.whatsApp.onLine}
							</label>
						</div>
					</div>
					<div className="col-2 awake-pt-2 awake-fw-700 awake-colors-white">
						<button className="btn-close-whatsapp" onClick={handleClickClose}>
							{homeLabels.whatsApp.close}
						</button>
					</div>
				</div>
				<div
					className={
						window.innerWidth < 1290 && window.innerWidth > 800
							? ''
							: 'awake-px-30 awake-py-30 awake-pt-10 awakep-b-20'
					}>
					<Formik
						initialValues={{
							name: '',
							phone: '',
							message: getMessage()
						}}
						validationSchema={WhastAppFormValidations(formValidationsLabels)}
						onSubmit={({ name, phone, message }, { resetForm }) => {
							if (props.messageToSend) {
								StartConversation({ name, phone, destination: props.messageToSend })
									.then((result: StartConversationResponse | CatchErrorInterface) => {
										if (!isACatchError(result)) {
											notify();
											handleClickClose();
										} else {
											window.open(homeLabels.whatsApp.whatsAppMessage);
											handleClickClose();
										}
									})
									.catch(() => {
										setResponse(false);
									});
								resetForm();
							}
						}}>
						{({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
							<form onSubmit={handleSubmit}>
								<div className="form-floating mb-3">
									<input
										type="text"
										className="form-control"
										id="name"
										name="name"
										value={values.name}
										placeholder="Nombre"
										onChange={handleChange}
										onBlur={handleBlur}
										required
									/>

									{touched.name && errors.name && (
										<>
											<p className="awake-pt-14 errors_inputs awake-heading-6">
												{errors.name}
											</p>
										</>
									)}
									<label>
										<span className="icon-person"> </span>
										{homeLabels.whatsApp.name}
									</label>
								</div>
								<div className="form-floating mb-3">
									<PhoneInput
										country={'co'}
										value={values.phone}
										onChange={(...params) => {
											const e = params[2];
											handleChange(e);
											handleBlur(e);
										}}
										placeholder="Phone"
										inputClass={
											window.innerWidth < 1290 && window.innerWidth > 800
												? 'awake-wt-228 awake-ht-55'
												: 'awake-wt-255 awake-ht-55'
										}
										inputProps={{
											name: 'phone',
											require
										}}
									/>
									{touched.phone && errors.phone && (
										<>
											<p className="awake-pt-14 errors_inputs awake-heading-6">
												{errors.phone}
											</p>
										</>
									)}
								</div>
								<div className="form-floating mb-3">
									<input
										type="text"
										className="form-control"
										id="message"
										name="message"
										value={getMessage()}
										placeholder="Ballenas"
										onChange={handleChange}
										onBlur={handleBlur}
										required
									/>
									<label className="destination-one">
										<span className="icon-favorite"> </span>
										{props.placeholder ?? homeLabels.whatsApp.destination}
									</label>
								</div>
								<h6 className="awake-secondary awake-heading-6">
									{homeLabels.whatsApp.sms}
								</h6>
								{!isACatchError ? (
									<button
										id="contact-by-whastapp"
										className="awake-button awake-primary-button awake-fw-700 awake-ms-auto awake-me-auto">
										{homeLabels.whatsApp.btn}
									</button>
								) : (
									<button
										id="contact-by-whastapp"
										type="submit"
										className="awake-button awake-primary-button awake-fw-700 awake-ms-auto awake-me-auto">
										{homeLabels.whatsApp.btn}
									</button>
								)}
							</form>
						)}
					</Formik>
				</div>
			</div>
		</>
	);
};
