export enum SessionStorageKeys {
    sessionStorageKey = 'sessionStorage',
    authUser = 'authUser',
    accessToken = 'accessToken',
    authToken = 'authToken',
    tokenType = 'tokenType',
    expiresIn = 'expiresIn',
    username = 'username',
    email = 'email',
    quoterInfo = 'quoterInfo',
    sessionStorageClientKey = 'sessionStorageClient',
    giftCardBannerClosed = 'giftCardBannerClosed',
    feedbackSaved = 'feedbackSaved'
}

export enum Storage {
    Local,
    Session
}