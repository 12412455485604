import type { HomeLabelsInterface } from '@home/interfaces/HomeLabels.interface';
import travelersImg from '@assets/home/impact/travellers.png';
import hostsImg from '@assets/home/impact/hosts.png';
import natureImg from '@assets/home/impact/nature.png';

export const HomeLabelsES: HomeLabelsInterface = {
    promotionTemporary: {
        title: 'Recibe 5% de descuento, en tu primera reserva por la página web',
        button: '🎁 Inicia sesión',
        btnClose: 'X',
    },
    banner: {
        subtitle: 'a través de anfitriones locales',
        buttonText: 'Ver todas las experiencias',
        searchPlaceholder: 'Escribe un destino',
    },
    categories: {
        search: 'Filtra tu búsqueda',
    },
    knowExperience: {
        title: 'Conoce las experiencias de otros',
        subtitle: 'Se parte de nuestra comunidad, etiqueta tus increíbles fotos con el #awaketravel',
        starLabel: 'Estrellas',
    },
    promotions: {
        subtitle: 'Conoce increíbles destinos naturales con nuestros grupos confirmados.',
        comments: 'comentarios',
        copMoney: 'COP',
        buttonText: 'Ver todas las experiencias'
    },
    joinGroup: {
        title: 'Únete al grupo',
        subtitle: 'Conoce increíbles destinos naturales con nuestros grupos confirmados.',
        buttonText: 'Ver todas las experiencias'
    },
    discover: {
        title: 'Descubre Colombia',
        comments: 'comentarios',
        copMoney: 'COP',
        buttonText: 'Ver todas las experiencias del destino'
    },
    impact: {
        title: 'Existimos para proteger la naturaleza',
        data: [
            {
                title: 'Viajeros',
                paragraph: 'Conectamos a viajeros con la naturaleza y conservación',
                image: travelersImg,
            },
            {
                title: 'Anfitriones',
                paragraph: 'Empoderamos negocios impactándolos social, ambiental y económicamente.',
                image: hostsImg,
            },
            {
                title: 'Naturaleza',
                paragraph: 'Potenciamos acciones de conservación y restauración',
                image: natureImg,
            },
        ],
    },
    LookingHosts: {
        title: 'Buscamos anfitriones',
        subtitle: 'EXTRAORDINARIOS',
        paragraph:
            'Conoces o eres un anfitrión local con una propuesta turística, pulsa el botón y contáctate con un agente Awake para empezar tu proceso.',
        button: 'Quiero saber más',
        url: 'https://awake.travel/files/19/PDF%20Anfitriones%20pagina.pdf',
    },
    whatsApp:{
        whatsAppMessage: `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_AWAKE_MAIN_PHONE_NUMBER}&text=¡Hola!+%c2%bfMe+puedes+ayudar+con+más+información+sobre+las+experiencias+de+la+página+web+%3f`,
        messageContactAdvisor: 'Comunicate con un asesor',
        messageClickHere:'Pulsando aquí',
        close:'X',
        name:'*Nombre',
        NumberPhone: '*Número de celular',
        destination:'Destino de interés',
        btn:'Iniciar conversación',
        sms:'Al pulsar iniciar estás aceptando nuestros términos y condiciones',
        nameAwake:'Awake',
        onLine:'En linea',
        notify: {
            success: 'Hemos enviado un mensaje por WhatsApp',
            error: 'hubo un error, intenta de nuevo en el botón de WhatsApp'
        }

    }
};

export const HomeLabelsEN: HomeLabelsInterface = {
    promotionTemporary: {
        title: 'Receive a 5% discount on your first booking on the website.',
        button: '🎁 Log in',
        btnClose: 'X',
    },
    banner: {
        subtitle: 'through local hosts',
        buttonText: 'See all experiences',
        searchPlaceholder: 'Type a destination',
    },
    categories: {
        search: 'Filter your search'
    },
    knowExperience: {
        title: 'Get to know the experiences of others',
        subtitle: 'Be part of our community, tag your amazing photos with #awaketravel',
        starLabel: 'Stars',
    },
    promotions: {
        subtitle: 'Get to know incredible natural destinations with our confirmed groups.',
        comments: 'comments',
        copMoney: 'USS',
        buttonText: 'See all experiences'
    },
    joinGroup: {
        title: 'Join the group',
        subtitle: 'Get to know incredible natural destinations with our confirmed groups.',
        buttonText: 'See all experiences'
    },
    discover: {
        title: 'Discover Colombia',
        comments: 'comments',
        copMoney: 'USS',
        buttonText: 'See all destination experiences'
    },
    impact: {
        title: 'We exist to protect nature',
        data: [
            {
                title: 'Travelers',
                paragraph: 'Connecting travelers to nature and conservation',
                image: travelersImg,
            },
            {
                title: 'Hosts',
                paragraph: 'We empower businesses by impacting them socially, environmentally and economically.',
                image: hostsImg,
            },
            {
                title: 'Nature',
                paragraph: 'We promote conservation and restoration actions',
                image: natureImg,
            },
        ],
    },
    LookingHosts: {
        title: 'We are looking for hosts',
        subtitle: 'EXTRAORDINARY',
        paragraph:
            'If you know or are a local host with a tourism proposal, click the button and contact an Awake agent to start your process.',
        button: 'I want to know more',
        url: 'https://awake.travel/files/19/PDF%20Anfitriones%20pagina.pdf',
    },
    whatsApp:{
        whatsAppMessage: `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_AWAKE_MAIN_PHONE_NUMBER}&text=Hello!+%c2%bfCan+you+help+me+with+more+information+about+the+experiences+of+the+website+%3f`,
        messageContactAdvisor: 'Contact a consultant',
        messageClickHere:'Click here',
        close:'X',
        name:'*Name',
        NumberPhone: '*Number Phonne',
        destination:'Whales',
        btn:'Start a conversation',
        sms:'By clicking start you agree to our terms and conditions.',
        nameAwake:'Awake',
        onLine:'Online',
        notify: {
            success: 'We have sent a message by WhatsApp',
            error: 'there was an error, try again on WhatsApp button'
        }
    }
};
