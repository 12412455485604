import imgBanner from '@assets/landingProposed/banner-mobile.png';
import logo from '@assets/landingProposed/logo.png';
import imgScope from '@assets/landingProposed/scope.png';
import imgIconBuild from '@assets/landingProposed/iconBuild.png';
import imgIconconnection from '@assets/landingProposed/iconConnection.png';
import imgIconEco from '@assets/landingProposed/iconEco.png';
import imgLivingArrangements from '@assets/landingProposed/living-arrangements.png';
import imgIconLivingArrangements from '@assets/landingProposed/icon-living.png';
import imgFollowUp from '@assets/landingProposed/acoustic-monitoring.png';
import imgcameraTrap from '@assets/landingProposed/camera-trap.png';
import imgForestMonitoring from '@assets/landingProposed/forest-monitoring.png';
import imgManifest from '@assets/landingProposed/manifest.png';
import imgCollage from '@assets/landingProposed/collage.png';
import imgIcoEyes from '@assets/landingProposed/icon-eyes.png';
import imgIconSatellite from '@assets/landingProposed/icon-satelite.png';
import imgIconAudio from '@assets/landingProposed/icon-audio.png';

const data = [
	{
		bannerLandingProposed: {
			imgen: imgBanner,
			log: logo,
			title: 'landingProposed.banner.title',
			titleDescktop: 'landingProposed.banner.titleDescktop',
			description: 'landingProposed.banner.description'
		},
		scope: {
			imgScopes: imgScope,
			title: 'landingProposed.scope.title',
			description: 'landingProposed.scope.description'
		},
		cardLandingProposed: [
			{
				icon: imgIconBuild,
				title: 'landingProposed.cardLandingProposedOne.title',
				description: 'landingProposed.cardLandingProposedOne.description',
				contentType: 'video',
				contentSrc: 'https://www.youtube.com/watch?v=WJEhRa-ZJCQ&t=3s'
			},
			{
				icon: imgIconconnection,
				title: 'landingProposed.cardLandingProposedTwo.title',
				description: 'landingProposed.cardLandingProposedTwo.description',
				contentType: 'image',
				contentSrc: imgCollage
			},
			{
				icon: imgIconEco,
				title: 'landingProposed.cardLandingProposedThree.title',
				description: 'landingProposed.cardLandingProposedThree.description',
				contentType: 'video',
				contentSrc: 'https://www.youtube.com/watch?v=WJEhRa-ZJCQ&t=3s'
			}
		],
		livingArrangements: {
			img: imgLivingArrangements,
			title: 'landingProposed.livingArrangements.title',
			description: 'landingProposed.livingArrangements.description',
			icon: imgIconLivingArrangements,
			text: 'landingProposed.livingArrangements.text'
		},
		followUps: [
			{
				icon: imgIcoEyes,
				title: 'landingProposed.followUpsOne.title',
				description: 'landingProposed.followUpsOne.description',
				img: imgcameraTrap,
				position: 'right'
			},
			{
				icon: imgIconSatellite,
				title: 'landingProposed.followUpsTwo.title',
				description: 'landingProposed.followUpsTwo.description',
				img: imgForestMonitoring,
				position: 'left'
			},
			{
				icon: imgIconAudio,
				title: 'landingProposed.followUpsThree.title',
				description: 'landingProposed.followUpsThree.description',
				img: imgFollowUp,
				position: 'right'
			}
		],
		manifest: {
			title: 'landingProposed.manifest.title',
			img: imgManifest,
			list: [
				{
					description: 'landingProposed.manifest.descriptionOne'
				},
				{
					description: 'landingProposed.manifest.descriptionTwo'
				},
				{
					description: 'landingProposed.manifest.descriptionThree'
				}
			]
		}
	}
];

export default data;