export enum AuthAPIsEnum {
    AuthEmailPasswordAPIsUrl = 'auth/login',
    AuthWithFacebookAPIsUrl = 'auth/facebook',
    AuthWithGoogleAPIsUrl = 'auth/google',
    AuthWithGoogleTapAPIsUrl = 'auth/google/tap',
    AuthPageWithBackUrl = 'auth/token',
    RegisterUserUrl = 'users/register',
    recoverUserPasswordUrl = 'users/password/recovery',
    changeUserPasswordUrl = 'users/password/reset',
    userExist = 'users/exists',
    AuthPhoneAPIsUrl = 'users/profile/phone'
}
