import type { AuthLabelsInterface } from '@root/modules/auth/interfaces/AuthLabels.interface';

export const AuthLabelsES: AuthLabelsInterface = {
    title: '¡Bienvenido! Inicia fácil, seguro y rápido',
    subtitle:
        'Únete a la comunidad que vive y protege la naturaleza, además de acceder a beneficios increíbles.',
    startWithGoogle: 'Inicia con Google',
    userAlreadyExists:
        'Ya existe una cuenta con esta dirección de correo. Por favor, inicia sesión directamente con tu correo y contraseña.',
    startWithFacebook: 'Inicia con Facebook',
    start: 'Inicia sesión',
    email: '*Correo electrónico',
    emailPlaceholder: 'ejemplo@gmail.com',
    password: '*Contraseña',
    passwordPlaceholder: 'Awake123',
    forgotPassword: '¿Has olvidado tu contraseña?',
    rememberPassword: 'Recordar contraseña',
    createAccountOption: 'Crear una cuenta nueva',
    createAccountTitle: 'Crea una cuenta Awake',
    createAccountSubTitle: '¡Únete a Awake y vive la Naturaleza!',
    withGoogle: 'Con Google',
    withFacebook: 'Con Facebook',
    orFillTheNextFields: 'O diligencia la siguiente información:',
    acceptTermsFirstText: 'Acepto los',
    acceptTerms: 'términos y condiciones',
    acceptTermsUrl: 'https://awake.travel/terminosycondiciones',
    iAmHots: 'Soy anfitrión Awake',
    confirmEmail: '*Confirmar correo electronónico',
    confirmEmailPlaceholder: 'ejemplo@gmail.com',
    completeName: '*Nombre y apellido',
    completeNamePlaceholder: 'Maria Camila Rodriguez Gomez',
    phone: '*Número de celular',
    phonePlaceholder: '310 555 5555',
    recoverYourAccount: 'Recupera tu cuenta',
    doNotWorryInfo:
        '¡No te preocupes! ingresa tu correo electrónico, pulsa enviar y te llegará un correo para generar una nueva contraseña.',
    changeYourPasswordTitle: 'Cambia tu contraseña',
    changePasswordLinkInfo:
        'Este link esta activado solo por 1 hora, pasado este tiempo tienes que solicitar uno nuevo.',
    newPasswordPlaceholder: '*Contraseña nueva',
    newPasswordConfirmPlaceholder: '*Confirmar contraseña nueva',
    createAccountButtonLabel: 'Crear cuenta',
    updateButtonLabel: 'Actualizar',
    sendButtonLabel: 'Enviar',
    nextButtonLabel: 'Siguiente',
    passwords: 'Las contraseñas',
    emails: 'Los correos',
    digits: 'dígitos',
    loginSuccess: 'Te extrañamos ',
    recoverSuccess: 'Se ha enviado un correo electrónico, revisa tu bandeja de entrada',
    welcome: 'Bienvenido a la comunidad Awake',
    recoverSuccessTitle: 'Tu contraseña ha sido actualizada con éxito',
    recoverSuccessMessage:
        'Inicia sesión con tu nueva contraseña y descubre de experiencias increíbles',
    smsErrorLoginWhasgtApp:'Ya existe una cuenta con esta direccion de correo, inicia sesión con tu correo y contraseña'    
};

export const AuthLabelsEN: AuthLabelsInterface = {
    title: '¡Welcome! Start planning your trip.',
    subtitle: 'Join this community that protects nature, and access amazing benefits..',
    startWithGoogle: 'Start with Google',
    userAlreadyExists:
        'An account with this email address already exists. Please log in directly with your email and password',
    startWithFacebook: 'Start with Facebook',
    start: 'Sign in',
    email: 'Email',
    emailPlaceholder: 'example@gmail.com',
    password: '*Password',
    passwordPlaceholder: 'Awake123',
    forgotPassword: '¿Have you forgotten your password?',
    rememberPassword: 'Remember password',
    createAccountOption: 'Create a new account',
    createAccountTitle: 'Create an Awake account',
    createAccountSubTitle: '¡Join Awake and experience Nature!',
    withGoogle: 'With google',
    withFacebook: 'With Facebook',
    orFillTheNextFields: 'Or fill out the following information:',
    acceptTermsFirstText: 'Accept all',
    acceptTerms: 'terms and conditions',
    acceptTermsUrl: 'https://awake.travel/terminosycondiciones',
    iAmHots: 'I am host Awake',
    confirmEmail: '*Confirm your email address',
    confirmEmailPlaceholder: 'example@gmail.com',
    completeName: '*Name and last name',
    completeNamePlaceholder: 'Matthew Murphy',
    phone: '*Phone number',
    phonePlaceholder: '218 555 5555',
    recoverYourAccount: 'recover your account',
    doNotWorryInfo:
        '¡Do not worry! enter your email address, press send and you will receive an email to generate a new password.',
    changeYourPasswordTitle: 'Change your password',
    changePasswordLinkInfo:
        'This link is only activated for 24 hours, after this time you have to request a new one.',
    newPasswordPlaceholder: '*New password',
    newPasswordConfirmPlaceholder: '*Confirm your new password',
    createAccountButtonLabel: 'Create Account',
    updateButtonLabel: 'Update',
    sendButtonLabel: 'Send an invitation',
    nextButtonLabel: 'Next',
    passwords: 'Passwords',
    emails: 'Emails',
    digits: 'digits',
    loginSuccess: 'Miss you ',
    recoverSuccess: 'An email has been sent, check your inbox',
    welcome: 'Welcome to Awake community',
    recoverSuccessTitle: 'Your password has been successfully updated',
    recoverSuccessMessage: 'Log in with your new password and discover incredible experience',
    smsErrorLoginWhasgtApp:'An account already exists with this email address, please log in with your email and password.'
};
