import type {
	RequestInterface,
	RequestOptionsInterface
} from '@shared/services/request/interfaces/Request.interface';
import { SessionStorageKeys } from '@shared/enums/SessionStorageKeys.enum';
import { fromFetch } from 'rxjs/fetch';
import { catchError, of, switchMap, firstValueFrom } from 'rxjs';
import {
	getSessionStorageClientItem,
	getAuthSession,
	setClientAuth
} from '../storage/SessionStorage.service';
import { authPageWithBack } from '@shared/services/auth-service/Auth.service';
import { getLangCode, isACatchError } from '@shared/services/utils/Utils.services';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import type { AuthPageResponseInterface } from '@shared/services/auth-service/interfaces/AuthPageResult.interface';
import { defaultPageConfigs } from '@shared/services/set-configs/defaultPageConfigs';

export const sendRequest = async (data: RequestInterface): Promise<any> => {
	const requestOptions: RequestOptionsInterface = {
		method: data.method,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Accept-Language': getLangCode(),
			'Accept-Currency': data.currentPageConfiguration?.currency
				? data.currentPageConfiguration?.currency.code
				: defaultPageConfigs.currency.code,
			Authorization: await getAccessToken()
		},
		signal: data.signal
	};

	if (data.body) {
		requestOptions.body = JSON.stringify(data.body);
	}

	const apiUrl = process.env.REACT_APP_SERVER_URL!;

	return await firstValueFrom(
		fromFetch(apiUrl + data.url, requestOptions).pipe(
			switchMap(async (response) => {
				if (response.ok) {
					return await response.json();
				} else {
					return { error: true, message: await response.json() };
				}
			}),
			catchError((err) => {
				console.error(err);
				return of({ error: true, message: err.message });
			})
		)
	);
};

export async function getAccessToken(): Promise<string> {
	if (accessTokenIsValid()) {
		return getCurrentAccessToken();
	}
	const returnVal: CatchErrorInterface | AuthPageResponseInterface = await firstValueFrom(
		authPageWithBack()
	);
	if (!isACatchError(returnVal)) {
		setClientAuth(returnVal.data);
		return returnVal.data.token_type + ' ' + returnVal.data.access_token;
	} else {
		console.error(returnVal.error);
		return '';
	}
}

function getCurrentAccessToken(): string {

	return getAuthSession()
		? getAuthSession().tokenType + ' ' + getAuthSession().accessToken
		: getSessionStorageClientItem(SessionStorageKeys.tokenType) +
				' ' +
				getSessionStorageClientItem(SessionStorageKeys.accessToken);
}

function accessTokenIsValid(): boolean {
	return getAuthSession()
		? getAuthSession().expiresIn > Date.now()
		: getSessionStorageClientItem(SessionStorageKeys.expiresIn) > Date.now();
}
