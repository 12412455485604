import type { InputFinderLabelsInterface } from '@input-finder/interfaces/InputFinderLabels.interface';
import '@input-finder/InputFinder.component.scss';
import { DebounceInput } from 'react-debounce-input';
import { type DestinationsInfoDataInterface } from '@shared/interfaces/DestinationsInfo.interface';

function InputFinderComponent(props: InputFinderLabelsInterface): JSX.Element {

	return (
		<div className='nav-link input-finder__container awake-ms-18'>
			<span
				className='input-finder__container__icon icon-search awake-ms-13 awake-mt-11'></span>
			<DebounceInput
				value={props.inputInitialValue}
				debounceTimeout={300}
				placeholder={props.inputPlaceholder}
				onChange={(e) => {
					props.setInputSearchValue(e.target.value);
				}}
				className='input-finder__container__input form-control form-control-sm awake-ht-38 awake-br-8 awake-heading-6 awake-fw-700'
				type='text'></DebounceInput>
			<div
				className={` ${props.destinationsList?.length ? 'input-finder__container__list__overflow' : ''
					} awake-bg-white awake-br-8 input-finder__container__list position-absolute`}>
				{props.destinationsList?.map(
					(destination: DestinationsInfoDataInterface, index: number) => (
						<>
							{props.type === 'filter' ? (
								<a
									onClick={() => {
										if (props.setDestinationValue) {
											props.setDestinationValue(destination.url, destination.name);
										}
									}}
									className='text-start awake-ps-8 awake-pt-6 awake-pb-6 awake-pe-8 input-finder__container__list__item'
									key={index}
								>
									<label className='awake-mb-0 input-finder__container__list__item__text text-nowrap awake-heading-6 awake-fw-700 awake-text-complementary awake-cursor-pointer'>
										{destination.name}
									</label>
								</a>
							) : (
							<div></div>
							)}
						</>

					)
				)}
			</div>
		</div>
	);
}

export default InputFinderComponent;
