import { useResponsive } from '@shared/services/utils/Utils.services';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

const CardLandingProposed = ({
	icon,
	title,
	description,
	children
}: {
	icon: string;
	title: string;
	description: string;
	children?: JSX.Element;
}): JSX.Element => {
	const { isMobile, isTablet, isDesktop } = useResponsive();
	const { t } = useTranslation();

	const titleCard = t(title);
	const descriptionCard = t(description);
	return (
		<>
			{!isDesktop && (
				<div>
					<div className="p-4">
						<div className="d-flex justify-content-center align-items-center m-4">
							<img className="w-25" src={icon} alt="" />
						</div>

						<h1
							className="awake-heading-2 awake-fw-700 text-start"
							dangerouslySetInnerHTML={{ __html: titleCard }}></h1>

						<h5
							className="awake-heading-5 awake-text-secondary"
							dangerouslySetInnerHTML={{ __html: descriptionCard }}></h5>
					</div>

					{children}
				</div>
			)}
			{isDesktop && (
				<div className="row g-0 mt-4">
					{children?.type === ReactPlayer ? (
						<>
							<div className="d-flex justify-content-center align-items-center mt-4 ">
								<div className="col-5 ">{children}</div>
								<div className="col-5 ms-4">
									<div className="d-flex justify-content-center align-items-center m-4">
										<img className="awake-wt-67" src={icon} alt="" />
									</div>
									<h1
										className="awake-heading-2 awake-fw-700 text-start"
										dangerouslySetInnerHTML={{ __html: titleCard }}></h1>

									<h5
										className="awake-heading-5 awake-text-secondary"
										dangerouslySetInnerHTML={{ __html: descriptionCard }}></h5>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="d-flex justify-content-center align-items-center w-50">
								<div className="col-8 ">
									<div className="d-flex justify-content-center align-items-center">
										<img className="awake-wt-67" src={icon} alt="" />
									</div>
									<h1
										className="awake-heading-2 awake-fw-700 text-start"
										dangerouslySetInnerHTML={{ __html: titleCard }}></h1>

									<h5
										className="awake-heading-5 awake-text-secondary"
										dangerouslySetInnerHTML={{ __html: descriptionCard }}></h5>
								</div>
							</div>
							<div className="d-flex justify-content-end w-50 awake-mt-90">
								<div className="col-10">{children}</div>
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
};

export default CardLandingProposed;