import { type CampaignDataInterface } from '@shared/interfaces/Experience.interface';

function CampaignComponent(props: CampaignDataInterface): JSX.Element {
    return <>
        {props.cardColor && props.titleColor ?
            (
                <div className="campaign__text col-auto campaign__text__card d-flex awake-ht-38 awake-zi-99 text-center">
                    <label style={{ color: props.titleColor, backgroundColor: props.cardColor }} className='d-flex py-1 rounded px-3'>
                        <span style={{ color: props.titleColor }} className={`d-flex align-items-center campaign__text__card__icon awake-icon-color-unset ${props.icon}`}></span>
                        <span className='ms-3 awake-fw-700 d-flex align-items-center'>{props.title}</span>
                    </label>
                </div>
            ) : (
                props.image &&
                <div className='campaign__img d-flex justify-content-center awake-zi-99'>
                    <img
                        src={props.image}
                        className="campaign__image"></img>
                </div>
            )
        }
    </>
}
export default CampaignComponent;