import FilterCardResultComponent from '@shared/components/filters/components/results-section/components/FilterCardResult.component';
import type { FilterResultResponseInterface } from '@shared/components/filters/interfaces/FilterResultResponse.interfaces';
import type { FiltersDataResponseInfoInterface } from '@shared/components/filters/interfaces/FiltersInfoResponse.interface';
import type { FiltersValueInterface } from '@shared/components/filters/interfaces/FiltersValueInterface.interface';
import { FiltersLabelsEN, FiltersLabelsES } from '@shared/components/filters/labels/Filters.labels';
import {
	filterExperiences,
	getInitialValuesForFilter
} from '@shared/components/filters/services/Filter.services';
import InputFinderComponent from '@shared/components/input-finder/InputFinder.component';
import OffCanvasComponent from '@shared/components/off-canvas/OffCanvas.component';
import { ScreenDesktop } from '@shared/components/screens/ScreenDesktop.component';
import { ScreenDimensions } from '@shared/components/screens/ScreenDimensions.component';
import { FilterCardResultSkeleton } from '@shared/components/skeletons/FilterCardResult.skeleton';
import { AppContext } from '@shared/context/App.context';
import { Route } from '@shared/enums/Routes.enum';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import type { DestinationsInfoDataInterface } from '@shared/interfaces/DestinationsInfo.interface';
import type { ExperienceDataInterface } from '@shared/interfaces/Experience.interface';
import { defaultPageConfigs } from '@shared/services/set-configs/defaultPageConfigs';
import {
	getRoute,
	isACatchError,
	removeAccent,
	useResponsive
} from '@shared/services/utils/Utils.services';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const ButtonSearch = ({ open }: { open: () => void }): JSX.Element => {
	const { isTablet, isMobile } = useResponsive();
	const { t, i18n } = useTranslation();

	return (
		<>
			<ScreenDimensions mobile tablet>
				<label
					className={`${isMobile ?? isTablet
						? 'awake-fw-700 cursor-pointer-link'
						: 'p-3 awake-button-header awake-fw-700 cursor-pointer-link awake-wt-80'
						}`}
					onClick={open}>
					{' '}
					<div className="text-center awake-bg-white rounded awake-ht-40 awake-wt-40 p-2">
						<span className="icon-search awake-fw-700 cursor-pointer-link"></span>{' '}
					</div>
				</label>
			</ScreenDimensions>
			<ScreenDesktop>
				<label
					className={`${isMobile ?? isTablet
						? 'awake-fw-700 cursor-pointer-link'
						: 'p-3 awake-button-header awake-fw-700 cursor-pointer-link awake-wt-80'
						}`}
					onClick={open}>
					{' '}
					<div className="text-center awake-bg-white rounded awake-ht-40 awake-wt-40 p-2">
						<span className="icon-search awake-fw-700 cursor-pointer-link"></span>{' '}
					</div>
				</label>
			</ScreenDesktop>
		</>
	);
};

const HomeSearch: React.FC = ({
	filters,
	setDestinationFilterValue
}: {
	onDestinationChanged?: (destination: string | undefined) => void;
	filters?: FiltersDataResponseInfoInterface;
	setDestinationFilterValue?: React.Dispatch<React.SetStateAction<string>>;
	position?: string;
}): JSX.Element => {
	const { t } = useTranslation();
	const { isMobile } = useResponsive();
	const [isFiltering, setIsFiltering] = useState<boolean>(false);
	const [filterResults, setFilterResults] = useState<ExperienceDataInterface[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const params = useParams();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const { appContextValue } = useContext(AppContext);
	const [hasMore, setHasMore] = useState<boolean>(false);
	const [modal, setModal] = useState<boolean>(false);
	const [filtersLabels, setFiltersLabels] = useState(FiltersLabelsES);
	const [filtersValueState, setFiltersValueState] = useState<FiltersValueInterface>(
		getInitialValuesForFilter(params, currentPage)
	);
	const [searchValue, setSearchValue] = useState('');
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	
	useEffect(() => {
		if (isLoaded && modal) {
			getExperienceData();
		}
	}, [
		appContextValue.currentPageConfiguration?.currency,
		appContextValue.currentPageConfiguration?.language,
		filtersValueState
	]);

	const [destinationsFilterList, setDestinationsFilterList] = useState<
		DestinationsInfoDataInterface[]
	>([]);

	const setSearchInputValue = (value: string): any => {
		setFiltersValueState({
			...filtersValueState,
			searchInput: value
		});
		setSearchValue(value);
		if (value.length > 1) {
			setDestinationsFilterList([]);
		} else {
			setDestinationsFilterList([]);
		}
	};

	const handleOpenModal = (): void => {
		setModal(true);

		getExperienceData();
	};

	useEffect(() => {
		if (
			appContextValue.currentPageConfiguration?.language.code ===
			defaultPageConfigs.language.code
		) {
			setFiltersLabels(FiltersLabelsES);
		} else {
			setFiltersLabels(FiltersLabelsEN);
		}
	}, [appContextValue.currentPageConfiguration]);

	const getExperienceData = (): void => {
		if (!isFiltering) {
			setFilterResults([]);
			setIsLoading(true);
			setIsFiltering(true);
			filterExperiences(
				filtersValueState,
				filters!,
				setFiltersValueState,
				appContextValue.currentPageConfiguration
			)
				.then((filterResultInfo: CatchErrorInterface | FilterResultResponseInterface) => {
					if (!isACatchError(filterResultInfo)) {
						if (filtersValueState.page === 1) {
							setFilterResults(filterResultInfo.data.items);
						} else if (filtersValueState.page > 1) {
							setFilterResults([...filterResults, ...filterResultInfo.data.items]);
						}
						setHasMore(filterResultInfo.data.hasMore);
						if (filterResultInfo.data.hasMore) {
							setCurrentPage(filtersValueState.page);
						}
					} else {
						setIsFiltering(false);
						setIsLoading(false);
					}

					if (!isLoaded) {
						setIsLoaded(true);
					}
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					setIsLoading(false);
					setIsFiltering(false);
				});
			if (setDestinationFilterValue) {
				filtersValueState.destinationFilterValue
					? setDestinationFilterValue(filtersValueState.destinationFilterValue)
					: setDestinationFilterValue('');
			}
		}
	};

	const handleFilterButtonClick = (): void => {
		getRoute({ route: Route.Filters });
		setModal(false);
	};

	function getDestinationsSearchLocal(search: string): any[] {
		const data = appContextValue.categoriesInfo ?? [];
		const dest = JSON.parse(sessionStorage.getItem('destinationList')!);
		const xx = data?.concat(dest);
		return xx.filter((destination: any) => {
			return removeAccent(destination.name)
				.toLowerCase()
				.match(search ?? '');
		});
	}

	useEffect(() => {
		if (searchValue.length > 1) {
			setDestinationsFilterList(getDestinationsSearchLocal(searchValue));
		} else {
			setDestinationsFilterList([]);
		}
	}, [searchValue]);

	useEffect(() => {
		if (destinationsFilterList.length === 0) {
			setFiltersValueState({
				...filtersValueState,
				searchInput: searchValue
			});
		}
	}, [destinationsFilterList]);
	
	return (
		<>
			<ButtonSearch open={handleOpenModal}></ButtonSearch>
			<OffCanvasComponent show={modal} setShow={() => { setModal(false) }}
				customClass='header-search'
				header={false}
			>
				<>
					<div className="awake-bg-white p-md-2">
						<div className="d-flex flex-row justify-content-space-between">
							<h4
								className={
									isMobile
										? 'awake-fw-700 text-right awake-heading-5 mt-4'
										: 'awake-fw-700 text-right'
								}>
								{t('header.modal.title')}
							</h4>
							<span
								className="icon-close m-4 me-5 position-absolute top-0 end-0 cursor-pointer-link"
								onClick={() => {
									setModal(false);
								}}>
							</span>
						</div>
						<div className="row">
							<div className="col-12 awake-mb-40 w-100 mt-3 me-3">
								<InputFinderComponent
									destinationsList={destinationsFilterList}
									setDestinationsList={setDestinationsFilterList}
									inputInitialValue={filtersValueState.searchInput}
									setInputSearchValue={setSearchInputValue}
									inputPlaceholder={
										filtersLabels.searchResultSection.searchADestination
									}></InputFinderComponent>
							</div>
						</div>

						{filterResults.length === 0 ? (
							<>
								<h6 className="awake-fw-700">
									{filterResults.length} {t('header.modal.numberOfResults')}
								</h6>
							</>
						) : (
							<h6 className="awake-fw-700 ms-3">
								{filterResults.length} {t('header.modal.numberOfResults')}
							</h6>
						)}

						<h6 className="ms-3">
							{t('header.modal.description')}{' '}
							<Link
								to={getRoute({ route: Route.Filters })}
								onClick={(e) => {
									e.stopPropagation();
									handleFilterButtonClick();
								}}>
								{t('header.modal.clickHere')}{' '}
							</Link>
						</h6>

						<>
							<div className="ms-3">
								{isLoading && <FilterCardResultSkeleton />}
								{filterResults.slice(0, 16).map((filterResultItem, index) => (
									<>
										<div
											className="row g-0 w-100"
											style={{ display: 'block', paddingLeft: '0px' }}>
											<a
												style={{ textDecoration: 'none' }}
												key={index}
												className="col-12"
												onClick={() => {
													setModal(false);
												}}>
												<FilterCardResultComponent
													resultItemExperience={filterResultItem}
												/>
											</a>
										</div>
									</>
								))}
							</div>
						</>
					</div>
				</>
			</OffCanvasComponent >
		</>
	);
};

export default HomeSearch;
