import type {
	CheckIfAnReferralCodeExistsInterface,
	ProfileInfoUserData
} from '@profile/interface/ProfileUserData.interface';
import { ProfileLabelsES } from '@profile/labels/Profile.labels';
import { getUserProfile, getUserReferralsCodeExists } from '@profile/service/UserProfile.service';
import { useGoogleLogin } from '@react-oauth/google';
import { type RegisterFormInterface } from '@root/modules/auth/components/register/interfaces/RegisterForm.interface';
import { RegisterFormValidations } from '@root/modules/auth/components/register/validations/RegisterForm.validations';
import { AuthLabelsEN, AuthLabelsES } from '@root/modules/auth/labels/Auth.labels';
import ErrorSectionComponent from '@root/modules/error-page/components/ErrorSection.component';
import ToastComponent from '@shared/components/toast/ToastIcon.component';
import WhatsAppComponent from '@shared/components/whats-app-button/WhatsAppButton.component';
import { AppContext } from '@shared/context/App.context';
import { useConfiguration } from '@shared/context/Configuration.context';
import { Route } from '@shared/enums/Routes.enum';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import {
	FormValidationsLabelsEN,
	FormValidationsLabelsES
} from '@shared/labels/FormValidations.labels';
import { authUserWithGoogle, registerUser } from '@shared/services/auth-service/Auth.service';
import type {
	AuthPageResponseDataInterface,
	AuthPageResponseInterface
} from '@shared/services/auth-service/interfaces/AuthPageResult.interface';
import { updateDataLayer } from '@shared/services/ga-service/GA.service';
import { defaultPageConfigs } from '@shared/services/set-configs/defaultPageConfigs';
import {
	getAuthSession,
	setAuthUser,
	setUserData
} from '@shared/services/storage/SessionStorage.service';
import { showToastError, showToastSuccess } from '@shared/services/toast-service/toast.service';
import { getRoute, isACatchError } from '@shared/services/utils/Utils.services';
import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

function ProfileReferralsSharedComponent(): JSX.Element {
	const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
	const [bookingsData, setBookingsData] = useState<CheckIfAnReferralCodeExistsInterface>();
	const { userCode: referralCode } = useParams();
	const recaptchaRegisterRef = useRef<ReCAPTCHA>(null);
	const [authLabels, setAuthLabels] = useState(AuthLabelsES);
	const [formValidationsLabels, setFormValidationsLabels] = useState(FormValidationsLabelsEN);
	const { appContextValue, setAppContextMethod } = useContext(AppContext);
	const [profileLabel, setProfileLabel] = useState(ProfileLabelsES);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { app: { phoneNumber: { awake: awakePhoneNumber } } } = useConfiguration();

	useEffect(() => {
		setAppContextMethod({
			showLoginGoogleOneTap: false,
			showLogin: false,
		});
	}, [appContextValue])

	useEffect(() => {
		if (
			appContextValue.currentPageConfiguration?.language.code ===
			defaultPageConfigs.language.code
		) {
			setAuthLabels(AuthLabelsES);
			setFormValidationsLabels(FormValidationsLabelsES);
		} else {
			setAuthLabels(AuthLabelsEN);
			setFormValidationsLabels(FormValidationsLabelsEN);
		}
	}, [
		appContextValue.currentPageConfiguration
	]);


	useEffect(() => {
		getUserReferralsCodeExists(referralCode!)
			.then((result: CheckIfAnReferralCodeExistsInterface | CatchErrorInterface) => {
				if ('data' in result && result.data) {
					setBookingsData(result);
				} else if ('data' in result && !result.data) {
					setBookingsData(result.data);
				}
			})
			.catch((error) => console.log(error));
	}, [referralCode]);

	const next = (): void => {
		setAppContextMethod({
			showLogin: false,
			hasLogin: true
		});
	}

	const formik = useFormik({
		initialValues: {
			referralCode,
			email: '',
			confirmationEmail: '',
			name: '',
			phone: '',
			'g-recaptcha-response': ''
		},
		validationSchema: RegisterFormValidations(authLabels, formValidationsLabels, true),
		onSubmit: async (values: RegisterFormInterface) => {
			updateDataLayer();
			await recaptchaRegisterRef.current?.executeAsync();
			registerUser(values)
				.then(async (result: CatchErrorInterface | AuthPageResponseInterface) => {
					if (!isACatchError(result)) {
						setAuthUser(result.data);

						const profile = await getUserProfile() as ProfileInfoUserData;
						setUserData(profile.data);

						next()
						showToastSuccess(
							<ToastComponent
								message={'Bienvenido a la comunidad Awake'}></ToastComponent>,
							'top-right',
							false
						);
						navigate(getRoute({ route: Route.ProfileReferrals }));
					} else {
						showToastError('Email ya existe');
					}
					recaptchaRegisterRef.current?.reset();
				})
				.catch((error) => {
					console.error(error);
				});
		}
	});

	const googleLogin = useGoogleLogin({
		flow: 'auth-code',
		onSuccess: async (tokenResponse) => {
			authUserWithGoogle(tokenResponse.code, referralCode)
				.then((result: CatchErrorInterface | AuthPageResponseDataInterface) => {
					if (!isACatchError(result)) {
						if (setAuthUser(result).success) {
							getUserProfile()
								.then((result: CatchErrorInterface | ProfileInfoUserData) => {
									if (!isACatchError(result)) {
										setUserData(result.data);
										showToastSuccess(
											<ToastComponent
												message={
													'ok' +
													' ' +
													getAuthSession().username
												}></ToastComponent>,
											'top-right',
											false
										);
										setAppContextMethod({
											showLogin: false
										});
									} else {
										if (result.message.statusCode === 401) {
											showToastError(authLabels.userAlreadyExists);
										} else {
											console.error(result.error);
										}
									}
								})
								.catch((error) => {
									console.error(error);
								});
						} else {
							showToastError('Error');
						}
					} else {
						if (result.message.statusCode === 401) {
							showToastError(authLabels.userAlreadyExists);
						} else {
							console.error(result.error);
						}
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	});

	return (
		<div className="row g-0">
			{!bookingsData ? (
				<div>
					<ErrorSectionComponent />
				</div>
			) : (
				<div className="row awake-mt-50 ">
					{window.innerWidth < 750 ? (
						<div className="col-auto text-center awake-mt-40 awake-m-20">
							<img
								className="text-center awake-ht-110"
								src="/static/media/hosts.8e61ff454460e2089577.png"
								alt="imagen anfitriones"
							/>
							<label className="awake-fw-700 text-center awake-heading-2 awake-mb-20">
								{ProfileLabelsES.ReferralsShared.joinUsAt}
								{bookingsData.data.referred.amount.toLocaleString('es')}{' '}
								{ProfileLabelsES.ReferralsShared.joinUsAtTwo}
							</label>
							<label className="text-start awake-heading-5 awake-mb-20 awake-mx-20">
								{bookingsData.data.user.name}{' '}
								{ProfileLabelsES.ReferralsShared.invitesYou}
							</label>
							<label className="text-start awake-fw-700 awake-heading-6 awake-mx-20">
								{ProfileLabelsES.ReferralsShared.thisOffer}
							</label>
						</div>
					) : (
						<div className="col-6 register-component container-fluid awake-ps-container awake-pe-container">
							<div className="row">
								<div className="col-12 text-center">
									<img
										className="justify-content-center"
										src="/static/media/hosts.8e61ff454460e2089577.png"
										alt="imagen anfitriones"
									/>
								</div>
							</div>
							<label className="awake-fw-700 text-center awake-heading-3 awake-mb-20">
								{ProfileLabelsES.ReferralsShared.joinUsAt}
								{bookingsData.data.referred.amount.toLocaleString('es')}{' '}
								{ProfileLabelsES.ReferralsShared.joinUsAtTwo}
							</label>
							<label className="text-start awake-heading-5 awake-mb-20">
								{bookingsData.data.user.name}{' '}
								{ProfileLabelsES.ReferralsShared.invitesYou}
							</label>
							<label className="text-start awake-fw-700 awake-heading-6">
								{ProfileLabelsES.ReferralsShared.thisOffer}
							</label>
						</div>
					)}
					<div className={`${window.innerWidth < 1200 ? 'col-12' : 'col-5'}`}>
						<div
							className={`row awake-mt-20 ${window.innerWidth < 750 ? 'text-center' : ''
								} `}>
							<label className="fw-bold awake-heading-3">
								{profileLabel.ReferralsShared.easyStartTitle}
							</label>
							<div
								className={`col-6 awake-mt-10  ${window.innerWidth < 750
										? 'col-12 awake-ms-auto awake-me-auto awake-mt-20 awake-mb-33'
										: ''
									}`}>
								<button
									onClick={googleLogin}
									className={`awake-google-button awake-wt-276 awake-ht-57 ${window.innerWidth < 1200 ? 'text-center' : ''
										}`}>
									<a className="icon-google awake-link-icon awake-me-5"></a>
									<label className="awake-heading-5 awake-fw-700">
										{authLabels.withGoogle}
									</label>
								</button>
							</div>
						</div>
						<div className="row awake-mt-20">
							<div
								className={`${window.innerWidth < 750 ? 'text-start awake-px-60' : 'col-12'
									}`}>
								<label className="awake-heading-6">
									{profileLabel.ReferralsShared.complete}
								</label>
							</div>
						</div>
						<Form onSubmit={formik.handleSubmit}>
							<div className="row awake-mt-29">
								<div
									className={
										'col-12 col-lg-6 col-xl-6 ' +
										(window.innerWidth < 1200 ? 'awake-mb-26' : '')
									}>
									<div
										className={`${formik.touched.email && formik.errors.email
												? 'invalid'
												: formik.values.email !== ''
													? 'valid'
													: 'awake-input__black'
											} ${window.innerWidth > 1200
												? ''
												: 'awake-ms-auto awake-me-auto'
											} awake-wt-276 awake-input`}>
										<label
											htmlFor="name"
											className="awake-input__label awake-bg-white">
											<span className="icon-account_circle awake-input__label__icon awake-link-icon awake-me-10"></span>
											<span className="awake-text-complementary">
												{authLabels.email}
											</span>
										</label>
										<input
											name="email"
											className="awake-ht-55 awake-input__input awake-bg-white"
											placeholder={authLabels.emailPlaceholder}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.email}></input>
										{formik.touched.email && formik.errors.email ? (
											<div className="invalid-message">
												{formik.errors.email}
											</div>
										) : null}
									</div>
								</div>
								<div
									className={
										'col-12 col-lg-6 col-xl-6 ' +
										(window.innerWidth < 1200 ? 'awake-mb-26' : '')
									}>
									<div
										className={`${formik.touched.confirmationEmail &&
												formik.errors.confirmationEmail
												? 'invalid'
												: formik.values.confirmationEmail !== ''
													? 'valid'
													: 'awake-input__black'
											} ${window.innerWidth > 1200
												? ''
												: ' awake-ms-auto awake-me-auto'
											} awake-wt-276 awake-input`}>
										<label
											htmlFor="name"
											className="awake-input__label awake-bg-white">
											<span className="icon-account_circle awake-input__label__icon awake-link-icon awake-me-10"></span>
											<span className="awake-text-complementary">
												{authLabels.confirmEmail}
											</span>
										</label>
										<input
											name="confirmationEmail"
											className="awake-ht-55 awake-input__input awake-bg-white"
											placeholder={authLabels.confirmEmailPlaceholder}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.confirmationEmail}></input>
										{formik.touched.confirmationEmail &&
											formik.errors.confirmationEmail ? (
											<div className="invalid-message">
												{formik.errors.confirmationEmail}
											</div>
										) : null}
									</div>
								</div>
							</div>
							<div
								className={
									'row ' + (window.innerWidth > 1200 ? 'awake-mt-20' : '')
								}>
								<div
									className={
										'col-12 col-lg-6 col-xl-6 ' +
										(window.innerWidth < 1200 ? 'awake-mb-26' : '')
									}>
									<div
										className={`${formik.touched.name && formik.errors.name
												? 'invalid'
												: formik.values.name !== ''
													? 'valid'
													: 'awake-input__black'
											} ${window.innerWidth > 1200
												? ''
												: ' awake-ms-auto awake-me-auto'
											} awake-wt-276 awake-input`}>
										<label
											htmlFor="name"
											className="awake-input__label awake-bg-white">
											<span className="icon-account_circle awake-input__label__icon awake-link-icon awake-me-10"></span>
											<span className="awake-text-complementary">
												{authLabels.completeName}
											</span>
										</label>
										<input
											name="name"
											className="awake-ht-55 awake-input__input awake-bg-white"
											placeholder={authLabels.completeNamePlaceholder}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.name}></input>
										{formik.touched.name && formik.errors.name ? (
											<div className="invalid-message">
												{formik.errors.name}
											</div>
										) : null}
									</div>
								</div>
								<div
									className={
										'col-12 col-lg-6 col-xl-6 ' +
										(window.innerWidth < 1200 ? 'awake-mb-26' : '')
									}>
									<div
										className={`${formik.touched.phone && formik.errors.phone
												? 'invalid'
												: formik.values.phone !== ''
													? 'valid'
													: 'awake-input__black'
											} ${window.innerWidth > 1200
												? ''
												: ' awake-ms-auto awake-me-auto'
											} awake-wt-276 awake-input`}>
										<label
											htmlFor="name"
											className="awake-input__label awake-bg-white">
											<span className="icon-account_circle awake-input__label__icon awake-link-icon awake-me-10"></span>
											<span className="awake-text-complementary">
												{authLabels.phone}
											</span>
										</label>
										<input
											name="phone"
											className="awake-ht-55 awake-input__input awake-bg-white"
											placeholder={authLabels.phonePlaceholder}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.phone}></input>
										{formik.touched.phone && formik.errors.phone ? (
											<div className="invalid-message">
												{formik.errors.phone}
											</div>
										) : null}
									</div>
								</div>
							</div>
							<div className="row awake-mt-20">
								<div className="col-auto awake-ms-auto awake-me-auto">
									<div>
										<ReCAPTCHA
											id="register-captcha"
											ref={recaptchaRegisterRef}
											sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY!}
											onChange={(value) => {
												formik.values['g-recaptcha-response'] = value ?? '';
												void formik.setFieldTouched('g-recaptcha-response');
											}}
											size="invisible"
										/>
									</div>
								</div>
							</div>
							<div className="row awake-mt-20">
								<div
									className={`col-6 ${window.innerWidth < 700
											? 'col-12 d-flex justify-content-center awake-mt-20 awake-mb-35'
											: 'd-flex'
										}`}>
									<Form.Check
										className={`${window.innerWidth < 700
												? 'login-component__checkbox awake-d-inline-block '
												: 'login-component__checkbox awake-d-inline-block '
											}`}
										type={'checkbox'}
										id={'checkbox-accept-terms'}
										checked={acceptTerms}
										onChange={(e) => {
											setAcceptTerms(!acceptTerms);
										}}
									/>

									<span
										className="awake-heading-5 awake-ms-10"
									>
										{authLabels.acceptTermsFirstText}

										<a
											className="awake-heading-5 awake-ms-10 awake-cursor-pointer"
											href={authLabels.acceptTermsUrl}
											rel="noreferrer"
											target="_blank"
										>
											{authLabels.acceptTerms}
										</a>
									</span>
								</div>
							</div>
							<div className="row awake-mt-10 awake-mb-20 text-center">
								<div className="col-12">
									<button
										id="btn-new-account"
										disabled={
											formik.isSubmitting ||
											!formik.dirty ||
											!formik.isValid ||
											!acceptTerms
										}
										className="awake-secondary-button awake-fw-700 awake-py-15 awake-px-32 awake-mt-10">
										{profileLabel.ReferralsShared.createAccountButton}
									</button>
								</div>
							</div>
						</Form>
					</div>
				</div>
			)}
			<div className="awake-heading-6">
				<WhatsAppComponent />
			</div>
		</div>
	);
}

export default ProfileReferralsSharedComponent;
