import type { ModalViewInterface } from '@shared/components/modal-view/interfaces/ModalViewProps.interface';
import { AppContext } from '@shared/context/App.context';
import { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';

function ModalViewComponent(props: ModalViewInterface): JSX.Element {
	const { setAppContextMethod } = useContext(AppContext);

	return (
		<Modal
			dialogClassName={props.dialogClassName ?? ''}
			contentClassName={props.modalClassName}
			show={props.modalState}
			onHide={() => {
				props.setModalState(!props.modalState);
				setAppContextMethod({
					showLogin: false,
					showSearch: false
				});
			}}
			backdrop="static"
			keyboard={false}
			size="xl">
			<div id="modal-container">
				<Modal.Header closeButton className="awake-no-border">
					<Modal.Title></Modal.Title>
				</Modal.Header>
				<Modal.Body>{props.children}</Modal.Body>
			</div>
		</Modal>
	);
}

export default ModalViewComponent;
