import Skeleton from 'react-loading-skeleton';
import './TravelGuidePage.skeleton.scss';
import { useResponsive } from '@shared/services/utils/Utils.services';
import React from 'react';

export const JoinTheGroupSkeleton = (): JSX.Element => {
	const { isMobile, isTablet } = useResponsive();
	const getClassNames = (): string => {
		if (isMobile) {
			return 'col-12 mt-4 mb-4';
		}

		if (isTablet) {
			return 'col-12 awake-ps-50 awake-pe-50 mt-4 mb-4';
		}
		return 'container-sm ps-sm-0 promotion-with-price d-flex flex-column gap-3 col-3 mb-4';
	};
	return (
		<>
			<div className="awake-bg-page-background row">
				<div className="row ">
					<Skeleton
						className={
							isMobile
								? 'mt-4 awake-pm-0 d-flex w-100'
								: 'container-sm g-0 ps-sm-0 promotion-with-price d-flex flex-column gap-3'
						}
						height={40}
					/>
					<Skeleton
						className={
							isMobile
								? 'mt-0 awake-pm-0 d-flex w-100'
								: 'container-sm g-0 ps-sm-0 promotion-with-price d-flex flex-column gap-3 '
						}
						height={40}
					/>
				</div>

				{React.Children.toArray(
					Array.from({ length: 2 }).map(() => (
						<>
							<div className={getClassNames()}>
								<Skeleton height={289} />
							</div>
						</>
					))
				)}

				<Skeleton
					count={1}
					className="container-sm g-0 ps-sm-0 promotion-with-price d-flex flex-column"
				/>
			</div>
		</>
	);
};
