export enum Language {
	Spanish = 'es',
	English = 'en'
}

export enum Currency {
	COP = 'COP',
	USD = 'USD'
}

export type LanguageCode = Language.Spanish | Language.English;
export type CurrencyCode = Currency.COP | Currency.USD;
export type LanguageName = Record<LanguageCode, string>;
export type CurrencyName = Record<CurrencyCode, string>;
export type LanguageCurrency = Record<LanguageCode, string>;

export interface Prefix {
	filters: Record<LanguageCode, string>;
	destination: Record<LanguageCode, string>;
	travelGuide: Record<LanguageCode, string>;
	journey: Record<LanguageCode, string>;
	experience: Record<LanguageCode, string>;
	summary: Record<LanguageCode, string>;
	booking: Record<LanguageCode, string>;
	addOns: Record<LanguageCode, string>;
	update: Record<LanguageCode, string>;
	profile: Record<LanguageCode, string>;
	recoverAccount: Record<LanguageCode, string>;
	thankYou: Record<LanguageCode, string>;
	payment: Record<LanguageCode, string>;
	detail: Record<LanguageCode, string>;
	bookings: Record<LanguageCode, string>;
	notifications: Record<LanguageCode, string>;
	wishList: Record<LanguageCode, string>;
	referrals: Record<LanguageCode, string>;
	form: Record<LanguageCode, string>;
	giftCard: Record<LanguageCode, string>;
	dataProcessing: Record<LanguageCode, string>;
	cookiesPolicy: Record<LanguageCode, string>;
	joinTheGroup: Record<LanguageCode, string>;
	weLanding: Record<LanguageCode, string>;
}
export interface DefaultPageConfigsInterface {
	currency: {
		code: CurrencyCode;
		name: string;
		rate?: number;
		round?: number;
	};
	language: {
		code: LanguageCode;
		name: string;
		currency?: CurrencyCode;
	};
	allowedLanguages: LanguageCode[];
	allowedCurrencies: CurrencyCode[];
	prefix: Prefix;
}
