import { useResponsive } from '@shared/services/utils/Utils.services';
import type { CollapseProposition } from '../interfaces/ValuesProposition.interface';

export default function CollapseValueProposition({
	show,
	props
}: {
	props: CollapseProposition;
	show: boolean;
}): JSX.Element {
	const { isMobile } = useResponsive();

	return (
		<>
			{isMobile ? (
				<>
					<div className=" m-4">
						{!show && (
							<h6 className="cursor-pointer-link awake-fw-700 p-3">
								{props.title} {show ? '-' : '+'}
							</h6>
						)}
						{show && (
							<div className="awake-bg-white w-100 awake-br-8 p-3 shadow p-2 mb-5 bg-body rounded">
								<h6 className="cursor-pointer-link awake-fw-700">
									<h6 className="cursor-pointer-link awake-fw-700">
										{props.title} {show ? '-' : '+'}
									</h6>
								</h6>
								{show && <h6 dangerouslySetInnerHTML={{ __html: props.description }}></h6>}
							</div>
						)}
					</div>
				</>
			) : (
				<div className="mt-4 ">
					{!show && (
							<h6 className="cursor-pointer-link awake-fw-700 pt-2 ps-2">
								{props.title} {show ? '-' : '+'}
							</h6>
						)}
						{show && (
							<div className="awake-bg-white w-75 awake-br-8 shadow p-3 mb-5 bg-body rounded">
								<h6 className="cursor-pointer-link awake-fw-700">
									<h6 className="cursor-pointer-link awake-fw-700 pt-2 ps-2">
										{props.title} {show ? '-' : '+'}
									</h6>
								</h6>
								{show && <h6 className="p-4" dangerouslySetInnerHTML={{ __html: props.description }}></h6>}
							</div>
						)}
				</div>
			)}
		</>
	);
}
