import { fromFetch } from 'rxjs/fetch';
import type { Observable } from 'rxjs';
import { switchMap, of, catchError } from 'rxjs';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import { AuthAPIsEnum } from '@shared/enums/AuthAPIs.enum';
import type { LoginFormInterface } from '@auth/components/login/interfaces/LoginForm.interface';
import type { RegisterFormInterface } from '@auth/components/register/interfaces/RegisterForm.interface';
import type { RecoverUserPasswordFormInterface } from '@auth/components/recover/interfaces/RecoverUserPasswordForm.interface';
import type { RecoverUserPasswordResponseInterface } from '@shared/services/auth-service/interfaces/RecoverUserPasswordResult.interface';
import type { ChangeUserPasswordFormInterface } from '@auth/components/change-password/interfaces/ChangeUserPasswordForm.interface';
import type { ChangeUserPasswordResponseInterface } from '@shared/services/auth-service/interfaces/ChangeUserPasswordResult.interface';
import type { UserExistResultInterface } from '@shared/services/auth-service/interfaces/UserExistResult.interface';
import type {
    AuthPageResponseDataInterface,
    AuthPageResponseInterface
} from '@shared/services/auth-service/interfaces/AuthPageResult.interface';
import { sendRequest } from '@shared/services/request/Request.service';
import type { RequestInterface } from '@shared/services/request/interfaces/Request.interface';
import type { UpdateUserPhoneResult } from '@shared/services/auth-service/interfaces/UpdateUserPhoneResult';
export const authUserWithPasswordAndEmail: (
    formData: LoginFormInterface
) => Promise<AuthPageResponseDataInterface | CatchErrorInterface> = async (
    formData: LoginFormInterface
): Promise<AuthPageResponseDataInterface | CatchErrorInterface> => {
    const data: RequestInterface = {
        url: AuthAPIsEnum.AuthEmailPasswordAPIsUrl,
        method: 'POST',
        body: formData
    };

    return await sendRequest(data);
};

export const authUserWithFacebook: () => Observable<
    AuthPageResponseInterface | CatchErrorInterface
> = (): Observable<AuthPageResponseInterface | CatchErrorInterface> => {
    return fromFetch(process.env.REACT_APP_SERVER_URL! + AuthAPIsEnum.AuthWithFacebookAPIsUrl).pipe(
        switchMap(async (response) => {
            if (response.ok) {
                return await response.json();
            } else {
                return { error: true, message: 'Error' };
            }
        }),
        catchError((err) => {
            console.error(err);
            return of({ error: true, message: err.message });
        })
    );
};

export const authUserWithGoogle = async (
    token: string,
    referralCode?: string
): Promise<AuthPageResponseDataInterface | CatchErrorInterface> => {
    const data: RequestInterface = {
        url: AuthAPIsEnum.AuthWithGoogleAPIsUrl + '?code=' + token,
        method: 'POST'
    };

    if (referralCode) {
        data.url += `&referralCode=${referralCode}`
    }

    return await sendRequest(data);
};

export const authUserWithGoogleTap = async (
    token: string,
    referralCode?: string
): Promise<AuthPageResponseDataInterface | CatchErrorInterface> => {
    const body: { code: string; referralCode?: string } = {
        code: token
    }

    if (referralCode) {
        body.referralCode = referralCode;
    }

    const data: RequestInterface = {
        url: AuthAPIsEnum.AuthWithGoogleTapAPIsUrl,
        method: 'POST',
        body
    };

    return await sendRequest(data);
};

export const authPageWithBack: () => Observable<
    AuthPageResponseInterface | CatchErrorInterface
> = (): Observable<AuthPageResponseInterface | CatchErrorInterface> => {
    return fromFetch(process.env.REACT_APP_SERVER_URL! + AuthAPIsEnum.AuthPageWithBackUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            client_id: Number(process.env.REACT_APP_CLIENT_ID),
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            grant_type: process.env.REACT_APP_GRAND_TYPE
        })
    }).pipe(
        switchMap(async (response) => {
            if (response.ok) {
                return await response.json();
            } else {
                return { error: true, message: 'Error' };
            }
        }),
        catchError((err) => {
            console.error(err);
            return of({ error: true, message: err.message });
        })
    );
};

export const registerUser: (
    formData: RegisterFormInterface
) => Promise<AuthPageResponseInterface | CatchErrorInterface> = async (
    formData: RegisterFormInterface
): Promise<AuthPageResponseInterface | CatchErrorInterface> => {
    const data: RequestInterface = {
        url: AuthAPIsEnum.RegisterUserUrl,
        method: 'POST',
        body: formData
    };
    return await sendRequest(data);
};

export const recoverUserPassword: (
    formData: RecoverUserPasswordFormInterface
) => Promise<RecoverUserPasswordResponseInterface | CatchErrorInterface> = async (
    formData: RecoverUserPasswordFormInterface
): Promise<RecoverUserPasswordResponseInterface | CatchErrorInterface> => {
    const data: RequestInterface = {
        url: AuthAPIsEnum.recoverUserPasswordUrl,
        method: 'POST',
        body: formData
    };

    return await sendRequest(data);
};

export const changeUserPassword: (
    formData: ChangeUserPasswordFormInterface
) => Promise<ChangeUserPasswordResponseInterface | CatchErrorInterface> = async (
    formData: ChangeUserPasswordFormInterface
): Promise<ChangeUserPasswordResponseInterface | CatchErrorInterface> => {
    const data: RequestInterface = {
        url: AuthAPIsEnum.changeUserPasswordUrl,
        method: 'POST',
        body: formData
    };

    return await sendRequest(data);
};

export const validateUserByEmail: (
    userEmail: string
) => Promise<UserExistResultInterface | CatchErrorInterface> = async (
    userEmail: string
): Promise<UserExistResultInterface | CatchErrorInterface> => {
    const data: RequestInterface = {
        url: AuthAPIsEnum.userExist,
        method: 'POST',
        body: { email: userEmail }
    };
    return await sendRequest(data);
};

export const updateUserPhone: (
    userPhone: string
) => Promise<UpdateUserPhoneResult | CatchErrorInterface> = async (
    userPhone: string
): Promise<UpdateUserPhoneResult | CatchErrorInterface> => {
    const data: RequestInterface = {
        url: AuthAPIsEnum.AuthPhoneAPIsUrl,
        method: 'PUT',
        body: { phone: userPhone }
    };

    return await sendRequest(data);
};
