import type { MobileMenuItemChildPropsInterface } from '@header/interfaces/MobileMenuItemProps.interface';
import type { DestinationsInfoDataInterface } from '@shared/interfaces/DestinationsInfo.interface';
import { Link, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useResponsive } from '@shared/services/utils/Utils.services';
import React from 'react';


function MobileMenuCollapseWithChildComponent(
	props: MobileMenuItemChildPropsInterface
): JSX.Element {
	const navigate = useNavigate();
	const { isMobile } = useResponsive();
	const redirectToDestination = (url: string): void => {
		if (url.includes('www.priceless.com')) {
			window.open(
				'https://www.priceless.com/celebrity/19409/awake-travel',
				'_blank' as string
			);
		} else {
			navigate(url);
		}
		props.toggleLeftMenuMethod();
	};

	return (
		<React.Fragment>
			{!props.isRoute ? (
				<a
					href={props.href}
					className={`${
						props.href ? 'mobile-menu-component__item__single' : ''
					} row g-0 mobile-menu-component__item d-flex align-items-center collapsed awake-cursor-pointer`}
					data-bs-toggle={props.showSubmenuMobile ? 'collapse' : ''}
					data-bs-target={`#${props.menuName}`}
					aria-expanded="false">
					<div className="col-auto">
						<span
							className={`${props.icon} ${
								isMobile
									? 'mobile-menu-component__item__icon d-flex align-items-center'
									: 'mobile-menu-component__item__icon-desk d-flex align-items-center'
							}`}></span>{props.icon === '🎁' ? props.icon : ''}
					</div>
					<div className="col">
						<h3
							className={
								isMobile
									? 'mobile-menu-component__item__text awake-heading-5 awake-fw-700 awake-text-white awake-mb-0 awake-ms-20'
									: 'mobile-menu-component__item__text awake-heading-5 awake-fw-700 awake-text-secondary awake-mb-0 awake-ms-20'
							}>
							{props.text}
						</h3>
					</div>
				</a>
			) : (
				props.href && (
					<Link
						onClick={() => {
							props.toggleLeftMenuMethod();
						}}
						to={props.href}
						className="mobile-menu-component__item__single row g-0 mobile-menu-component__item d-flex align-items-center collapsed awake-cursor-pointer">
						<div className="col-auto">
							<span className="mobile-menu-component__item__icon d-flex align-items-center">🎁</span>
						</div>
						<div className="col">
							<h3
								className={
									isMobile
										? 'mobile-menu-component__item__text awake-heading-5 awake-fw-700 awake-text-white awake-mb-0 awake-ms-20'
										: 'mobile-menu-component__item__text awake-heading-5 awake-fw-700 awake-text-secondary awake-mb-0 awake-ms-10'
								}>
								{props.text}
							</h3>
						</div>
					</Link>
				)
			)}
			{props.showSubmenuMobile && (
				<div className="row g-0 collapse" id={`${props.menuName}`}>
					{props.menu.slice(0, 7).map((item, index2: number) => {
						const menuId = _.random(10);
						return (
							<div className="col-12" key={index2}>
								{props.showSubmenuMobile && (
									<React.Fragment>
										<div
											key={index2}
											className={`${
												props.background
													? props.background
													: 'awake-bg-white'
											} ${
												props.hasSubmenu
													? 'mobile-menu-component__item__single'
													: ''
											} row g-0 mobile-menu-component__item mobile-menu-component__item__submenu d-flex align-items-center`}
											data-bs-toggle="collapse"
											data-bs-target={`#child-${index2}-${menuId}`}
											aria-expanded="false"
											onClick={() =>
												!props.hasSubmenu
													? redirectToDestination(
															item.url ? item.url : ''
													  )
													: ''
											}>
											<div className="col-auto">
												<span
													className={`${
														props.hasSubmenu
															? 'icon-expand_more'
															: 'icon-brightness'
													} mobile-menu-component__item__submenu__icon d-flex align-items-center`}></span>
											</div>
											<div className="col">
												<h5 className="awake-heading-5 mobile-menu-component__item__submenu__text awake-text-secondary awake-mb-0 awake-ms-20">
													{item.name}
												</h5>
											</div>
										</div>
										<div
											className="row g-0 collapse"
											id={`child-${index2}-${menuId}`}>
											<div className="col-12">
												{item.children?.length &&
													item.children.map(
														(
															item: DestinationsInfoDataInterface,
															index3: number
														) => (
															<div
																onClick={() =>
																	redirectToDestination(item.url)
																}
																className="row g-0 awake-bg-white row mobile-menu-component__item mobile-menu-component__item__submenu d-flex align-items-center"
																key={index3}>
																<div className="col-auto">
																	<span className="icon-brightness mobile-menu-component__item__submenu__icon d-flex align-items-center"></span>
																</div>
																<div className="col">
																	<h5 className="awake-heading-5 mobile-menu-component__item__submenu__text awake-text-secondary awake-mb-0 awake-ms-20">
																		{item.name}
																	</h5>
																</div>
															</div>
														)
													)}
											</div>
										</div>
									</React.Fragment>
								)}
							</div>
						);
					})}
				</div>
			)}
		</React.Fragment>
	);
}

export default MobileMenuCollapseWithChildComponent;
