export const copyLink: (experienceToShareUrl: string) => Promise<void> = async (
    experienceToShareUrl: string
): Promise<void> => {
    await navigator.clipboard.writeText(window.location.origin + experienceToShareUrl);
};

export const copyLinkTwo: (experienceToShareUrl: string) => Promise<void> = async (
    experienceToShareUrl: string
): Promise<void> => {
    await navigator.clipboard.writeText(experienceToShareUrl);
};

export const copyFullLink: (experienceToShareUrl: string) => Promise<void> = async (
    experienceToShareUrl: string
): Promise<void> => {
    await navigator.clipboard.writeText(experienceToShareUrl);
};