import imgCrossing from '@assets/journey/crossing.png';
import imgCrossingMobile from '@assets/journey/crossingMobile.png';
import imgCrossingDesk from '@assets/journey/crossingDesk.png';
import { useResponsive } from '@shared/services/utils/Utils.services';
import { useTranslation } from 'react-i18next';

function CardCrossing({
	icon,
	title,
	description
}: {
	icon: string;
	title: string;
	description: string;
}): JSX.Element {
	const { isMobile } = useResponsive();
	return (
		<div
			className={
				isMobile
					? 'd-flex flex-column awake-bg-white awake-br-8 shadow-lg p-3 bg-body rounded m-3'
					: 'awake-bg-white awake-br-8 shadow-lg bg-body rounded awake-wt-450 mt-4 p-3 '
			}>
			<div className="d-flex align-items-center">
				<span className={`${icon} icon-plans-new`}></span>
				<h3 className="awake-fw-700 awake-heading-5 awake-text-secondary m-2 ">{title}</h3>
			</div>
			<p className="awake-text-secondary awake-heading-6">{description}</p>
		</div>
	);
}

function PropositionCrossing(): JSX.Element {
	const { t } = useTranslation();
	const { isMobile, isTablet } = useResponsive();

	const infoCards = [
		{
			icon: 'icon-star',
			title: t('journey.crossing.thatIs'),
			description: t('journey.crossing.descriptionThatIs')
		},
		{
			icon: 'icon-location_on',
			title: t('journey.crossing.whatWillYouFind'),
			description: t('journey.crossing.descriptionwhatWillYouFind')
		},
		{
			icon: 'icon-colombia',
			title: t('journey.crossing.why'),
			description: t('journey.crossing.descriptionWhy')
		}
	];

	return (
		<>
			<div className="mb-4">
				<h2 className="awake-text-journey awake-fw-900 text-center">
					{t('journey.crossing.title')}
				</h2>
				{isTablet ? (
					<>
						<div className="d-flex justify-content-center">
							<img className="w-75" src={imgCrossingMobile} alt="" />
						</div>
						<div className="d-flex flex-column gap-2 align-items-center px-4">
							{infoCards.map((item, index) => (
								<CardCrossing key={index} {...item} />
							))}
						</div>
					</>
				) : !isMobile ? (
					<div className="d-flex justify-content-center position-relative">
						<img className="d-flex justify-items-center" src={imgCrossingDesk} alt="" />
						<div className="position-absolute position-start-45 top-0 awake-mt-68 gap-2">
							{infoCards.map((item, index) => (
								<CardCrossing key={index} {...item} />
							))}
						</div>
					</div>
				) : (
					<>
						<div className="d-flex justify-content-center">
							<img className="w-100" src={imgCrossingMobile} alt="" />
						</div>
						{infoCards.map((item, index) => (
							<CardCrossing key={index} {...item} />
						))}
					</>
				)}
			</div>
		</>
	);
}

export default PropositionCrossing;
