import type { BaseScreen } from '@shared/interfaces/Screen.interface';
import { useResponsive } from '@shared/services/utils/Utils.services';
import { ScreenBase } from './ScreenBase.component';

export function ScreenMobile({
  ...base
}: BaseScreen): JSX.Element {
  const { isMobile } = useResponsive();

  return (
    <>
      {isMobile && <ScreenBase {...base}></ScreenBase>}
    </>
  );
}