import { formatTo, useGetMonthName, useResponsive } from '@shared/services/utils/Utils.services';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { GroupsItem } from '../interfaces/JoinTheGroupInfo.interface';

const CardJoinTheGroup = (props: GroupsItem): any => {
	const { isMobile, isTablet } = useResponsive();
	const [isHovered, setIsHovered] = useState(false);
	const { t } = useTranslation();
	const getMonthName = useGetMonthName();
	const handleMouseEnter = (): void => {
		setIsHovered(true);
	};

	const handleMouseLeave = (): void => {
		setIsHovered(false);
	};

	const getDuration = (duration: string): JSX.Element => {
		const [day, dayText, night, nightText] = duration.split(' ');
		return (
			<div className="d-flex justify-content-end">
				<div className="rounded awake-bg-white float-right mt-2 me-2 p-1">
					<div className="d-flex align-items-baseline justify-content-center">
						<label className="awake-heading-6 awake-fw-700 awake-text-black me-1">
							0{day}
						</label>
						<span className="icon-clear_day awake-icon-black awake-fw-700 awake-heading-6"></span>
					</div>
					{night && (
						<div className="d-flex align-items-baseline justify-content-center">
							<label className="awake-heading-6 awake-fw-700 awake-text-black me-1">
								0{night}
							</label>
							<span className="icon-night awake-icon-black awake-fw-700 awake-heading-6"></span>
						</div>
					)}
				</div>
			</div>
		);
	};

	const routeWithUtm = `${props.url}?utm_source=owned&utm_medium=unete-al-grupo`;

	return (
		<>
			{isMobile ? (
				<Link
					className="row p-3 awake-bg-white cursor-pointer-link awake-hover h-100 position-relative g-0 m-0 text-decoration-none awake-text-secondary"
					to={routeWithUtm}
				>
					<div
						className="d-flex col-5 awake-br-8 h-100 flex-column flex-wrap justify-content-between me-3"
						style={{
							backgroundImage: `url(${props.image})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center'
						}}>
						<div className="justify-content-end">{getDuration(props.duration)}</div>
					</div>
					<div className="col-6">
						<div className="d-inline-block awake-heading-6 awake-bg-star awake-text-complementary awake-fw-700 awake-br-8 pt-1 pb-1 mb-2 ps-2 pe-1">
							{t('joinTheGroup.cards.title')} {props.startPoint}
						</div>

						<div className="awake-fw-700 awake-heading-6 mb-2">{props.title}</div>
						<div className=" d-flex flex-column awake-heading-6">
							{props.dates.length !== 1 ? (
								<label className="awake-fw-700 mb-2">
									{t('joinTheGroup.cards.confirmedDate')}
								</label>
							) : (
								<label className="awake-text-error awake-fw-700 mb-2">
									{t('joinTheGroup.cards.lastDate')}
								</label>
							)}

							{props.dates.map((date: any, dateIndex: any) => {
								const startDate = moment(date.start);
								const endDate = moment(date.end);

								const startMonth = getMonthName(parseInt(startDate.format('MM')));
								const endMonth = getMonthName(parseInt(endDate.format('MM')));

								return (
									<div key={dateIndex}>
										{`${startDate.format('DD')} ${startMonth} - ${endDate.format('DD')} ${endMonth}`}
									</div>
								);
							})}
						</div>
						<label className="awake-heading-6 mt-4">
							{t('joinTheGroup.cards.personFrom')}
						</label>
						<label className="awake-fw-700 awake-heading-5">
							{formatTo(props.price)}
						</label>
					</div>
					<div className="col-lg-1 col-1 position-absolute bottom-0 end-0 m-3">
						<div className="icon-array awake-ht-30 awake-wt-30 awake-br-50 box-shadow-wt-icon d-flex justify-content-center align-items-center icon-chevron_right float-end"></div>
					</div>
				</Link>
			) : (
				<Link
					className={`awake-bg-white row g-0 awake-br-8 p-2 m-4 cursor-pointer-link awake-hover-join awake-wt-complementary h-100 position-relative text-decoration-none awake-text-secondary ${isHovered ? 'awake-hovered-join' : ''
						}`}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					to={routeWithUtm}
				>
					<div
						className={'col-3 rounded mt-4 mb-4 me-3 ms-2'}
						style={{
							backgroundImage: `url(${props.image})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center'
						}}>
						<div className="justify-content-end">{getDuration(props.duration)}</div>
					</div>
					<div className={!isTablet ? 'col-7' : 'col-8 mt-0'}>
						<label className="awake-bg-star mt-4 mb-4 ps-4 pe-4 rounded awake-heading-5 awake-fw-700 awake-text-complementary">
							{t('joinTheGroup.cards.title')} {props.startPoint}
						</label>
						<h4 className="awake-fw-700">{props.title}</h4>
						<div className=" d-flex flex-column awake-heading-5">
							{props.dates.length !== 1 ? (
								<label className="awake-fw-700">
									{t('joinTheGroup.cards.confirmedDate')}
								</label>
							) : (
								<label className="awake-text-error awake-fw-700">
									{t('joinTheGroup.cards.lastDate')}
								</label>
							)}

							{props.dates.map((date: any, dateIndex: any) => {
								const startDate = moment(date.start);
								const endDate = moment(date.end);

								const startMonth = getMonthName(parseInt(startDate.format('MM')));
								const endMonth = getMonthName(parseInt(endDate.format('MM')));

								return (
									<div key={dateIndex}>
										{`${startDate.format('DD')} ${startMonth} - ${endDate.format('DD')} ${endMonth}`}
									</div>
								);
							})}
						</div>
						<div>
							<label className="mt-4 awake-heading-6">
								{t('joinTheGroup.cards.personFrom')}
							</label>
							<h4 className="awake-fw-700">{formatTo(props.price)}</h4>
						</div>
					</div>
					<button
						className={`col-2 awake-m-20 awake-mb-50 awake-hover-join__button awake-secondary-button position-absolute bottom-0 end-0 awake-fw-700 ${isHovered ? 'awake-hovered ' : ''
							}`}>
						{t('joinTheGroup.cards.button')}
					</button>
				</Link>
			)}
		</>
	);
};
export default CardJoinTheGroup;
