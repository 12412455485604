import { MenuItemsES, MenuItemsEN } from '@shared/labels/ProfileMenu.labels';
import type { HeaderLabelsInterface } from '@header/interfaces/HeaderLabels.interface';
import { getRoute } from '@shared/services/utils/Utils.services';
import { Route } from '@shared/enums/Routes.enum';

export const HeaderLabelsES: HeaderLabelsInterface = {
	leftMenu: {
		destinations: {
			index: 'destinations',
			title: 'Destinos',
			icon: 'icon-forest',
			showMobile: true,
			showSubmenuMobile: true,
			background: 'awake-bg-secondary-2'
		},
		experiences: {
			index: 'experiences',
			title: 'Tipos de experiencias',
			icon: 'icon-sentiment_very_satisfied',
			showMobile: true,
			showSubmenuMobile: true,
			LeftMenuPropsInterface: 'Experiencias priceless'
		},

		data: [
			{
				index: 'aboutUs',
				title: 'Sobre nosotros',
				icon: 'icon-self_improvement',
				href: 'https://awake.travel/files/19/Quienes%20somos%20pagina.pdf',
				showMobile: true,
				showSubmenuMobile: false,
				data: [
					{
						text: 'Nosotros',
						href: 'https://awake.travel/files/19/Quienes%20somos%20pagina.pdf'
					},
					{
						text: 'Manifiesto Awake',
						href: 'https://awake.travel/files/19/Manifiesto%20pagina.pdf'
					},
					{
						text: 'Bono de regalo',
						url: getRoute({ route: Route.GiftCard }),
						isRoute: true
					},
					{
						text: '✈️ Calendario de viajes',
						url: getRoute({ route: Route.JoinTheGroup }),
						isRoute: true
					},
					{
						text: 'Conviértete en un anfitrión',
						href: 'https://awake.travel/files/19/PDF%20Anfitriones%20pagina.pdf'
					},
					{
						text: 'Awake Bio',
						href: 'https://awakebio.awake.travel/'
					},
					{
						text: 'Awake U',
						href: 'https://u.awake.travel/'
					},
					{
						text: 'Trabaja con nosotros',
						href: 'https://destinos.awake.travel/ofertas_awake/'
					}
				]
			},
			{
				index: 'gift-card',
				title: 'Bono de regalo',
				icon: 'icon-mark',
				href: getRoute({ route: Route.GiftCard }),
				showMobile: true,
				showSubmenuMobile: false,
				data: [],
				isRoute: true
			},
			{
				index: 'join-the-group',
				title: 'Calendario de viajes',
				icon: '✈️',
				href: getRoute({ route: Route.JoinTheGroup }),
				showMobile: true,
				showSubmenuMobile: false,
				data: [],
				isRoute: true
			},
			{
				index: 'blog',
				title: 'Blog',
				icon: 'icon-mark',
				href: 'https://awake.travel/blog/',
				showMobile: true,
				showSubmenuMobile: false,
				data: [
					{
						text: 'Actualidad',
						href: 'https://awake.travel/blog/'
					},
					{
						text: 'Experiencia',
						href: 'https://awake.travel/blog/'
					},
					{
						text: 'Tops',
						href: 'https://awake.travel/blog/'
					}
				]
			},
			{
				index: 'contact',
				title: 'Contacto',
				icon: 'icon-call',
				showMobile: false,
				showSubmenuMobile: false,
				data: [
					{
						text: 'Lunes  a Viernes de 9am - 8pm'
					},
					{
						text: `(+57) ${process.env.REACT_APP_AWAKE_MAIN_PHONE_NUMBER?.substring(2)}`
					},
					{
						text: 'Preguntas frecuentes de viajeros',
						href: 'https://awake.travel/files/19/Preguntas%20frecuentes%202023.pdf'
					},
					{
						text: 'Términos y condiciones',
						href: 'https://awake.travel/terminosycondiciones'
					},
					{
						text: 'PQR',
						href: 'https://destinos.awake.travel/pqrs/'
					}
				]
			}
		],
		mobile: {
			buttons: {
				experience: 'Ver todas las experiencias'
			}
		}
	},
	rightMenu: {
		search: {
			placeholder: 'Escribe un destino'
		},
		items: MenuItemsES
	}
};

export const HeaderLabelsEN: HeaderLabelsInterface = {
	leftMenu: {
		destinations: {
			index: 'destinations',
			title: 'Destinations',
			icon: 'icon-forest',
			showMobile: true,
			showSubmenuMobile: true,
			background: 'awake-bg-secondary-2'
		},
		experiences: {
			index: 'experiences',
			title: 'Experience types',
			icon: 'icon-sentiment_very_satisfied',
			showMobile: true,
			showSubmenuMobile: true,
			LeftMenuPropsInterface: 'Experiencias priceless'
		},

		data: [
			{
				index: 'aboutUs',
				title: 'About Us',
				icon: 'icon-self_improvement',
				href: 'https://awake.travel/files/19/Quienes%20somos%20pagina.pdf',
				showMobile: true,
				showSubmenuMobile: false,
				data: [
					{
						text: 'Us',
						href: 'https://awake.travel/files/19/Quienes%20somos%20pagina.pdf'
					},
					{
						text: 'Manifest Awake',
						href: 'https://awake.travel/files/19/Manifiesto%20pagina.pdf'
					},
					{
						text: 'Gift cardhh',
						url: getRoute({ route: Route.GiftCard }),
						isRoute:true,
					},
					{
						text: '✈️ Travel calendar',
						url: getRoute({ route: Route.JoinTheGroup }),
						isRoute: true
					},
					{
						text: 'Become a host',
						href: 'https://awake.travel/files/19/PDF%20Anfitriones%20pagina.pdf'
					},
					{
						text: 'Awake Bio',
						href: 'https://awakebio.awake.travel/'
					},
					{
						text: 'Awake U',
						href: 'https://u.awake.travel/'
					},
					{
						text: 'Work with us',
						href: 'https://destinos.awake.travel/ofertas_awake/'
					}
				]
			},
			{
				index: 'gift-card',
				title: 'Gift cards',
				icon: '🎁' ,
				href: getRoute({ route: Route.GiftCard }),
				showMobile: true,
				showSubmenuMobile: false,
				data: [],
				isRoute: true
			},
			{
				index: 'join-the-group',
				title: 'Travel calendar',
				icon: '✈️',
				href: getRoute({ route: Route.JoinTheGroup }),
				showMobile: true,
				showSubmenuMobile: false,
				data: [],
				isRoute: true
			},
			{
				index: 'blog',
				title: 'Blog',
				icon: 'icon-mark',
				href: 'https://awake.travel/blog/',
				showMobile: true,
				showSubmenuMobile: false,
				data: [
					{
						text: 'Topicality',
						href: 'https://awake.travel/blog/'
					},
					{
						text: 'Experience',
						href: 'https://awake.travel/blog/'
					},
					{
						text: 'Tops',
						href: 'https://awake.travel/blog/'
					}
				]
			},
			{
				index: 'contact',
				title: 'Contact',
				icon: 'icon-call',
				showMobile: false,
				showSubmenuMobile: false,
				data: [
					{
						text: 'Monday to Friday from 9am - 8pm'
					},
					{
						text: `(+57) ${process.env.REACT_APP_AWAKE_MAIN_PHONE_NUMBER?.substring(2)}`
					},
					{
						text: 'Frequently Asked Questions for Travelers',
						href: 'https://awake.travel/files/19/Preguntas%20frecuentes%202023.pdf'
					},
					{
						text: 'Terms and Conditions',
						href: 'https://awake.travel/terminosycondiciones'
					},
					{
						text: 'PQR',
						href: 'https://destinos.awake.travel/pqrs/'
					}
				]
			}
		],
		mobile: {
			buttons: {
				experience: 'See all experiences'
			}
		}
	},
	rightMenu: {
		search: {
			placeholder: 'Type a destination'
		},
		items: MenuItemsEN
	}
};
