import { useResponsive } from '@shared/services/utils/Utils.services';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './LandingPropossed.component.scss';

import BannerLandingProposed from './components/BannerLandingProposed.component';
import ScopeSessionLandingProposed from './components/ScopeSessionLandingProposed.component';
import SliderLandingProposed from './components/SliderLandingProposed.component';
import CardLandingProposed from './components/CardLandingProposed.component';
import LivingArrangementsProposedComponent from './components/LivingArrangementsProposed.component';
import FollowUpsProposedComponent from './components/FollowUpsProposed.component';
import ManifestProposedComponent from './components/ManifestProposed.component';
import data from './data/DataLandingProposed.component';

const LandingProposedComponent = (): JSX.Element => {
	const { isMobile } = useResponsive();
	const { t } = useTranslation();

	return (
		<>
			<BannerLandingProposed
				img={data[0].bannerLandingProposed.imgen} 
				logo={data[0].bannerLandingProposed.log} 
				title={data[0].bannerLandingProposed.title}
				titleDesktop={data[0].bannerLandingProposed.titleDescktop} 
				description={data[0].bannerLandingProposed.description}
			/>
			<div className="d-flex justify-content-center align-items-center mt-4 mb-4">
				<ScopeSessionLandingProposed {...data[0].scope} img={data[0].scope.imgScopes} />
			</div>
			<div className="container-no-scroll slider-container">
				<SliderLandingProposed />
			</div>
			<h1 className="text-center awake-mt-50 awake-heading-2 awake-fw-900 ps-4 pe-4">
				{t('landingProposed.titleFirstSection')}
			</h1>
			<div>
				<div>
					{data[0].cardLandingProposed.map((d, index) => (
						<div key={index}>
							<CardLandingProposed
								icon={d.icon}
								title={d.title}
								description={d.description}>
								{d.contentType === 'image' ? (
									<img
										src={d.contentSrc}
										alt="Content"
										className="img-fluid w-100"
									/>
								) : (
									<ReactPlayer
										url={d.contentSrc}
										className={
											isMobile
												? 'awake-wt-per-100 awake-ht-per-100 ps-4 pe-4'
												: 'awake-wt-per-100 awake-ht-per-150 p-4'
										}
									/>
								)}
							</CardLandingProposed>
						</div>
					))}
				</div>
			</div>
			<div className="p-4">
				<LivingArrangementsProposedComponent {...data[0].livingArrangements} />
			</div>
			<h1
				className="text-center  awake-heading-2 awake-fw-900  ps-4 pe-4"
				dangerouslySetInnerHTML={{ __html: t('landingProposed.titleFollowUp') }}></h1>
			<div className="ps-4 pe-4">
				{data[0].followUps.map((d, index) => (
					<div key={index}>
						<FollowUpsProposedComponent
							{...d}
							position={index % 2 === 0 ? 'left' : 'right'}
						/>
					</div>
				))}
			</div>
			<div className="p-4">
				<ManifestProposedComponent
					title={data[0].manifest.title}
					img={data[0].manifest.img}
					list={data[0].manifest.list}>
					<div className="d-flex justify-content-center m-4">
						<button
							className="awake-primary-button awake-fw-700"
							onClick={() =>
								window.open(
									'https://awake.travel/files/19/Manifiesto%20pagina.pdf',
									'_blank'
								)
							}>
							{t('landingProposed.button')}
						</button>
					</div>
				</ManifestProposedComponent>
			</div>
		</>
	);
};

export default LandingProposedComponent;
