import React, { useEffect, useState } from 'react';
import { useResponsive } from '@shared/services/utils/Utils.services';
import { Carousel } from 'react-bootstrap';
import { type ItemOption } from '../interfaces/Response.interface';

export const Item = (props: ItemOption & {
	startOpened: boolean;
	textAlign: string;
	backgroundColor: string;
}): JSX.Element => {
	const { isMobile, isTablet, isDesktop } = useResponsive();

	const getHeight = (): number => {
		if (isMobile) {
			return 200;
		}

		if (isTablet) {
			return 225;
		}

		return 350;
	}

	const [opened, setOpened] = useState<boolean>(props.startOpened);
	const [height, setHeight] = useState<number>(getHeight());

	const toggle = (): void => {
		setOpened(!opened);
	}

	useEffect(() => {
		setHeight(getHeight());
	}, [isMobile, isTablet, isDesktop]);

	return (
		<>
			<div id={props.id} className={`col-12 col-md-11 col-lg-10 awake-bg-${props.backgroundColor} rounded ${opened && ''}`}>
				<div
					className={`d-flex flex-column justify-content-center align-items-${props.textAlign} position-relative awake-cursor-pointer awake-ht-70 px-4`}
					onClick={toggle}
				>
					<h3 className="awake-text-secondary awake-heading-3 text-center my-4">
						{props.icon && <span className={`pe-3 awake-icon-secondary icon-${props.icon}`}></span>}
						{props.title}
					</h3>

					<span className={`${opened ? 'icon-expand_less' : 'icon-expand_more'} awake-heading-4 awake-text-before-secondary position-absolute end-0 me-4`}></span>
				</div>
				{['itinerary', 'includes', 'faqs'].includes(props.id) &&
					<div className={`pt-4 px-4 pb-4 align-items-start awake-bg-white ${opened ? 'd-flex flex-column' : 'd-none'} ${isMobile && 'flex-column'}`}>
						{React.Children.toArray(
							props.values.map((item: { key: string, value: string, label: string }, index: any) => (
								<>
									{props.id === 'faqs' ?
										(<div>
											<h4 className='awake-fw-400 awake-text-secondary awake-heading-4 pe-2'>
												{item.label}:
											</h4>
											<ul>
												<li>
													<h4
														className='awake-fw-400 awake-text-secondary awake-heading-4 pe-2'
														dangerouslySetInnerHTML={{
															__html: item.value
														}}
													/>
												</li>
											</ul>
										</div>) : (
											<ul>
												<li>
													<h4 className='awake-fw-700 awake-text-secondary awake-heading-4 pe-2'>
														{item.label}:
													</h4>
													<h4
														className='awake-fw-400 awake-text-secondary awake-heading-4'
														dangerouslySetInnerHTML={{
															__html: item.value
														}}
													/>
												</li>
											</ul>
										)
									}

								</>
							))
						)}
					</div>
				}
				{['accommodation', 'howToGet', 'feeding', 'host'].includes(props.id) &&
					<div className={`pt-4 px-4 pb-4 align-items-start awake-bg-white ${opened ? 'd-flex' : 'd-none'} ${isMobile && 'flex-column'}`}>
						{React.Children.toArray(
							props.values.map((item: { key: string, value: any; }, index: number) => (
								<>
									{item.key === 'images' &&
										<div className="d-block awake-wt-per-100">
											<Carousel
												className="awake-wt-per-100"
												indicators={false}
												fade
											>
												{React.Children.toArray(
													item.value.map((image:string) => (
														<Carousel.Item>
															<img
																className={`awake-cover-image awake-ht-${height} ${isTablet ? 'awake-wt-285' : 'awake-wt-per-100'}`}
																src={image}
																loading={opened ? (index ? 'lazy' : undefined) : 'lazy'}
															/>
														</Carousel.Item>
													))
												)}
											</Carousel>
										</div>
									}
									{item.key === 'image' &&
										<div className="d-block awake-wt-per-100">
											<img
												className={`awake-cover-image awake-ht-${height} ${isMobile && 'awake-wt-per-100'} ${isTablet && 'awake-wt-285'} ${isDesktop && 'awake-wt-450'}`}
												src={item.value}
												loading={opened ? (index ? 'lazy' : undefined) : 'lazy'}
											/>
										</div>
									}
									{item.key === 'description' &&
										<div className={`d-flex flex-column gap-4 pt-3 pt-md-0 px-2 px-md-4 ${!isMobile ? `overflow-y-auto awake-ht-${height}` : 'justify-content-center'}`}>
											<h4
												className="awake-heading-4 awake-fw-400 awake-text-secondary"
												dangerouslySetInnerHTML={{
													__html: item.value
												}}
											></h4>
										</div>
									}
								</>
							))
						)}

					</div>
				}
				{['recommendations', 'security', 'cancellationPolicies'].includes(props.id) &&
					<div className={`pt-4 px-4 pb-4 align-items-start awake-bg-white ${opened ? 'd-flex flex-column' : 'd-none'} ${isMobile && 'flex-column'}`}>
						<div className='row gy-4'>
							{React.Children.toArray(
								props.values.map((item: { key: string, value: string, label: string }, index: any) => (
									<>
										{
											item.key === 'description' &&
											<div className="col-12">
												<h4 className='awake-fw-400 awake-text-secondary awake-heading-4 pe-2'
													dangerouslySetInnerHTML={{
														__html: item.value
													}}
												>
												</h4>
											</div>
										}
										{
											item.key === 'item' &&
											<div className="col-12 col-lg-6">
												<div className="d-flex flex-row">
													<label className='awake-fw-700 awake-text-secondary'>
														<span className={`pe-3 awake-heading-4 awake-icon-secondary icon-${item.label}`}></span>
													</label>
													<h4 className='awake-fw-400 awake-text-secondary awake-heading-4 pe-2'>
														{item.value}
													</h4>
												</div>
											</div>
										}
									</>
								))
							)}
						</div>
					</div>
				}
			</div>
		</>
	);
}