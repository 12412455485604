import * as Yup from 'yup';
import { emailFormatValidation } from '@shared/services/validations/FormValidations.service';
import type { FormValidationsLabelsInterface } from '@shared/interfaces/FormValidationLabels.interface';

export function recoverUserPasswordFormValidations(
    FormValidationsLabels: FormValidationsLabelsInterface
): any {
    return Yup.object({
        email: Yup.string()
            .matches(emailFormatValidation.value, FormValidationsLabels.emailFormatValidation)
            .email(FormValidationsLabels.emailFormatValidation)
            .required(FormValidationsLabels.requiredField)
    });
}
