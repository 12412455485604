
import { useAppContext } from '@shared/context/App.context';
import { isACatchError } from '@shared/services/utils/Utils.services';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { type Journey } from '../interfaces/Response.interface';
import { get } from '../services/Journey.service';

export const useJourney = (): any => {
  const { slug: destinationSlug } = useParams();
  const { appContextValue } = useAppContext();
  const [destination, setDestination] = useState<string | undefined>(destinationSlug);
  const [result, setResult] = useState<Journey | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [found, setFound] = useState<boolean>(true);

  const getData = (): void => {
    setFound(true);
    setIsLoading(true);
    setResult(undefined);

    get({
      slug: destination!,
      currentPageConfiguration: appContextValue.currentPageConfiguration!
    })
      .then((response) => {
        if (!isACatchError(response)) {
          setResult(response.data);
        } else {
          setFound(false);
        }
      })
      .catch(() => setFound(false))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (result) {
      setFound(true);
    }
  }, [result]);

  useEffect(() => {
    getData();
  }, [destination]);

  useEffect(() => {
    getData();
  }, [appContextValue.currentPageConfiguration?.language]);

  useEffect(() => {
    if (destinationSlug) {
      setDestination(destinationSlug);
    }
  }, [destinationSlug]);

  return {
    found,
    isLoading,
    result,
  }
}
