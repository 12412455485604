import awakeBrand from '@assets/common/awake-brand.png';
import HeaderButtonComponent from '@header/components/header-button/HeaderButton.component';
import '@header/components/right-menu/RightMenu.component.scss';
import type { RightMenuPropsInterface } from '@header/interfaces/RightMenuProps.interface';
import { AppContext } from '@shared/context/App.context';
import { Route } from '@shared/enums/Routes.enum';
import { getRoute, useResponsive } from '@shared/services/utils/Utils.services';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

function RightMenuComponent(props: RightMenuPropsInterface): JSX.Element {
	const { appContextValue, setLanguage, setCurrency } = useContext(AppContext);
	const { isMobile, isTablet } = useResponsive();
	return (
		<ul
			className={
				isTablet
					? 'navbar-nav right-menu-component awake-p-absolute awake-left-0 m-4'
					: 'navbar-nav right-menu-component awake-p-absolute awake-left-20'
			}>
			{/* langs */}

			<ul className="navbar-nav">
				<li className="nav-item">
					<Link
						className="nav-link awake-cursor-pointer d-flex justify-content-center"
						to={getRoute({ route: Route.Home })}>
						<img className="header-component__logo" src={awakeBrand}></img>
					</Link>
				</li>
			</ul>

			{!isMobile && (
				<>
					<HeaderButtonComponent
						textClass={'awake-language-label'}
						items={appContextValue.languagesInfo ? appContextValue.languagesInfo : []}
						text={appContextValue.currentPageConfiguration?.language.name}
						code={appContextValue.currentPageConfiguration?.language.code}
						buttonMethod={setLanguage}
					/>
					<div className='ms-3 awake-text-white'> | </div>
					{/* currency */}

					{/* <HeaderButtonComponent
						items={appContextValue.currenciesInfo ? appContextValue.currenciesInfo : []}
						icon={'icon-attach_money'}
						buttonMethod={setCurrency}
					/> */}

					{/* current currency */}

					<HeaderButtonComponent
						textClass={'awake-uppercase-label '}
						items={appContextValue.currenciesInfo ? appContextValue.currenciesInfo : []}
						text={appContextValue.currentPageConfiguration?.currency.code}
						buttonMethod={setCurrency}
					/>
				</>
			)}
		</ul>
	);
}

export default RightMenuComponent;
