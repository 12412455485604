import { useResponsive } from '@shared/services/utils/Utils.services';
import { useTranslation } from 'react-i18next';

const BannerLandingProposed = ({
	img,
	logo,
	title,
	titleDesktop,
	description
}: {
	img: string;
	logo: string;
	title: string;
	titleDesktop: string;
	description: string;
}): JSX.Element => {
	const { isMobile, isTablet, isDesktop } = useResponsive();
	const { t } = useTranslation();

	// Traduce las claves dentro del componente
	const translatedTitle = t(isDesktop ? titleDesktop : title);
	const translatedDescription = t(description);

	return (
		<div className="position-relative">
			<img className="w-100 awake-ht-214 awake-cover-image" src={img} alt="" />
			<div
				className="position-absolute top-0 start-0 w-100 h-100 "
				style={{
					background:
						'linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 1.9))',
					zIndex: 1
				}}
			/>
			<div
				className="position-absolute top-50 start-50 translate-middle w-100"
				style={{ zIndex: 2 }}>
				<div className="d-flex justify-content-center align-items-center mt-4 mb-3">
					<img className="awake-wt-64 awake-ht-35" src={logo} alt="" />
				</div>
				<div className="text-center">
					{!isMobile ? (
						<h1
							className="text-white awake-heading-2 awake-fw-900"
							dangerouslySetInnerHTML={{ __html: translatedTitle }}></h1>
					) : (
						<label
							className="text-white awake-heading-2 awake-fw-900"
							dangerouslySetInnerHTML={{ __html: translatedTitle }}></label>
					)}

					<h6 className="text-white awake-heading-6 mt-3 ps-4 pe-4">
						{translatedDescription}
					</h6>
				</div>
			</div>
		</div>
	);
};

export default BannerLandingProposed;