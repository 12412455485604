import * as Yup from 'yup';
import type { FormValidationsLabelsInterface } from '@shared/interfaces/FormValidationLabels.interface';

export function WhastAppFormValidations(FormValidationsLabels: FormValidationsLabelsInterface): any {
    return Yup.object({
        name: Yup.string().min(3, FormValidationsLabels.minLength).required(FormValidationsLabels.requiredField),
        phone:Yup.string().min(10, FormValidationsLabels.invalid).required(),
        message: Yup.string().min(3, FormValidationsLabels.minLength).required(FormValidationsLabels.requiredField)
    });
}
