import { FiltersItemsEnum } from '@filters/enums/FilterItems.enum';
import { FilterShortEnum } from '@filters/enums/FilterShort.enum';
import type { FilterResultResponseInterface } from '@filters/interfaces/FilterResultResponse.interfaces';
import type {
    FiltersDataResponseInfoInterface,
    FiltersInfoResponseInterface
} from '@filters/interfaces/FiltersInfoResponse.interface';
import { DestinationsAPIsEnum } from '@root/modules/destinations/enums/DestinationsAPIs.enum';
import type { FiltersValueInterface } from '@shared/components/filters/interfaces/FiltersValueInterface.interface';
import { Route } from '@shared/enums/Routes.enum';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import type { PageConfigurationInterface } from '@shared/interfaces/PageConfiguration.interface';
import { sendRequest } from '@shared/services/request/Request.service';
import type { RequestInterface } from '@shared/services/request/interfaces/Request.interface';
import { formatSlugToString, getRoute } from '@shared/services/utils/Utils.services';
import type { Params } from 'react-router-dom';

export const filterExperiences: (
    filtersUserValues: FiltersValueInterface,
    filtersConfigState: FiltersDataResponseInfoInterface,
    setFiltersValueState: React.Dispatch<React.SetStateAction<FiltersValueInterface>>,
    currentPageConfiguration?: PageConfigurationInterface
) => Promise<FilterResultResponseInterface | CatchErrorInterface> = async (
    filtersUserValues: FiltersValueInterface,
    filtersConfigState: FiltersDataResponseInfoInterface,
    setFiltersValueState: React.Dispatch<React.SetStateAction<FiltersValueInterface>>,
    currentPageConfiguration?: PageConfigurationInterface
): Promise<FilterResultResponseInterface | CatchErrorInterface> => {
        const body: any = {
            page: filtersUserValues.page
        };

        if (filtersUserValues.monthFilterValue !== '') {
            body.date = {
                type: 'scheduled',
                year: Number(filtersUserValues.monthFilterValue.split('-')[0]),
                month: Number(filtersUserValues.monthFilterValue.split('-')[1])
            };
        } else if (filtersUserValues.dateFilterValue !== '') {
            const userSelectedDate = new Date(filtersUserValues.dateFilterValue);
            body.date = {
                type: 'normal',
                date: userSelectedDate.toISOString().split('T')[0]
            };
        }

        const immediatelyReservesId = 0;
        const awakeRecommendedId = 1;

        if (
            filtersUserValues.favoritesFilterValue.includes(awakeRecommendedId) ||
            filtersUserValues.favoritesFilterValue.includes(immediatelyReservesId)
        ) {
            body.favorites = {
                suggested: filtersUserValues.favoritesFilterValue.includes(awakeRecommendedId),
                instantBooking: filtersUserValues.favoritesFilterValue.includes(immediatelyReservesId)
            };
        }

        if (filtersUserValues.typeOfExperiencesFilterValue.length > 0) {
            body.experienceTypes = filtersUserValues.typeOfExperiencesFilterValue;
        }

        if (filtersUserValues.destinationFilterValue !== '') {
            body.destination = filtersUserValues.destinationFilterValue;
        }

        if (filtersUserValues.durationFilterValue !== 0) {
            body.duration = filtersUserValues.durationFilterValue;
        }

        if (filtersUserValues.priceFilterValue !== 0) {
            body.price = {
                min: 1,
                max:
                    Number(filtersUserValues.priceFilterValue) <
                        (filtersConfigState.price ? filtersConfigState.price.min : 2)
                        ? filtersConfigState.price
                            ? filtersConfigState.price.min
                            : 2
                        : Number(filtersUserValues.priceFilterValue)
            };
        }

        if (filtersUserValues.quantityFiltersValue !== 0) {
            body.numberPeople = filtersUserValues.quantityFiltersValue;
        }

        if (filtersUserValues.sealsFilterValue.length > 0) {
            body.impactRecognitions = filtersUserValues.sealsFilterValue;
        }

        if (filtersUserValues.ecosystemsFilterValue.length > 0) {
            body.ecosystems = filtersUserValues.ecosystemsFilterValue;
        }

        if (filtersUserValues.demandLevelFilterValue !== 0) {
            body.difficulty = filtersUserValues.demandLevelFilterValue;
        }

        if (filtersUserValues.searchInput.length >= 3) {
            body.search = filtersUserValues.searchInput;
        }
        if (filtersUserValues.shortBy) {
            body.sortBy = filtersUserValues.shortBy;
        }

        const data: RequestInterface = {
            url: DestinationsAPIsEnum.DestinationFiltersDataURL,
            method: 'POST',
            body,
            currentPageConfiguration
        };
        setFilterValueMethod(
            Object.keys(body).length - 2,
            FiltersItemsEnum.countFilters,
            setFiltersValueState,
            filtersUserValues
        );
        return await sendRequest(data);
    };

export const getFilersInfo: (
    destination: string,
    currentPageConfiguration?: PageConfigurationInterface
) => Promise<FiltersInfoResponseInterface | CatchErrorInterface> = async (
    destination: string,
    currentPageConfiguration?: PageConfigurationInterface
): Promise<FiltersInfoResponseInterface | CatchErrorInterface> => {
        const data: RequestInterface = {
            url:
                DestinationsAPIsEnum.DestinationFiltersDataURL +
                (destination !== '' ? '?destination=' + destination : ''),
            method: 'GET',
            currentPageConfiguration
        };

        return await sendRequest(data);
    };

export const getInitialValuesForFilter: (
    params: Params,
    currentPage: number
) => FiltersValueInterface = (params: Params, currentPage: number): FiltersValueInterface => {
    return {
        dateFilterValue: '',
        monthFilterValue: '',
        demandLevelFilterValue: 0,
        destinationFilterValue: params.slug ?? '',
        durationFilterValue: 0,
        ecosystemsFilterValue: [],
        favoritesFilterValue: [],
        priceFilterValue: 0,
        quantityFiltersValue: 0,
        sealsFilterValue: [],
        typeOfExperiencesFilterValue: [],
        searchInput: params.searchValue ? formatSlugToString(params.searchValue) : '',
        page: currentPage,
        count: 0,
        shortBy: null
    };
};

export const changeShortType: (
    shortType: FilterShortEnum,
    filtersValueState: FiltersValueInterface,
    setFiltersValueState: React.Dispatch<React.SetStateAction<FiltersValueInterface>>
) => void = (
    shortType: FilterShortEnum,
    filtersValueState: FiltersValueInterface,
    setFiltersValueState: React.Dispatch<React.SetStateAction<FiltersValueInterface>>
): void => {
        if (filtersValueState && setFiltersValueState) {
            switch (shortType) {
                case FilterShortEnum.orderPriceMinorToMajor: {
                    if (
                        filtersValueState.shortBy?.criteria === 'price' &&
                        filtersValueState.shortBy?.type === 'ASC'
                    ) {
                        setFiltersValueState({
                            ...filtersValueState,
                            shortBy: null
                        });
                    } else {
                        setFiltersValueState({
                            ...filtersValueState,
                            shortBy: {
                                criteria: 'price',
                                type: 'ASC'
                            }
                        });
                    }
                    break;
                }
                case FilterShortEnum.orderPriceMajorToMinor: {
                    if (
                        filtersValueState.shortBy?.criteria === 'price' &&
                        filtersValueState.shortBy?.type === 'DESC'
                    ) {
                        setFiltersValueState({
                            ...filtersValueState,
                            shortBy: null
                        });
                    } else {
                        setFiltersValueState({
                            ...filtersValueState,
                            shortBy: {
                                criteria: 'price',
                                type: 'DESC'
                            }
                        });
                    }
                    break;
                }
                case FilterShortEnum.orderDurationMinorToMajor: {
                    if (
                        filtersValueState.shortBy?.criteria === 'duration' &&
                        filtersValueState.shortBy?.type === 'ASC'
                    ) {
                        setFiltersValueState({
                            ...filtersValueState,
                            shortBy: null
                        });
                    } else {
                        setFiltersValueState({
                            ...filtersValueState,
                            shortBy: {
                                criteria: 'duration',
                                type: 'ASC'
                            }
                        });
                    }
                    break;
                }
                case FilterShortEnum.orderDurationMajorToMinor: {
                    if (
                        filtersValueState.shortBy?.criteria === 'duration' &&
                        filtersValueState.shortBy?.type === 'DESC'
                    ) {
                        setFiltersValueState({
                            ...filtersValueState,
                            shortBy: null
                        });
                    } else {
                        setFiltersValueState({
                            ...filtersValueState,
                            shortBy: {
                                criteria: 'duration',
                                type: 'DESC'
                            }
                        });
                    }
                    break;
                }
            }
        }
    };

export const resetFilters: (
    setFiltersValueState: React.Dispatch<React.SetStateAction<FiltersValueInterface>>,
    params: Params,
    currentPage: number
) => void = (
    setFiltersValueState: React.Dispatch<React.SetStateAction<FiltersValueInterface>>,
    params: Params,
    currentPage: number
) => {
        setFiltersValueState(getInitialValuesForFilter(params, currentPage));
    };

export const theUserHasFilters: (
    filtersValueState: FiltersValueInterface,
    params: Params
) => boolean = (filtersValueState: FiltersValueInterface, params: Params) => {
    const destination = params
        ? getRoute({ route: Route.Destination, params: { slug: params.slug! } })
        : '';
    return (
        filtersValueState.dateFilterValue !== '' ||
        filtersValueState.monthFilterValue !== '' ||
        filtersValueState.demandLevelFilterValue !== 0 ||
        (filtersValueState.durationFilterValue !== 0 &&
            filtersValueState.durationFilterValue !== 1) ||
        filtersValueState.ecosystemsFilterValue.length > 0 ||
        filtersValueState.favoritesFilterValue.length > 0 ||
        filtersValueState.priceFilterValue > 0 ||
        filtersValueState.quantityFiltersValue !== 0 ||
        filtersValueState.sealsFilterValue.length > 0 ||
        filtersValueState.typeOfExperiencesFilterValue.length > 0 ||
        filtersValueState.searchInput.length >= 3 ||
        (filtersValueState.destinationFilterValue !== '' &&
            filtersValueState.destinationFilterValue !== destination) ||
        (filtersValueState.shortBy !== null &&
            filtersValueState.shortBy.criteria !== 'price' &&
            filtersValueState.shortBy.type !== 'ASC')
    );
};

export const setFilterValueMethod: (
    newValue: string | number | number[],
    from: FiltersItemsEnum,
    setFiltersValueState: React.Dispatch<React.SetStateAction<FiltersValueInterface>>,
    filtersValueState: FiltersValueInterface
) => void = (
    newValue: string | number | number[],
    from: FiltersItemsEnum,
    setFiltersValueState: React.Dispatch<React.SetStateAction<FiltersValueInterface>>,
    filtersValueState: FiltersValueInterface
): void => {
        switch (from) {
            case FiltersItemsEnum.calendarDate: {
                if (setFiltersValueState && filtersValueState)
                    if (filtersValueState.dateFilterValue === newValue) {
                        setFiltersValueState({
                            ...filtersValueState,
                            dateFilterValue: '',
                            monthFilterValue: '',
                            page: 1
                        });
                    } else {
                        setFiltersValueState({
                            ...filtersValueState,
                            dateFilterValue: newValue + '',
                            monthFilterValue: '',
                            page: 1
                        });
                    }
                break;
            }
            case FiltersItemsEnum.destination: {
                if (setFiltersValueState && filtersValueState) {
                    setFiltersValueState({
                        ...filtersValueState,
                        destinationFilterValue: newValue + '',
                        page: 1,
                        searchInput: ''
                    });
                }
                break;
            }
            case FiltersItemsEnum.durationOfTravel: {
                if (setFiltersValueState && filtersValueState)
                    setFiltersValueState({
                        ...filtersValueState,
                        durationFilterValue: Number(newValue),
                        page: 1
                    });
                break;
            }
            case FiltersItemsEnum.ecosystems: {
                if (setFiltersValueState && filtersValueState)
                    setFiltersValueState({
                        ...filtersValueState,
                        ecosystemsFilterValue:
                            typeof newValue !== 'number' && typeof newValue !== 'string'
                                ? newValue
                                : [],
                        page: 1
                    });
                break;
            }
            case FiltersItemsEnum.favorites: {
                if (setFiltersValueState && filtersValueState)
                    setFiltersValueState({
                        ...filtersValueState,
                        favoritesFilterValue:
                            typeof newValue !== 'number' && typeof newValue !== 'string'
                                ? newValue
                                : [],
                        page: 1
                    });
                break;
            }
            case FiltersItemsEnum.monthDate: {
                if (setFiltersValueState && filtersValueState)
                    if (filtersValueState.monthFilterValue === newValue) {
                        setFiltersValueState({
                            ...filtersValueState,
                            monthFilterValue: '',
                            dateFilterValue: '',
                            page: 1
                        });
                    } else {
                        setFiltersValueState({
                            ...filtersValueState,
                            monthFilterValue: newValue + '',
                            dateFilterValue: '',
                            page: 1
                        });
                    }
                break;
            }
            case FiltersItemsEnum.physicalDemand: {
                if (setFiltersValueState && filtersValueState)
                    setFiltersValueState({
                        ...filtersValueState,
                        demandLevelFilterValue: Number(newValue),
                        page: 1
                    });
                break;
            }
            case FiltersItemsEnum.quantityOfTravelers: {
                if (setFiltersValueState && filtersValueState)
                    setFiltersValueState({
                        ...filtersValueState,
                        quantityFiltersValue: Number(newValue),
                        page: 1
                    });
                break;
            }
            case FiltersItemsEnum.rangeOfPrice: {
                if (setFiltersValueState && filtersValueState)
                    setFiltersValueState({
                        ...filtersValueState,
                        priceFilterValue: Number(newValue),
                        page: 1
                    });
                break;
            }
            case FiltersItemsEnum.seals: {
                if (setFiltersValueState && filtersValueState)
                    setFiltersValueState({
                        ...filtersValueState,
                        sealsFilterValue:
                            typeof newValue !== 'number' && typeof newValue !== 'string'
                                ? newValue
                                : [],
                        page: 1
                    });
                break;
            }
            case FiltersItemsEnum.typeOfExperiences: {
                if (setFiltersValueState && filtersValueState)
                    setFiltersValueState({
                        ...filtersValueState,
                        typeOfExperiencesFilterValue:
                            typeof newValue !== 'number' && typeof newValue !== 'string'
                                ? newValue
                                : [],
                        page: 1
                    });
                break;
            }
            case FiltersItemsEnum.page: {
                if (setFiltersValueState && filtersValueState)
                    setFiltersValueState({
                        ...filtersValueState,
                        page: Number(newValue)
                    });
                break;
            }
            case FiltersItemsEnum.countFilters: {
                if (setFiltersValueState && filtersValueState)
                    setFiltersValueState({
                        ...filtersValueState,
                        count: Number(newValue)
                    });
                break;
            }
        }
    };
