import ReactDOM from 'react-dom/client';
import '@root/index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from '@root/reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import RoutingComponent from '@shared/routing/Routing.component';
import { ToastContainer } from 'react-toastify';
import TagManager from 'react-gtm-module';
import { isUsingGtm } from '@shared/services/utils/Environment.util';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { ConfigurationProvider } from '@shared/context/Configuration.context';
import Tolstoy from '@shared/tolstoy/Tolstoy';
import './i18n';
import 'react-loading-skeleton/dist/skeleton.css'

if (isUsingGtm()) {
    const tagManagerArgs = {
        gtmId: 'GTM-NBRDQ6'
    };
    TagManager.initialize(tagManagerArgs);
}

Tolstoy.initialize();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <ConfigurationProvider>
        <I18nextProvider i18n={i18n}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
                <BrowserRouter>
                    <ToastContainer />
                    <RoutingComponent />
                </BrowserRouter>
            </GoogleOAuthProvider>
        </I18nextProvider>
    </ConfigurationProvider>
);
reportWebVitals();
