import imgOneMobile from '@assets/value-proposal/value_proposition_image_one-mobile.png';
import imgOne from '@assets/value-proposal/value_proposition_image_one.png';
import imgOneTablet from '@assets/value-proposal/value_proposition_image_one_tablet.png';
import imgThree from '@assets/value-proposal/value_proposition_image_three.png';
import imgThreeTablet from '@assets/value-proposal/value_proposition_image_three_tablet.png';
import imgThreeMobile from '@assets/value-proposal/value_proposition_image_three_mobile.png';
import imgTwo from '@assets/value-proposal/value_proposition_image_two.png';
import imgTwoTablet from '@assets/value-proposal/value_proposition_image_two_tablet.png';
import imgTwoMobile from '@assets/value-proposal/value_proposition_image_two_mobile.png';
import type { TFunction } from 'i18next';
import type { ValuesPropositionInterface } from '../interfaces/ValuesProposition.interface';

export const MockValueProposition = (t: TFunction<any, any>, isMobile: boolean, isTablet:boolean): ValuesPropositionInterface => {
	return {
		main: {
			title: t('valueProposition.main.title'),
			description: t('valueProposition.main.description')
		},
		collapseProposition: [
			{
				name: '',
				title: t('valueProposition.one.title'),
				description: t('valueProposition.one.description')
			},
			{
				name: '',
				title: t('valueProposition.two.title'),
				description: t('valueProposition.two.description')
			},
			{
				name: '',
				title: t('valueProposition.three.title'),
				description: t('valueProposition.three.description')
			}
		],
		img: [
			{
				id: 1,
				name: 'isMobile',
				image: isMobile ? imgOneMobile : (isTablet ? imgOneTablet : imgOne),
				url: 'url_de_la_imagen_móvil'
			},
			{
				id: 2,
				name: 'isMobile',
				image: isMobile ? imgTwoMobile : (isTablet ? imgTwoTablet : imgTwo),
				url: 'url_de_la_imagen_tablet'
			},
			{
				id: 3,
				name: 'isMobile',
				image:isMobile ? imgThreeMobile : (isTablet ? imgThreeTablet : imgThree),
				url: 'url_de_la_imagen_escritorio'
			}
		]
	};
};
