import { type Route } from '@shared/enums/Routes.enum';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import {
	type LanguageCode,
	type Prefix,
	Currency
} from '@shared/interfaces/DefaultPageConfigs.interface';
import { type DestinationsInfoDataInterface } from '@shared/interfaces/DestinationsInfo.interface';
import { formatterDollar, formatterPeso } from '@shared/services/format/Format.service';
import { useWindowSize } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';
import { defaultPageConfigs } from '../set-configs/defaultPageConfigs';

export function isACatchError(obj: any): obj is CatchErrorInterface {
	return ('error' in obj && 'message' in obj) || 'additional' in obj;
}

export function isNumber(value: string | number): boolean {
	return value !== null && value !== '' && !isNaN(Number(value.toString()));
}

export function getPercentInANumber(totalValue: number, currentValue: number): string {
	return (currentValue * 100) / totalValue + '%';
}

export function formatToSlug(url: string): string {
	return url
		.normalize('NFD')
		.replace(/ /g, '-')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(/[\W_]+/g, '-')
		.toLowerCase();
}
export function formatSlugToString(slug: string): string {
	return slug.replaceAll('-', ' ').toLowerCase();
}

export function findWordsInBraces<T = string>(inputString: string): T[] {
	const regex = /{([^{}]+)}/g;
	const matches = inputString.match(regex);

	if (!matches) {
		return [];
	}

	return matches.map((match) => match.slice(1, -1)) as T[];
}

export function getRoute({
	route,
	params = {},
	searchParams = '',
	toLanguage
}: {
	route: Route;
	params?: Record<string, string | number>;
	searchParams?: string;
	toLanguage?: LanguageCode;
}): string {
	const lang = toLanguage ?? getLangCode();
	const isDefaultLang = lang === defaultPageConfigs.language.code;

	let path: string = route;

	Object.keys(params).forEach((param) => {
		path = path.replace(`:${param}`, `${params[param]}`);
	});

	path = replacePrefixes(path, lang).replace('/:lang?', '');

	if (isDefaultLang) {
		if (path !== '') {
			return `${path}${searchParams}`;
		}

		return '/';
	}

	if (`/${lang}${path}` === `/${lang}/`) {
		return `/${lang}${searchParams}`;
	}

	return `/${lang}${path}${searchParams}`;
}

export function replacePrefixes(path: string, lang: LanguageCode): string {
	const prefixes = findWordsInBraces<keyof Prefix>(path);

	prefixes.forEach((prefix) => {
		path = path.replace(`{${prefix}}`, defaultPageConfigs.prefix[prefix][lang]);
	});

	return path;
}

export function getLangCode(): LanguageCode {
	const language = sessionStorage.getItem('lang');

	if (!language) {
		return defaultPageConfigs.language.code;
	}

	if (defaultPageConfigs.allowedLanguages.includes(language as LanguageCode)) {
		return language as LanguageCode;
	}

	return defaultPageConfigs.language.code;
}

export function getLangCodeOrDefault(lang?: string): LanguageCode {
	return (lang ?? defaultPageConfigs.language.code) as LanguageCode;
}

export function getExperienceSlug(url: string): string {
	const data = url.split('/');

	return data.at(-1)!;
}

export function setDestinationsSearch(destinations: any): any {
	const destinationList: DestinationsInfoDataInterface[] = [];

	destinations.forEach((option: DestinationsInfoDataInterface) => {
		destinationList.push(option);
		if (option.children) {
			option.children.forEach((child: DestinationsInfoDataInterface) => {
				destinationList.push(child);
			});
		}
	});
	sessionStorage.setItem('destinationList', JSON.stringify(destinationList));
}
export function removeAccent(text: string): string {
	return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function getDestinationsSearch(search: string): DestinationsInfoDataInterface[] {
	return JSON.parse(sessionStorage.getItem('destinationList')!).filter(
		(destination: DestinationsInfoDataInterface) => {
			return removeAccent(destination.name)
				.toLowerCase()
				.match(search ?? '');
		}
	);
}

export function formatTo(value: number, withCurrency: boolean = true): string {
	let currency = getCurrencyCode();

	const defaultFormatter = (value: number | bigint): string => formatterPeso.format(value);

	const formatters: Record<Currency, (value: number | bigint) => string> = {
		[Currency.COP]: defaultFormatter,
		[Currency.USD]: (value: number | bigint) => formatterDollar.format(value)
	};

	const formatter = formatters[currency] ?? defaultFormatter;
	currency = formatters[currency] ? currency : defaultPageConfigs.currency.code;

	const amount = formatter(value);
	return withCurrency ? `${amount} ${currency}` : amount;
}

export function setUtmData(searchParams: any): void {
	['utm_medium', 'utm_campaign', 'utm_content', 'utm_source'].map((index, value): any => {
		if (!sessionStorage.getItem(index) && searchParams.has(index))
			sessionStorage.setItem(index, searchParams.get(index));
		return true;
	});
}

export function removeUtmData(): void {
	Object.keys(sessionStorage)
		.filter((x) => x.startsWith('utm_'))
		.forEach((x) => sessionStorage.removeItem(x));
}

export function getCurrencyCode(): Currency {
	const currency = sessionStorage.getItem('currency');

	if (currency) {
		return currency as Currency;
	}

	return defaultPageConfigs.currency.code;
}

export function useResponsive(): {
	isXs?: boolean;
	isSm?: boolean;
	isMd?: boolean;
	isLg?: boolean;
	isXl?: boolean;
	isXxl?: boolean;
	isMobile?: boolean;
	isTablet?: boolean;
	isDesktop?: boolean;
} {
	const { width } = useWindowSize();

	if (!width) {
		return {};
	}

	const isXs = width < 576;
	const isSm = width >= 576 && width < 768;
	const isMd = width >= 768 && width < 992;
	const isLg = width >= 992 && width < 1200;
	const isXl = width >= 1200 && width < 1400;
	const isXxl = width >= 1400;

	const isMobile = isXs || isSm;
	const isTablet = isMd || isLg;
	const isDesktop = isXl || isXxl;

	return { isXs, isSm, isMd, isLg, isXl, isXxl, isMobile, isTablet, isDesktop };
}

export const useGetMonthName = (): ((month: number) => string) => {
	const { t } = useTranslation();
	const months = [
		t('global.months.january'),
		t('global.months.february'),
		t('global.months.march'),
		t('global.months.april'),
		t('global.months.may'),
		t('global.months.june'),
		t('global.months.july'),
		t('global.months.august'),
		t('global.months.september'),
		t('global.months.october'),
		t('global.months.november'),
		t('global.months.december')
	];
	return (month: number): string => months[month - 1];
};
