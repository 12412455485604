
import type{ CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import type{ RequestInterface } from '../request/interfaces/Request.interface';
import { sendRequest } from '../request/Request.service';
import type { StartConversationRequest, StartConversationResponse } from './interfaces/StartConversation.interface';

export const Start: (options: StartConversationRequest) => Promise<StartConversationResponse | CatchErrorInterface> = async ({
	name,
	phone,
	destination
}: StartConversationRequest): Promise<StartConversationResponse | CatchErrorInterface> => {

	const urlApi = 'conversations/start';
	const data: RequestInterface = {
		url: urlApi,
		method: 'POST',
		body: {
			name,
			phone: phone.replaceAll(' ', ''),
			destination
		}
	};
	
	return await sendRequest(data);
};