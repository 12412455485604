import { useEffect } from 'react';
import type { TolstoyConversion, TolstoyConversionProps } from './TolstoyConversion.interface';
import { getAuthSession } from '@shared/services/storage/SessionStorage.service';

const isActive = (): boolean => !!(process.env.REACT_APP_TOLSTOY_ACTIVE && process.env.REACT_APP_TOLSTOY_ACTIVE === 'true');

const initialize = (): void => {
  if (!isActive()) {
    return;
  }

  const head = document.head;

  const appKey = process.env.REACT_APP_TOLSTOY_APP_KEY!
  
  const scriptAppKey = document.createElement('script');
  scriptAppKey.textContent = `tolstoyAppKey="${appKey}"`;
  
  const scriptModule = document.createElement('script');
  scriptModule.type = 'module';
  scriptModule.async = true;
  scriptModule.src = 'https://widget.gotolstoy.com/we/widget.js';
  
  const scriptNoModule = document.createElement('script');
  scriptNoModule.type = 'text/javascript';
  scriptNoModule.setAttribute('nomodule', '');
  scriptNoModule.async = true;
  scriptNoModule.src = 'https://widget.gotolstoy.com/widget/widget.js';
  
  head.appendChild(scriptAppKey);
  head.appendChild(scriptModule);
  head.appendChild(scriptNoModule);
}

export const useTolstoyConversion = (props: TolstoyConversionProps | undefined): void => {
  useEffect(() => {
    if (!props || !isActive()) {
      return;
    }

    const tolstoyConversion: TolstoyConversion = {
      appKey: process.env.REACT_APP_TOLSTOY_APP_KEY!,
      appUrl: window.location.hostname,
      currency: props.amount.currency,
      email: getAuthSession().email,
      items: [
        {
          discounts: [{ amount: '0' }],
          price: props.amount.total.toString(),
          productId: props.productId,
          quantity: '1',
          title: props.productTitle,
          totalPrice: props.amount.total.toString(),
          variantId: props.productId
        }
      ],
      orderId: props.orderId,
      presentmentCurrency: props.amount.currency,
      totalPrice: props.amount.total.toString()
    };

    (window as any).tolstoyConversion = tolstoyConversion;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://widget.gotolstoy.com/track.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [props]);
};

export default {
  initialize
};