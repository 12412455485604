import { createContext, useContext, type ReactNode } from 'react';

type Environment = 'development' | 'production';

interface Configuration {
  environment: Environment;
  app: {
    url: string;
    phoneNumber: {
      awake: string;
      dmc: string;
      agencies: string;
    };
    production: boolean;
  };
  api: {
    url: string;
    client: {
      id: number;
      secret: string;
      grantType: string;
    }
  }
  google: {
    clientId: string;
    authUrl: string;
    mapsKey: string;
    captchaKey: string;
    trackId: string;
    useGTM: boolean;
  }
  dealsApi: {
    url: string;
    token: string;
  }
  crm: {
    url: string;
  }
}

const getValues = (): Configuration => {
  return {
    environment: process.env.REACT_APP_ENV! as Environment,
    app: {
      url: process.env.REACT_APP_COPY_LINK_URL!,
      phoneNumber: {
        awake: process.env.REACT_APP_AWAKE_MAIN_PHONE_NUMBER!,
        dmc: process.env.REACT_APP_AWAKE_DMC_PHONE_NUMBER!,
        agencies: process.env.REACT_APP_AWAKE_AGENCY_PHONE_NUMBER!
      },
      production: process.env.REACT_APP_ENV === 'production'
    },
    api: {
      url: process.env.REACT_APP_SERVER_URL!,
      client: {
        id: parseInt(process.env.REACT_APP_CLIENT_ID!),
        secret: process.env.REACT_APP_CLIENT_SECRET!,
        grantType: process.env.REACT_APP_GRAND_TYPE!
      }
    },
    google: {
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
      authUrl: process.env.REACT_APP_GOOGLE_AUTH_URL!,
      mapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY!,
      captchaKey: process.env.REACT_APP_GOOGLE_CAPTCHA_KEY!,
      trackId: process.env.REACT_APP_GOOGLE_TRACK_ID!,
      useGTM: process.env.REACT_APP_USE_GTM === 'true'
    },
    dealsApi: {
      url: process.env.REACT_APP_DEALS_API_URL!,
      token: process.env.REACT_APP_DEALS_API_TOKEN!
    },
    crm: {
      url: process.env.REACT_APP_INTEGRATIONS_URL!
    }
  }
}

export const ConfigurationContext: React.Context<Configuration | undefined> = createContext<Configuration | undefined>(undefined);

export const useConfiguration = (): Configuration => {
  const context = useContext(ConfigurationContext);

  if (!context) {
    throw new Error('useConfiguration should be used inside a ConfigurationProvider');
  }

  return context;
};

export const ConfigurationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const value = getValues();

  return (
    <ConfigurationContext.Provider value={value}>
      {children}
    </ConfigurationContext.Provider>
  );
}