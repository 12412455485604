import {
    Language,
    type DefaultPageConfigsInterface,
    type LanguageName,
    type CurrencyName,
    type LanguageCurrency,
    Currency
} from '@shared/interfaces/DefaultPageConfigs.interface';

export const LanguageNames: LanguageName = {
    es: 'Español',
    en: 'English'
};

export const CurrencyNames: CurrencyName = {
    COP: 'Peso Colombiano',
    USD: 'United States dollar'
};

export const LangCurrency: LanguageCurrency = {
    es: Currency.COP,
    en: Currency.COP
};

export const defaultPageConfigs: DefaultPageConfigsInterface = {
    currency: {
        code: Currency.COP,
        name: CurrencyNames[Currency.COP],
        rate: 1,
        round: 1000
    },
    language: {
        code: Language.Spanish,
        name: LanguageNames[Language.Spanish],
        currency: Currency.COP
    },
    allowedLanguages: [Language.Spanish, Language.English],
    allowedCurrencies: [Currency.COP, Currency.USD],
    prefix: {
        filters: {
            es: 'filtros',
            en: 'filters'
        },
        destination: {
            es: 'destino',
            en: 'destination'
        },
        travelGuide: {
            es: 'guia-de-viaje',
            en: 'travel-guide'
        },
        journey: {
            es: 'travesia',
            en: 'journey'
        },
        experience: {
            es: 'experiencia',
            en: 'experience'
        },
        summary: {
            es: 'resumen',
            en: 'summary'
        },
        booking: {
            es: 'reserva',
            en: 'booking'
        },
        addOns: {
            es: 'adicionales',
            en: 'add-ons'
        },
        update: {
            es: 'actualizar',
            en: 'update'
        },
        profile: {
            es: 'perfil',
            en: 'profile'
        },
        recoverAccount: {
            es: 'recuperar-cuenta',
            en: 'recover-account'
        },
        thankYou: {
            es: 'gracias',
            en: 'thank-you'
        },
        payment: {
            es: 'pago',
            en: 'payment'
        },
        detail: {
            es: 'detalle',
            en: 'detail'
        },
        bookings: {
            es: 'mis-reservas',
            en: 'my-reservations'
        },
        notifications: {
            es: 'notificaciones',
            en: 'notifications'
        },
        wishList: {
            es: 'experiencias-guardadas',
            en: 'wish-list'
        },
        referrals: {
            es: 'referidos',
            en: 'referrals'
        },
        form: {
            es: 'formulario',
            en: 'form'
        },
        giftCard: {
            es: 'bono-regalo',
            en: 'gift-card'
        },
        dataProcessing:{
            es: 'tratamiento-datos',
            en: 'data-processing'
        },   
        cookiesPolicy: {
            es: 'politica-cookies',
            en: 'cookies-policy'
        },
        joinTheGroup:{
            es:'unete-al-grupo',
            en:'join-the-group'
        },
        weLanding:{
            es:'nosotros',
            en:'we'
        }
    },
};
