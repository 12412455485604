import { Route } from '@shared/enums/Routes.enum';
import { useTranslation } from 'react-i18next';

export const CookiesPolicyComponent = (): JSX.Element => {
	const { t } = useTranslation();
	
	return (
		<>
			<div className="row d-flex justify-content-center awake-justify-text">
				<div className="col-10 card text-dark bg-light awake-mb-118 awake-mt-70 ">
					<div className="card-body">
						<h5 className="card-title">{t('cookies.policy.title')}</h5>
						<p className="card-text awake-p-10">{t('cookies.policy.content')}</p>
						<a href={Route.CookiesLinktreatmentPolicies}>{t('cookies.policy.treatmentPolicies')}</a>
					</div>
				</div>
			</div>
		</>
	);
};
