import Skeleton from 'react-loading-skeleton';
import './TravelGuidePage.skeleton.scss';
import { useResponsive } from '@shared/services/utils/Utils.services';
import React from 'react';

export const FilterCardResultSkeleton = (): JSX.Element => {
	const { isMobile, isTablet } = useResponsive();

	return (
		<>
			<div className="travel-guide-skeleton">
				<div className="row g-0 justify-content-center m-3">
					{React.Children.toArray(
						Array.from({ length: 16 }).map(() => (
							<>
								<div className="awake-wt-per-50 mt-3">
									<Skeleton className="d-flex awake-ht-269 w-100" />
								</div>
							</>
						))
					)}
				</div>
			</div>
		</>
	);
};
