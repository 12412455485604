import '@profile/components/saved-experiences-profile/ProfileSavedExperiences.component.scss';
import type { ExperienceCardPropsInterface } from '@shared/components/experience-card/interfaces/ExperienceCardProps.interface';
import { Link } from 'react-router-dom';
import { AppContext } from '@shared/context/App.context';
import { useContext, useEffect, useState } from 'react';
import { copyLink } from '@shared/services/copy-link/copy-link.service';
import { toggleExperienceWishList } from '@shared/services/wish-list/WishList.service';
import { showToastError, showToastSuccess } from '@shared/services/toast-service/toast.service';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import { formatTo, isACatchError, useResponsive } from '@shared/services/utils/Utils.services';
import type { ExperienceDataInterface } from '@shared/interfaces/Experience.interface';
import { getAuthSession } from '@shared/services/storage/SessionStorage.service';
import type { WishListResponseInterface } from '@shared/services/wish-list/interfaces/WishListResult.interface';
import { defaultPageConfigs } from '@shared/services/set-configs/defaultPageConfigs';
import { updateDataLayer } from '@shared/services/ga-service/GA.service';
import CampaignComponent from './components/Campaign.component';
import { ExperienceCardLabelsEN, ExperienceCardLabelsES } from './labels/ExperienceCard.labels';
import { useTranslation } from 'react-i18next';


function ExperienceCardComponent(props: ExperienceCardPropsInterface): JSX.Element {
	const { appContextValue, setAppContextMethod } = useContext(AppContext);
	const [experienceCardLabels, setExperienceCardLabels] = useState(ExperienceCardLabelsES);
	const { isMobile } = useResponsive();
	useEffect(() => {
		if (
			appContextValue.currentPageConfiguration?.language.code ===
			defaultPageConfigs.language.code
		) {
			setExperienceCardLabels(ExperienceCardLabelsES);
		} else {
			setExperienceCardLabels(ExperienceCardLabelsEN);
		}
	}, [appContextValue.currentPageConfiguration]);

	const updateParentList = (experienceToSend: ExperienceDataInterface): void => {
		setAppContextMethod({
			lastFavoriteExperienceModified: experienceToSend
		});
	};
	const hasLogin = (): any => {
		if (!getAuthSession()) {
			const experienceCopy = { ...props.experience };
			experienceCopy.inWishList = true;
			setAppContextMethod({
				showLogin: true,
				lastFavoriteExperienceModifiedTemp: experienceCopy
			});
			return false;
		}
		return true;
	};

	const toggleWishList = (): void => {
		toggleExperienceWishList(props.experience.id)
			.then((result: CatchErrorInterface | WishListResponseInterface) => {
				if (!isACatchError(result)) {
					const experienceCopy = { ...props.experience };
					experienceCopy.inWishList = result.data.inWishList;
					updateParentList(experienceCopy);
					showToastSuccess(
						experienceCopy.inWishList
							? experienceCardLabels.wishList.added
							: experienceCardLabels.wishList.removed
					);
				} else {
					console.error(result.error);
					showToastError(result.message);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const wishListClickAction = (): void => {
		if (hasLogin()) {
			toggleWishList();
		}
	};
	const { wishListAction } = useContext(AppContext);
	const { t } = useTranslation();

	return (
		<>
			{props.orientation === 'horizontal' ? (
				<div
					className={
						isMobile
							? 'awake-hover p-2 awake-experience-card__horizontal position-relative awake-bg-white rounded'
							: 'awake-hover p-4 m-2 awake-experience-card__horizontal position-relative awake-bg-white rounded'
					}>
					<Link
						className="d-flex awake-cursor-pointer no-underline awake-text-inherit"
						to={props.experience.url}
						onClick={updateDataLayer}>
						<div
							className="col-5 col-md-3 awake-experience-card__horizontal__img awake-cover-image position-relative rounded"
							style={{
								backgroundImage: ` url(${props.experience.image})`,
								backgroundSize: 'cover',
								backgroundPosition: 'center'
							}}>
							{props.experience.campaigns.length > 0 &&
								props.experience.campaigns[0].image && (
									<div className="col">
										<CampaignComponent
											{...props.experience.campaigns[0]}></CampaignComponent>
									</div>
								)}
						</div>

						<div className="col-7 col-md-8 awake-ps-20 d-flex flex-column justify-content-between gap-2">
							{props.experience.campaigns.length > 0 &&
								props.experience.campaigns[0].cardColor &&
								props.experience.campaigns[0].titleColor && (
									<CampaignComponent
										{...props.experience.campaigns[0]}></CampaignComponent>
								)}
							<div className="row g-0 text-start">
								<div className="col-12">
									<label
										id="select-item-card"
										className="awake-heading-5 awake-text-complementary awake-no-margin awake-fw-700 awake-cursor-pointer">
										{props.experience.name}
									</label>
								</div>
								<div className="col-12 awake-mt-5 awake-mb-15">
									<a
										id="select-item-card"
										className="icon-star awake-link-icon awake-experience-card__horizontal__icon"></a>
									<label
										id="select-item-card"
										className="awake-heading-6 awake-text-complementary awake-no-margin awake-ms-7">
										{props.experience.rating}{' '}
										{'(' +
											props.experience.comments +
											' ' +
											t('experienceCard.comments') +
											')'}
									</label>
								</div>
							</div>

							<div className="row g-0 text-start align-items-baseline">
								<div className="col-12 d-flex flex-column">
									<label
										id="select-item-card"
										className="awake-heading-6 awake-text-complementary awake-mb-5">
										{props.experience.duration}
									</label>
									<label
										id="select-item-card"
										className="awake-heading-5 awake-text-complementary awake-fw-700 awake-cursor-pointer">
										{formatTo(props.experience.price)}
									</label>
								</div>
							</div>
						</div>
					</Link>
					<div className="position-absolute awake-right-30 awake-bottom-23">
						<a
							className="icon-share awake-link-icon me-3"
							onClick={async () => {
								await copyLink(props.experience.url);
								showToastSuccess(t('experienceCard.shared'));
							}}></a>

						<a
							className={`icon-heart_plus awake-link-icon ${props.experience.inWishList ? 'awake-icon-primary' : ''
								}`}
							onClick={() => wishListAction ? wishListAction(props.experience) : false}>
						</a>
					</div>
				</div>
			) : (
				<div className="d-flex flex-column awake-hover awake-experience-card rounded awake-bg-white p-4 position-relative">
					{props.experience.campaigns.length > 0 && (
						<CampaignComponent {...props.experience.campaigns[0]}></CampaignComponent>
					)}
					<Link
						to={props.experience.url}
						onClick={updateDataLayer}
						className="awake-experience-card__content awake-cursor-pointer no-underline d-grid gap-2">
						<div className="row g-0">
							<div>
								<img
									id="select-item-card"
									src={props.experience.image}
									className="awake-experience-card__img d-flex justify-content-center mx-auto rounded awake-cover-image awake-wt-250 awake-ht-250 awake-wt-xl-300 awake-ht-xl-300"></img>
							</div>
						</div>
						<div className="awake-experience-card__info mx-auto d-flex flex-column gap-3">
							<div className="col awake-experience-card__name">
								<label
									id="select-item-card"
									className="awake-heading-5 awake-no-margin awake-fw-700 awake-block-format awake-cursor-pointer text-start">
									{props.experience.name}
								</label>
							</div>
							<div className="col">
								<a id="select-item-card" className="icon-star awake-link-icon"></a>
								<label id="select-item-card" className="awake-heading-6">
									{props.experience.rating}{' '}
									{'(' +
										props.experience.comments +
										' ' +
										t('experienceCard.comments') +
										')'}
								</label>
							</div>
							<div className="d-flex flex-column gap-2">
								<label
									id="select-item-card"
									className="awake-heading-6 awake-no-margin">
									{props.experience.duration}
								</label>
								<label className="awake-heading-5 awake-fw-700 awake-no-margin">
									{formatTo(props.experience.price)}
								</label>
							</div>
						</div>
					</Link>

					<div className="awake-experience-card__actions d-flex position-absolute gap-3 awake-right-25 awake-bottom-25">
						<a
							className="icon-share awake-link-icon"
							onClick={async () => {
								await copyLink(props.experience.url);
								showToastSuccess(t('experienceCard.shared'));
							}}></a>

						<a
							className={`icon-heart_plus awake-link-icon ${props.experience.inWishList ? 'awake-icon-primary' : ''
								}`}
							onClick={() => wishListAction ? wishListAction(props.experience) : false}>
						</a>
					</div>
				</div>
			)}
		</>
	);
}

export default ExperienceCardComponent;
