import { ScreenDesktop } from '@shared/components/screens/ScreenDesktop.component';
import { ScreenDimensions } from '@shared/components/screens/ScreenDimensions.component';
import { ScreenMobile } from '@shared/components/screens/ScreenMobile.component';
import { JourneyPageSkeleton } from '@shared/components/skeletons/JourneyPage.skeleton';
import WhatsAppComponent from '@shared/components/whats-app-button/WhatsAppButton.component';
import { useResponsive } from '@shared/services/utils/Utils.services';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../error-page/Error.page';
import './Journey.page.scss';
import HeaderComponent from './components/Header.component';
import { Item } from './components/Item.component';
import PropositionCrossing from './components/PropositionCrossing.component';
import { Quoter } from './components/Quoter.component';
import { useJourney } from './hooks/Journey.hook';

function JourneyPage({ isLoading: initialIsLoading = false }: { isLoading?: boolean; }): JSX.Element {
	const itemsRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();
	const { isMobile, isTablet, isDesktop } = useResponsive();
	const [isLoading, setIsLoading] = useState<boolean>(initialIsLoading);

	const { found, isLoading: hookIsLoading, result } = useJourney();

	const getImages = (images: any): JSX.Element => {
		return (
			<>
				{React.Children.toArray(
					images.map((image: any, index: number) => (
						<div className="">
							<img
								className={`awake-cover-image awake-wt-per-100 ${index % 2 !== 0 && isDesktop && 'awake-ht-per-90'
									}`}
								src={image}
							/>
						</div>
					))
				)}
			</>
		);
	};

	useEffect(() => {
		setIsLoading(initialIsLoading);
	}, [initialIsLoading]);

	useEffect(() => {
		setIsLoading(hookIsLoading);
	}, [hookIsLoading]);

	return (
		<>
			{!isLoading && !found && <ErrorPage />}

			{isLoading && <JourneyPageSkeleton />}

			{!isLoading && result && (
				<>
					<div className="d-flex flex-column gap-4 gap-md-5 journey-page">
						<div className="row g-0">
							<div className="d-flex flex-row pt-lg-5 journey-bg-top">
								<div className="container-lg">
									<div className="row g-0">
										<div className="col-12 col-lg-7 gx-0 justify-content-end">
											<div className="d-flex flex-column gap-3 gap-lg-5">
												<div className="d-flex flex-row justify-content-center">
													<div className="col-11">
														<div className="d-flex flex-column-reverse flex-lg-row py-3 py-lg-0 gap-3 gap-lg-4 justify-content-end align-items-center">
															<HeaderComponent title={result.title} />
														</div>
													</div>
												</div>
												<div className="d-flex justify-content-center align-items-end align-items-lg-center">
													{getImages(result.images)}
												</div>
												<ScreenDesktop>
													<div className="d-flex flex-column gap-4">
														<div className="d-flex flex-column gap-4 align-items-start">
															<h3 className="awake-fw-700 awake-heading-3 awake-text-secondary awake-ps-60">
																<span className="pe-3 awake-heading-4 awake-icon-secondary icon-emoji_flags"></span>
																{t('journey.featured')}
															</h3>
														</div>
														<div className="d-flex flex-column gap-4 align-items-center">
															{React.Children.toArray(
																result.featured.map(
																	(option: any) => (
																		<div className="d-flex flex-row">
																			<img
																				className={`awake-cover-image awake-ht-120 awake-wt-120 rounded ${isMobile &&
																					'awake-wt-per-100'
																					}`}
																				src={option.image}
																			/>
																			<div className="d-flex flex-column ps-4">
																				<div className="awake-wt-500">
																					<h5 className="awake-fw-700 awake-heading-5 awake-text-secondary pe-2">
																						{
																							option.title
																						}
																					</h5>
																					<h5 className="awake-fw-400 awake-heading-5 awake-text-secondary pe-2">
																						{
																							option.description
																						}
																					</h5>
																				</div>
																			</div>
																		</div>
																	)
																)
															)}
														</div>
													</div>
												</ScreenDesktop>
											</div>
										</div>
										<div
											className={`col-12 col-md-10 offset-md-1 offset-lg-0 col-lg-5 gx-0 justify-content-start ${isTablet && 'awake-mt-n-50'
												}`}>
											<Quoter
												{...result.quoter}
												title={result.title}
												id={result.id}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<ScreenDimensions mobile tablet>
							<div className="row g-0">
								<div className="col-12">
									<div className="container">
										<div className="flex px-4 pb-3">
											<h3 className="awake-fw-700 awake-heading-3 awake-text-secondary">
												<span className="pe-3 awake-heading-4 awake-icon-secondary icon-emoji_flags"></span>
												{t('journey.featured')}
											</h3>
										</div>
										<div className="d-flex flex-column gap-4 align-items-center px-4">
											{React.Children.toArray(
												result.featured.map((option: any) => (
													<div className="d-flex flex-column flex-md-row gap-3">
														<img
															className={`awake-cover-image awake-top-image awake-ht-120 rounded ${isMobile
																? 'awake-wt-per-100'
																: 'awake-wt-120'
																}`}
															src={option.image}
														/>
														<div className="d-flex flex-column ps-lg-4">
															<div className="">
																<h5 className="awake-fw-700 awake-heading-5 awake-text-secondary pe-2">
																	{option.title}
																</h5>
																<h5 className="awake-fw-400 awake-heading-5 awake-text-secondary pe-2">
																	{option.description}
																</h5>
															</div>
														</div>
													</div>
												))
											)}
										</div>
									</div>
								</div>
							</div>
						</ScreenDimensions>
						<PropositionCrossing />
						<div className="row g-0" ref={itemsRef}>
							<div className="items position-relative">
								<div className="row g-0 justify-content-center">
									<div className="col-lg-8">
										<div className="d-flex flex-column justify-content-center px-4">
											<h2 className="awake-text-journey awake-heading-2 text-center mb-4 my-md-4 awake-fw-900">
												{t('journey.details')}
											</h2>
										</div>
									</div>
									<div className="col-12 col-lg-10 mt-lg-4 px-4 px-lg-4 pb-5 pb-lg-0">
										<div className="d-flex flex-column justify-content-center align-items-center gap-2 gap-md-4 pb-4 pb-md-5 pb-lg-0">
											{React.Children.toArray(
												result.items.map((option: any, index: number) => (
													<Item
														key={index}
														{...option}
														id={option.key}
														startOpened={false}
														textAlign="start"
														backgroundColor="journey-item"
													/>
												))
											)}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row g-0">
							<div className="d-flex flex-row position-relative py-lg-5 journey-bg-bottom">
								<div className="container">
									<div className="row g-0">
										<div className="col-12 col-lg-7 gx-0 position-relative d-flex align-items-end">
											<div className="row g-0 justify-content-center gap-5 position-lg-absolute bottom-0">
												<div className="col-12 col-lg-11">
													<div className="d-flex flex-column gap-lg-5">
														<div className="d-flex flex-column">
															<div
																className="d-flex flex-column awake-bg-white rounded gap-3"
																style={{
																	boxShadow:
																		'0px 4px 4px 0px #0000000D'
																}}>
																<div className="d-flex flex-row justify-content-center px-md-5 px-lg-5 align-items-center journey-footer-images">
																	{getImages(result.images)}
																</div>
																<div className="p-4 px-lg-5 ">
																	<h4 className="awake-fw-700 awake-text-journey pe-2 awake-heading-4">
																		{result.info.title}
																	</h4>
																	<h5 className="awake-fw-400 awake-text-secondary pe-2 awake-heading-5">
																		{result.info.description}
																	</h5>
																</div>
															</div>
														</div>
														<div
															className={`${isMobile && 'journey-bg-mobile'
																} d-flex flex-column-reverse py-3 py-lg-0  flex-lg-row gap-1 gap-lg-4 justify-content-end align-items-center`}>
															<ScreenDimensions mobile desktop>
																<HeaderComponent
																	title={result.title}
																/>
															</ScreenDimensions>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 col-lg-5 gx-0 justify-content-start">
											<ScreenDimensions tablet desktop>
												<Quoter
													{...result.quoter}
													title={result.title}
													id={result.id}
												/>
											</ScreenDimensions>
											<ScreenMobile>
												<div className="awake-bg-white pb-5">
													<Quoter
														{...result.quoter}
														title={result.title}
														id={result.id}
													/>
												</div>
											</ScreenMobile>
											<ScreenDimensions tablet>
												<div className="d-flex flex-row align-items-center py-4">
													<HeaderComponent
														title={result.title}
														footer={true}
													/>
												</div>
											</ScreenDimensions>
										</div>
									</div>
								</div>
							</div>
						</div>
						<WhatsAppComponent
							message={t('journey.whatsappSms')}
							messageToSend={t('journey.whatsappSms')}
						/>
					</div>
				</>
			)}
		</>
	);
}

export default JourneyPage;
