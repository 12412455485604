import { WishListAPIsEnum } from '@shared/enums/WishListAPIs.enum';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import type { WishListResponseInterface } from '@shared/services/wish-list/interfaces/WishListResult.interface';
import { sendRequest } from '@shared/services/request/Request.service';
import type { RequestInterface } from '@shared/services/request/interfaces/Request.interface';

export const toggleExperienceWishList: (
    id: number
) => Promise<WishListResponseInterface | CatchErrorInterface> = async (
    id: number
): Promise<WishListResponseInterface | CatchErrorInterface> => {
    const data: RequestInterface = {
        url: WishListAPIsEnum.WishListUrl.replace(':experienceId', id.toString()),
        method: 'PUT'
    };

    return await sendRequest(data);
};
