import { useResponsive } from '@shared/services/utils/Utils.services';
import Slider from 'react-slick';
import imgAliados from '@assets/landingProposed/aliados.png';

const SliderLandingProposed = (): JSX.Element => {
	const { isMobile, isTablet, isDesktop } = useResponsive();
	const commonSettings = {
		dots: false,
		infinite: true,
		autoplay: true,
		speed: 12000,
		autoplaySpeed: 0,
		slidesToShow: 1,
		cssEase: 'linear',
		draggable: false,
		pauseOnHover: false
	};
	const renderSlider = (images: string[], settings: any): any => (
		<Slider {...settings}>
			{images.map((src, index) => (
				<img key={index} className="awake-wt-390" src={src} alt={`slide-${index}`} />
			))}
		</Slider>
	);
	const mobileImages = [imgAliados, imgAliados];
	return (
		<>
			{isMobile && (
				<div className="slider-container">{renderSlider(mobileImages, commonSettings)}</div>
			)}
			{!isMobile && (
				<div className="d-flex justify-content-center mt-4 ">
					<img src={imgAliados} alt="" />
				</div>
			)}
		</>
	);
};

export default SliderLandingProposed;