import { type ToastPosition, toast } from 'react-toastify';
import _ from 'lodash';

export const showToastSuccess: (
	messageToShow: any,
	position?: ToastPosition,
	showIcon?: boolean
) => void = (
	messageToShow: string,
	position: ToastPosition = 'bottom-center',
	showIcon: boolean = true
): void => {
	toast.success(messageToShow, {
		position,
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
		icon: showIcon
	});
};
export const showToastError: (messageToShow: string, position?: ToastPosition) => void = (
	messageToShow: string,
	position: ToastPosition = 'bottom-center'
): void => {
	toast.success(messageToShow, {
		position,
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
		type: 'error'
	});
};

export const showToastSuccessInfo: (
	messageToShow: any,
	position?: ToastPosition,
	showIcon?: boolean
) => void = (
	messageToShow: string,
	position: ToastPosition = 'bottom-center',
	showIcon: boolean = true
): void => {
	toast.info(messageToShow, {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light'
	});
};
