import ErrorSectionComponent from '@error-page/components/ErrorSection.component';
import WhatsAppComponent from '@shared/components/whats-app-button/WhatsAppButton.component';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function ErrorPage(): JSX.Element {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="error-page">
            <ErrorSectionComponent/>
            <WhatsAppComponent placeholder={t('whatsapp.placeholder')}/>
        </div>
    );
}

export default ErrorPage;
