import { AppContext } from '@shared/context/App.context';
import { showToastError } from '@shared/services/toast-service/toast.service';
import { useContext, useEffect, useState } from 'react';
import { reserve } from '../services/Journey.service';
import { type Reservation } from '../interfaces/Response.interface';
import { isACatchError } from '@shared/services/utils/Utils.services';
import { createPayment } from '@shared/services/payment/Payment.service';
import { PaymentEntity } from '@shared/enums/Payment.enum';
import { type PaymentLink } from '@shared/interfaces/Payment.interface';
import { useFormik } from 'formik';
import { userPhoneSchema } from '@shared/validations/UserPhoneForm.validations';
import { type UserPhoneInterface } from '@root/modules/reserve-summary/components/login-section/interfaces/UserPhoneInterface';
import { useTranslation } from 'react-i18next';


export const useJourneyQuoter = (id:number): any => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const { appContextValue, setAppContextMethod } = useContext(AppContext);
  const { t } = useTranslation();

  const handleReserve = async (phone: string): Promise<void> => {
    if (!appContextValue.hasLogin) {
      setAppContextMethod({
        showLogin: true,
      });
      setIsLogin(true)
      return
    }
    try {
      setIsLoading(true);

      const { id } = await createReserve(phone);
      const { url } = await createPaymentLink(id);

      window.location.href = url;
    } catch (ex: any) {
      showToastError(t('global.requestError'));
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }

  const createReserve = async (phone: string): Promise<Reservation> => {
    const response = await reserve({
      data: {
        id,
        phone
      },
      currentPageConfiguration: appContextValue.currentPageConfiguration!
    });

    if (isACatchError(response)) {
      throw new Error(response.message.message);
    }

    return response.data;
  }

  const createPaymentLink = async (id: number): Promise<PaymentLink> => {
    const response = await createPayment({
      id,
      entity: PaymentEntity.JourneyReservation,
      currentPageConfiguration: appContextValue.currentPageConfiguration!
    });

    if (isACatchError(response)) {
      throw new Error(response.message.message);
    }

    return response.data;
  }

  useEffect(() => {
    if (appContextValue.hasLogin && isLogin) {
      void handleReserve(formikUserPhone.values.phone)
    }
  }, [appContextValue.hasLogin])

  const formikUserPhone = useFormik({
    initialValues: {
      phone: ''
    },
    validationSchema: userPhoneSchema(),
    onSubmit: (values: UserPhoneInterface) => {
      void handleReserve(values.phone)
    }
  });
    return {
      formikUserPhone,
      isLoading,
    }
}
