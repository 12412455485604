import Skeleton from 'react-loading-skeleton';
import './TravelGuidePage.skeleton.scss';
import { useResponsive } from '@shared/services/utils/Utils.services';
import React from 'react';

export const TravelGuidePageSkeleton = (): JSX.Element => {
  const { isMobile, isTablet } = useResponsive();

  return (
    <>
      <div className="travel-guide-skeleton">
        <Skeleton className='banner' />

        <div className="d-flex flex-column py-2 py-md-4 py-lg-4 gap-2 gap-md-4 gap-lg-5">
          <div className="info">
            <div className="row g-0 justify-content-center">
              <div className="col-md-10 col-lg-8">
                <div className="d-flex flex-column justify-content-center px-4">
                  <div className="d-flex justify-content-center">
                    <div className="awake-wt-per-50">
                      <Skeleton className="awake-heading-2 my-4" />
                    </div>
                  </div>

                  <Skeleton className={`d-flex ${isTablet ? 'awake-ht-300' : (isMobile ? 'awake-ht-175' : 'awake-ht-500')}`} />

                  <Skeleton count={5} />
                </div>
              </div>
            </div>
          </div>

          <div className="row g-0 justify-content-center">
            <div className="col-lg-8">
              <div className="d-flex flex-column justify-content-center px-4">
                <div className="d-flex justify-content-center">
                  <div className="awake-wt-per-50">
                    <Skeleton className="awake-heading-2 my-4" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-10 mt-2 mt-lg-4 px-0 px-lg-4">
              <div className="d-flex flex-column justify-content-center align-items-center gap-2 gap-md-4">
                <div className="col-12 col-md-11 col-lg-10 px-lg-2 rounded">
                  <Skeleton height={400} />
                </div>

                {React.Children.toArray(
                  Array.from({ length: 5 }).map(() => (
                    <>
                      <div className="col-12 col-md-11 col-lg-10 px-lg-2 rounded">
                        <Skeleton height={50} />
                      </div>
                    </>
                  ))
                )}
              </div>
            </div>
          </div>

          <div className="row g-0 justify-content-center">
            <div className="col-lg-8">
              {React.Children.toArray(
                Array.from({ length: 5 }).map(() => (
                  <>
                    <div className="d-flex flex-column justify-content-center px-4">
                      <div className="d-flex justify-content-center">
                        <div className="awake-wt-per-50">
                          <Skeleton className="awake-heading-2 my-4" />
                        </div>
                      </div>

                      <Skeleton count={5} />
                    </div>
                  </>
                ))
              )}
            </div>
          </div>

        </div>
      </div>
    </>
  );
};