import type { ExperienceCardLabelsInterface } from '@shared/components/experience-card/interfaces/ExperienceCardLabels.interface';

export const ExperienceCardLabelsES: ExperienceCardLabelsInterface = {
    currencyAcronym: 'COP',
    comments: 'comentarios',
    wishList: {
        added: 'Experiencia agregada a favoritos',
        removed: 'Experiencia eliminada de favoritos'
    },
    shared: 'Link copiado'
};

export const ExperienceCardLabelsEN: ExperienceCardLabelsInterface = {
    currencyAcronym: 'COP',
    comments: 'comments',
    wishList: {
        added: 'Experience added to favorites',
        removed: 'Experience eliminated from favorites'
    },
    shared: 'Link copied'
};
