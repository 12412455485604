import { JourneyAPIsEnum } from '@shared/enums/JourneyAPIs.enum';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import type { PageConfigurationInterface } from '@shared/interfaces/PageConfiguration.interface';
import { sendRequest } from '@shared/services/request/Request.service';
import type { RequestInterface } from '@shared/services/request/interfaces/Request.interface';
import type { ReserveRequest } from '../interfaces/Request.interface';
import type { Journey, Reservation } from '../interfaces/Response.interface';

export const get = async ({
	slug,
	currentPageConfiguration
}: {
	slug: string;
	currentPageConfiguration: PageConfigurationInterface;
}): Promise<{ data: Journey; } | CatchErrorInterface> => {
	const data: RequestInterface = {
		url: JourneyAPIsEnum.FindJourney.replace(':slug', slug),
		method: 'GET',
		currentPageConfiguration
	};

	return await sendRequest(data);
}

export const reserve = async ({
	data,
	currentPageConfiguration
}: {
	data: ReserveRequest;
	currentPageConfiguration: PageConfigurationInterface;
}): Promise<{ data: Reservation; } | CatchErrorInterface> => {
	return await sendRequest({
		url: JourneyAPIsEnum.Reserve,
		method: 'POST',
		currentPageConfiguration,
		body: data
	});
}