import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import WhatsAppComponent from '../whats-app-button/WhatsAppButton.component';

const DataProcessingComponent = (): JSX.Element => {
	const { t } = useTranslation();
	const uses: string[] = t('dataProcessing.purposeOfDataProcessing.uses', {
		returnObjects: true
	});
	const rightsList: string[] = t(
		'dataProcessing.purposeOfDataProcessing.informsTheDataSubject.rightsList',
		{ returnObjects: true }
	);

	return (
		<>
			<div className="row d-flex justify-content-center awake-justify-text">
				<div className="col-lg-8 col-sm-10 awake-bg-complementary-3 awake-br-8 awake-p-40 awake-mt-40">
					<label className='awake-heading-2 awake-mb-20'>{t('dataProcessing.title')}</label>
					<label>{t('dataProcessing.head')}</label>
					<label className="awake-fw-700 awake-heading-3 awake-mt-20 awake-mb-20">
						{t('dataProcessing.generalData.title')}
					</label>
					<div>
						<p>
							{t('dataProcessing.generalData.name')}{' '}
							{t('dataProcessing.generalData.nit')}{' '}
							{t('dataProcessing.generalData.location')}{' '}
							<Link to={'/'}>{t('dataProcessing.generalData.website')}</Link>
							<br />
						</p>
						{t('dataProcessing.generalData.termsAndConditions')}
					</div>
					<label className="awake-mb-20">
						{t('dataProcessing.dataProcessingAuthorisation.title')}
					</label>
					<p>{t('dataProcessing.dataProcessingAuthorisation.content')}</p>
					<label>{t('dataProcessing.purposeOfDataProcessing.title')}</label>
					<br />
					<label className="awake-mt-20">
						{t('dataProcessing.purposeOfDataProcessing.description')}
					</label>
					<ol className="awake-mt-20">
						{uses.map((use: any, index: any): any => (
							<li key={index}>{use}</li>
						))}
					</ol>
					<label>
						{t('dataProcessing.purposeOfDataProcessing.informsTheDataSubject.content')}
					</label>
					<ol className="awake-mt-20" type="a">
						{rightsList.map((right: any, index: any): any => (
							<li key={index}>{right}</li>
						))}
					</ol>
					<label className="awake-mb-20">
						{t('dataProcessing.purposeOfDataProcessing.email')}
					</label>
					<br />
					<Link to={'mailto:info@awake.travel'}>
						{t('dataProcessing.purposeOfDataProcessing.emailAwake')}
					</Link>

					<br />
					<label className="awake-mt-20 awake-mb-20">
						{t('dataProcessing.purposeOfDataProcessing.phone')}
					</label>
					<p>{t('dataProcessing.purposeOfDataProcessing.phoneAwake')}</p>
					<label className="awake-heading-3 awake-fw-700 awake-mb-20">
						{t('dataProcessing.purposeOfDataProcessing.informationSecurity.title')}
					</label>
					<p>
						{t('dataProcessing.purposeOfDataProcessing.informationSecurity.commitment')}{' '}
						<Link to={'mailto:gea@awake.travel'}>
							{t('dataProcessing.purposeOfDataProcessing.informationSecurity.email')}
						</Link>
					</p>
				</div>
			</div>
			<WhatsAppComponent/>
		</>
	);
};

export default DataProcessingComponent;
