import awakeBrand from '@assets/common/awake-brand-green.svg';
import { useRef, useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import type { AuthComponentPropsInterface } from '@auth/interfaces/AuthComponentProps.interface';
import { useFormik } from 'formik';
import { RegisterFormValidations } from '@auth/components/register/validations/RegisterForm.validations';
import type { RegisterFormInterface } from '@auth/components/register/interfaces/RegisterForm.interface';
import { AuthPageOptionsEnum } from '@auth/enums/AuthPageOptions.enum';
import { isACatchError } from '@shared/services/utils/Utils.services';
import { showToastError, showToastSuccess } from '@shared/services/toast-service/toast.service';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import {
	getAuthSession,
	setAuthUser,
	setUserData
} from '@shared/services/storage/SessionStorage.service';
import { authUserWithGoogle, registerUser } from '@shared/services/auth-service/Auth.service';
import ToastComponent from '@shared/components/toast/ToastIcon.component';
import ReCAPTCHA from 'react-google-recaptcha';
import type {
	AuthPageResponseDataInterface,
	AuthPageResponseInterface
} from '@shared/services/auth-service/interfaces/AuthPageResult.interface';
import { updateDataLayer } from '@shared/services/ga-service/GA.service';
import { AppContext } from '@shared/context/App.context';
import { useGoogleLogin } from '@react-oauth/google';
import { getUserProfile } from '@root/modules/profile/service/UserProfile.service';
import type { ProfileInfoUserData } from '@root/modules/profile/interface/ProfileUserData.interface';
import PhoneInput from 'react-phone-input-2';

function RegisterComponent(props: AuthComponentPropsInterface): JSX.Element {
	const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
	const [iAmHost, setIAmHost] = useState<boolean>(false);
	const recaptchaRegisterRef = useRef<ReCAPTCHA>(null);
	const { appContextValue, setAppContextMethod } = useContext(AppContext);

	const formik = useFormik({
		initialValues: {
			email: '',
			confirmationEmail: '',
			name: '',
			phone: '',
			'g-recaptcha-response': ''
		},
		validationSchema: RegisterFormValidations(props.authLabels, props.formValidationsLabels),
		onSubmit: async (values: RegisterFormInterface) => {
			updateDataLayer();
			await recaptchaRegisterRef.current?.executeAsync();
			registerUser(values)
				.then(async (result: CatchErrorInterface | AuthPageResponseInterface) => {
					if (!isACatchError(result)) {
						formik.resetForm();
						setAuthUser(result.data);
						setAppContextMethod({
							showLogin: false,
							hasLogin: true
						});

						const profile = (await getUserProfile()) as ProfileInfoUserData;
						setUserData(profile.data);

						showToastSuccess(
							<ToastComponent message={props.authLabels.welcome}></ToastComponent>,
							'top-right',
							false
						);
						props.toggleAuthPageMethod();
					} else {
						showToastError(result.message.message);
					}
					recaptchaRegisterRef.current?.reset();
				})
				.catch((error) => {
					console.error(error);
				});
		}
	});

	const verifyFavorite = (): void => {
		if (appContextValue.lastFavoriteExperienceModifiedTemp) {
			setAppContextMethod({
				showLogin: false,
				lastFavoriteExperienceModified: appContextValue.lastFavoriteExperienceModifiedTemp,
				lastFavoriteExperienceModifiedTemp: undefined
			});
			showToastSuccess(props.authLabels.withGoogle);
		}
	};

	const googleLogin = useGoogleLogin({
		flow: 'auth-code',
		onSuccess: async (tokenResponse) => {
			authUserWithGoogle(tokenResponse.code)
				.then((result: CatchErrorInterface | AuthPageResponseDataInterface) => {
					if (!isACatchError(result)) {
						if (setAuthUser(result).success) {
							getUserProfile()
								.then((result: CatchErrorInterface | ProfileInfoUserData) => {
									if (!isACatchError(result)) {
										setUserData(result.data);
										showToastSuccess(
											<ToastComponent
												message={
													props.authLabels.loginSuccess +
													' ' +
													getAuthSession().username
												}></ToastComponent>,
											'top-right',
											false
										);
										setAppContextMethod({
											showLogin: false,
											hasLogin: true
										});
									} else {
										if (result.message.statusCode === 401) {
											showToastError(props.authLabels.userAlreadyExists);
										} else {
											console.error(result.error);
										}
									}
								})
								.catch((error) => {
									console.error(error);
								});
							props.toggleAuthPageMethod();
							verifyFavorite();
						} else {
							showToastError('Error');
						}
					} else {
						if (result.message.statusCode === 401) {
							showToastError(props.authLabels.userAlreadyExists);
						} else {
							console.error(result.error);
						}
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	});
	

	return (
		<div className="register-component container-fluid awake-ps-container awake-pe-container">
			<Form onSubmit={formik.handleSubmit}>
				<div className="row">
					<div className="col-12 text-center">
						<img
							className="login-component__logo"
							src={awakeBrand}
							alt="awake-logo"></img>
					</div>
				</div>
				<div className="row awake-mt-10">
					<div className="col-12 awake-text-center">
						<label className="awake-heading-3 awake-fw-700">
							{props.authLabels.createAccountTitle}
						</label>
					</div>
				</div>
				<div className="row awake-mt-10">
					<div className="col-12 awake-text-center">
						<label className="awake-heading-4">
							{props.authLabels.createAccountSubTitle}
						</label>
					</div>
				</div>
				<div className="row awake-mt-20">
					<div
						className={
							'col-12 col-lg-6 col-xl-6 ' +
							(window.innerWidth < 1200 ? 'awake-mb-10' : '')
						}>
						<button
							onClick={() => {
								googleLogin();
							}}
							className={
								'awake-google-button awake-fw-700 awake-d-block awake-ps-20 awake-wt-276 awake-ht-57 ' +
								(window.innerWidth < 1200
									? 'awake-ms-auto awake-me-auto'
									: 'awake-ms-auto')
							}>
							<a className="icon-google awake-link-icon awake-me-5"></a>
							<label className="awake-heading-5 awake-fw-700">
								{props.authLabels.withGoogle}
							</label>
						</button>
					</div>
				</div>
				<div className="row awake-mt-20">
					<div
						className={
							'col-12 col-lg-7 col-xl-7 ' +
							(window.innerWidth < 1200 ? 'awake-ms-auto awake-me-auto' : 'offset-1')
						}>
						<label
							className={
								'awake-heading-4 ' +
								(window.innerWidth < 1200
									? 'awake-d-block text-center'
									: 'awake-ms-40')
							}>
							{props.authLabels.orFillTheNextFields}
						</label>
					</div>
				</div>
				<div className={'row awake-mt-20'}>
					<div
						className={
							'col-12 col-lg-6 col-xl-6 ' +
							(window.innerWidth < 1200 ? 'awake-mb-26' : '')
						}>
						<div
							className={`${
								formik.touched.email && formik.errors.email
									? 'invalid'
									: formik.values.email !== ''
									? 'valid'
									: 'awake-input__black'
							} ${
								window.innerWidth > 1200
									? 'float-end'
									: ' awake-ms-auto awake-me-auto'
							} awake-wt-276 awake-input`}>
							<label htmlFor="name" className="awake-input__label awake-bg-white">
								<span className="icon-account_circle awake-input__label__icon awake-link-icon awake-me-10"></span>
								<span className="awake-text-complementary">
									{props.authLabels.email}
								</span>
							</label>
							<input
								name="email"
								className="awake-ht-55 awake-input__input awake-bg-white"
								placeholder={props.authLabels.emailPlaceholder}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.email}></input>
							{formik.touched.email && formik.errors.email ? (
								<div className="invalid-message">{formik.errors.email}</div>
							) : null}
						</div>
					</div>
					<div
						className={
							'col-12 col-lg-6 col-xl-6 ' +
							(window.innerWidth < 1200 ? 'awake-mb-26' : '')
						}>
						<div
							className={`${
								formik.touched.confirmationEmail && formik.errors.confirmationEmail
									? 'invalid'
									: formik.values.confirmationEmail !== ''
									? 'valid'
									: 'awake-input__black'
							} ${
								window.innerWidth > 1200 ? '' : ' awake-ms-auto awake-me-auto'
							} awake-wt-276 awake-input`}>
							<label htmlFor="name" className="awake-input__label awake-bg-white">
								<span className="icon-account_circle awake-input__label__icon awake-link-icon awake-me-10"></span>
								<span className="awake-text-complementary">
									{props.authLabels.confirmEmail}
								</span>
							</label>
							<input
								name="confirmationEmail"
								className="awake-ht-55 awake-input__input awake-bg-white"
								placeholder={props.authLabels.confirmEmailPlaceholder}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.confirmationEmail}></input>
							{formik.touched.confirmationEmail && formik.errors.confirmationEmail ? (
								<div className="invalid-message">
									{formik.errors.confirmationEmail}
								</div>
							) : null}
						</div>
					</div>
				</div>
				<div className={'row ' + (window.innerWidth > 1200 ? 'awake-mt-20' : '')}>
					<div
						className={
							'col-12 col-lg-6 col-xl-6 ' +
							(window.innerWidth < 1200 ? 'awake-mb-26' : '')
						}>
						<div
							className={`${
								formik.touched.name && formik.errors.name
									? 'invalid'
									: formik.values.name !== ''
									? 'valid'
									: 'awake-input__black'
							} ${
								window.innerWidth > 1200
									? 'float-end'
									: ' awake-ms-auto awake-me-auto'
							} awake-wt-276 awake-input`}>
							<label htmlFor="name" className="awake-input__label awake-bg-white">
								<span className="icon-account_circle awake-input__label__icon awake-link-icon awake-me-10"></span>
								<span className="awake-text-complementary">
									{props.authLabels.completeName}
								</span>
							</label>
							<input
								name="name"
								className="awake-ht-55 awake-input__input awake-bg-white"
								placeholder={props.authLabels.completeNamePlaceholder}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.name}></input>
							{formik.touched.name && formik.errors.name ? (
								<div className="invalid-message">{formik.errors.name}</div>
							) : null}
						</div>
					</div>
					<div
						className={
							'col-12 col-lg-6 col-xl-6 ' +
							(window.innerWidth < 1200 ? 'awake-mb-26' : '')
						}>
						<div
							className={`${
								formik.touched.phone && formik.errors.phone
									? 'invalid'
									: formik.values.phone !== ''
									? 'valid'
									: 'awake-input__black'
							} ${
								window.innerWidth > 1200 ? '' : ' awake-ms-auto awake-me-auto'
							} awake-wt-276 awake-input`}>
							<PhoneInput
								country={'co'}
								value={formik.values.phone}
								onChange={(value, country, e, formattedValue) => {
									formik.handleChange(e);
								}}
								onBlur={formik.handleBlur}
								placeholder={props.authLabels.phonePlaceholder}
								inputClass="awake-ht-55 awake-wt-30 awake-input__input awake-bg-white"
								inputProps={{
									name: 'phone',
									require
								}}
							/>
							{formik.touched.phone && formik.errors.phone ? (
								<div className="invalid-message">{formik.errors.phone}</div>
							) : null}
						</div>
					</div>
				</div>
				<div className="row awake-mt-20">
					<div className="col-auto awake-ms-auto awake-me-auto">
						<div>
							<ReCAPTCHA
								id="register-captcha"
								ref={recaptchaRegisterRef}
								sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY!}
								onChange={(value) => {
									formik.values['g-recaptcha-response'] = value ?? '';
									void formik.setFieldTouched('g-recaptcha-response');
								}}
								size="invisible"
							/>
						</div>
					</div>
				</div>
				<div className="row awake-mt-20">
					<div className="col-12 offset-lg-1">
						<Form.Check
							className={`${
								window.innerWidth < 700
									? 'login-component__checkbox awake-d-inline-block'
									: 'login-component__checkbox awake-d-inline-block awake-ms-45'
							}`}
							type={'checkbox'}
							id={'checkbox-accept-terms'}
							checked={acceptTerms}
							onChange={(e) => {
								setAcceptTerms(!acceptTerms);
							}}
						/>
						<span className="awake-heading-5 awake-ms-10">
							{props.authLabels.acceptTermsFirstText}

							<a
								className="awake-heading-5 awake-ms-10 awake-cursor-pointer"
								href={props.authLabels.acceptTermsUrl}
								rel="noreferrer"
								target="_blank">
								{props.authLabels.acceptTerms}
							</a>
						</span>
					</div>
				</div>
				<div className="row">
					<div className="col-12 offset-lg-1">
						<Form.Check
							className={`${
								window.innerWidth < 700
									? 'login-component__checkbox awake-d-inline-block'
									: 'login-component__checkbox awake-d-inline-block awake-ms-45'
							}`}
							type={'checkbox'}
							id={'checkbox-iAmHost'}
							checked={iAmHost}
							onChange={(e) => {
								setIAmHost(!iAmHost);
							}}
						/>
						<label className="awake-heading-5 awake-ms-10">
							{props.authLabels.iAmHots}
						</label>
					</div>
				</div>
				<div className="row awake-mt-10 awake-mb-20">
					<div className="col-12 text-center">
						<button
							id="btn-new-account"
							disabled={
								formik.isSubmitting ||
								!formik.dirty ||
								!formik.isValid ||
								!acceptTerms
							}
							className="awake-secondary-button awake-fw-700 awake-py-15 awake-px-32 awake-mt-10">
							{props.authLabels.nextButtonLabel}
						</button>
					</div>
				</div>
				<div className="row awake-mb-40">
					<div className="col-12 text-center">
						<label
							onClick={() => props.setAuthViewMethod(AuthPageOptionsEnum.LoginView)}
							className="awake-heading-5 awake-fw-700 awake-text-primary awake-d-block awake-cursor-pointer">
							{props.authLabels.start}
						</label>
					</div>
				</div>
			</Form>
		</div>
	);
}

export default RegisterComponent;
