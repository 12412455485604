import { ConfigAPIsEnum } from '@shared/enums/ConfigAPIs.enum';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import type { CategoriesInfoInterface } from '@shared/interfaces/CategoriesInfo.interface';
import type { CurrencyInfoInterface } from '@shared/interfaces/Currency.interface';
import { type LanguageCode } from '@shared/interfaces/DefaultPageConfigs.interface';
import type { DestinationsInfoInterface } from '@shared/interfaces/DestinationsInfo.interface';
import type { LanguagesInfoInterface } from '@shared/interfaces/Languages.interface';
import type { PageConfigurationInterface } from '@shared/interfaces/PageConfiguration.interface';
import { sendRequest } from '@shared/services/request/Request.service';
import type { RequestInterface } from '@shared/services/request/interfaces/Request.interface';

export const getCurrencies: (
    currentPageConfiguration?: PageConfigurationInterface
) => Promise<CurrencyInfoInterface | CatchErrorInterface> = async (
    currentPageConfiguration?: PageConfigurationInterface
): Promise<CurrencyInfoInterface | CatchErrorInterface> => {
        const data: RequestInterface = {
            url: ConfigAPIsEnum.CurrenciesAPIsUrl,
            method: 'GET',
            currentPageConfiguration
        };
        return await sendRequest(data);
    };

export const getLanguages: (
    currentPageConfiguration?: PageConfigurationInterface
) => Promise<LanguagesInfoInterface | CatchErrorInterface> = async (
    currentPageConfiguration?: PageConfigurationInterface
): Promise<LanguagesInfoInterface | CatchErrorInterface> => {
        const data: RequestInterface = {
            url: ConfigAPIsEnum.LanguagesAPIsUrl,
            method: 'GET',
            currentPageConfiguration
        };
        return await sendRequest(data);
    };

export const getCategories: (
    currentPageConfiguration?: PageConfigurationInterface
) => Promise<CategoriesInfoInterface | CatchErrorInterface> = async (
    currentPageConfiguration?: PageConfigurationInterface
): Promise<CategoriesInfoInterface | CatchErrorInterface> => {
        const data: RequestInterface = {
            url: ConfigAPIsEnum.CategoriesAPIUrl,
            method: 'GET',
            currentPageConfiguration
        };
        return await sendRequest(data);
    };

export const getDestinations: (
    currentPageConfiguration?: PageConfigurationInterface
) => Promise<DestinationsInfoInterface | CatchErrorInterface> = async (
    currentPageConfiguration?: PageConfigurationInterface
): Promise<DestinationsInfoInterface | CatchErrorInterface> => {
        const data: RequestInterface = {
            url: ConfigAPIsEnum.DestinationsAPIUrl,
            method: 'GET',
            currentPageConfiguration
        };
        return await sendRequest(data);
    };

export const translatePath = async ({
    path,
    language
}: {
    path: string;
    language: LanguageCode;
}): Promise<string | null> => {
    const { successful, data } = await sendRequest({
        method: 'POST',
        body: {
            path,
            language
        },
        url: ConfigAPIsEnum.PathTranslateAPIUrl
    });

    if (successful) {
        return data?.path ?? null;
    }

    return null;
};
