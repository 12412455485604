import { useResponsive } from '@shared/services/utils/Utils.services';
import { useTranslation } from 'react-i18next';

const FollowUpsProposedComponent = ({
	icon,
	title,
	description,
	img,
	position
}: {
	icon: string;
	title: string;
	description: string;
	img: string;
	position?: 'left' | 'right';
}): JSX.Element => {
	const { isMobile, isDesktop, isTablet } = useResponsive();
	const { t } = useTranslation();

	const titleFollow = t(title);
	const descriptionFollow = t(description);
	return (
		<>
			{!isDesktop && (
				<>
					<div className="d-flex justify-content-center awake-mt-70 awake-mb-40">
						<img src={icon} alt="" />
					</div>

					<h1
						className="text-start awake-heading-2 awake-fw-700"
						dangerouslySetInnerHTML={{ __html: titleFollow }}></h1>
					<label
						className="awake-heading-5"
						dangerouslySetInnerHTML={{ __html: descriptionFollow }}></label>
					<div className="d-flex justify-content-center m-4">
						<img src={img} alt="" className="w-100" />
					</div>
				</>
			)}
			{isDesktop && (
				<div className="row d-flex justify-content-center  m-4">
					{position === 'left' && (
						<>
							<div className="m-4 col-5">
								<img src={icon} alt="" />
								<h1
									className="text-start awake-heading-2 awake-fw-700"
									dangerouslySetInnerHTML={{ __html: titleFollow }}></h1>
								<label
									className="awake-heading-5"
									dangerouslySetInnerHTML={{ __html: descriptionFollow }}></label>
							</div>
							<div className="col-6">
								<div className="d-flex justify-content-center m-4">
									<img className="w-75" src={img} alt="" />
								</div>
							</div>
						</>
					)}

					{position === 'right' && (
						<>
							<div className="col-6">
								<div className="d-flex justify-content-center m-4">
									<img className="w-75" src={img} alt="" />
								</div>
							</div>
							<div className="m-4 col-5">
								<img src={icon} alt="" />
								<h1
									className="text-start awake-heading-2 awake-fw-700"
									dangerouslySetInnerHTML={{ __html: titleFollow }}></h1>
								<label
									className="awake-heading-5"
									dangerouslySetInnerHTML={{ __html: descriptionFollow }}></label>
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
};

export default FollowUpsProposedComponent;