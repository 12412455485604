import * as Yup from 'yup';
import {
    emailFormatValidation,
    passwordFormat
} from '@shared/services/validations/FormValidations.service';
import type { FormValidationsLabelsInterface } from '@shared/interfaces/FormValidationLabels.interface';
import type { AuthLabelsInterface } from '@auth/interfaces/AuthLabels.interface';

export function changeUserPasswordFormValidations(
    authLabels: AuthLabelsInterface,
    FormValidationsLabels: FormValidationsLabelsInterface
): any {
    return Yup.object({
        email: Yup.string()
            .matches(emailFormatValidation.value, FormValidationsLabels.emailFormatValidation)
            .email(FormValidationsLabels.emailFormatValidation)
            .required(FormValidationsLabels.requiredField),
        password: Yup.string()
            .trim()
            .matches(passwordFormat.value, FormValidationsLabels.passwordFormatValidation)
            .min(8, ' 8 ' + FormValidationsLabels.minLength)
            .required(FormValidationsLabels.requiredField),
        confirmationPassword: Yup.string()
            .oneOf(
                [Yup.ref('password')],
                authLabels.passwords + ' ' + FormValidationsLabels.mustMatch
            )
            .required(FormValidationsLabels.requiredField)
    });
}
