import awakeBrand from '@assets/common/awake-brand.png';
import '@header/components/left-menu/components/mobile-menu/MobileMenu.component.scss';
import type { ProfileMobileMenuPropsInterface } from '@header/interfaces/ProfileMobileMenuProps.interface';
import { AppContext } from '@shared/context/App.context';
import { Route } from '@shared/enums/Routes.enum';
import type { MenuItem } from '@shared/interfaces/ProfileMenuLabels.interface';
import { getAuthSession } from '@shared/services/storage/SessionStorage.service';
import { getRoute } from '@shared/services/utils/Utils.services';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

function ProfileMobileMenuComponent(props: ProfileMobileMenuPropsInterface): JSX.Element {
	const { appContextValue } = useContext(AppContext);

	return (
		<React.Fragment>
			{props.toggleProfileMenu && (
				<div className="left-menu-component mobile-menu-component mobile-menu-component__profile vh-100 awake-bg-secondary">
					<div className="row g-0 awake-mt-36">
						<div className="position-absolute">
							<span
								className="icon-close float-end awake-me-40"
								onClick={props.toggleProfileMenuMethod}></span>
						</div>
						<Link
							to={getRoute({ route: Route.Home })}
							className="col-auto awake-ms-auto awake-me-auto d-flex justify-content-center">
							<img className="mobile-menu-component__img" src={awakeBrand}></img>
						</Link>
					</div>
					<div className="awake-pt-22 awake-pb-22 awake-mt-39">
						<div className="row g-0">
							<div className="col-auto awake-ms-auto awake-me-auto">
								<span className="mobile-menu-component__profile__icon icon-account_circle"></span>
							</div>
						</div>
						<div className="row g-0 awake-mt-10">
							<div className="col-auto awake-ms-auto awake-me-auto">
								<h5 className="awake-heading-5 awake-fw-700 awake-text-white awake-mb-0">
									{getAuthSession().username}
								</h5>
							</div>
						</div>
					</div>
					<div className="row g-0 awake-mt-10 awake-mb-70">
						<div className="col-12">
							{props.headerLabels.rightMenu.items.map(
								(item: MenuItem, index: number) => (
									<Link
										to={item.url ? getRoute({ route: item.url }) : ''}
										key={index}
										className="row g-0 mobile-menu-component__item mobile-menu-component__item__single"
										onClick={() =>
											!item.url
												? appContextValue.logout!()
												: props.toggleProfileMenuMethod()
										}>
										<div className="col-auto">
											<span
												className={`${item.icon} mobile-menu-component__item__icon profile d-flex align-items-center`}></span>
										</div>
										<div className="col">
											<h3 className="mobile-menu-component__item__text profile awake-heading-5 awake-fw-700 awake-text-white awake-mb-0 awake-ms-20">
												{item.text}
											</h3>
										</div>
									</Link>
								)
							)}
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
}

export default ProfileMobileMenuComponent;
