import errorImg from '@assets/error-page/404_error.png';
import { Route } from '@shared/enums/Routes.enum';
import { getRoute } from '@shared/services/utils/Utils.services';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function ErrorSectionComponent(): JSX.Element {
    const { t } = useTranslation();

    return (
        <div
            className={`${window.innerWidth < 600 ? '' : ''
                } container-fluid awake-ps-container awake-pe-container`}>
            <div className="row">
                <div className="col-12">
                    <img
                        className={`${window.innerWidth < 600
                                ? 'awake-wt-215 awake-ht-214 awake-mt-40'
                                : 'awake-wt-315 awake-ht-314'
                            }  awake-ms-auto awake-me-auto awake-d-block`}
                        src={errorImg}
                        alt="Img error"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h3 className="awake-heading-3 awake-text-secondary text-center">
                        {t('error.title')}
                    </h3>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h5 className="awake-heading-5 awake-text-secondary text-center awake-mt-15">
                        {t('error.description')}
                    </h5>
                </div>
            </div>
            <div className="row">
                <div className="col-12 d-flex">
                    <Link
                        to={getRoute({ route: Route.Home })}
                        className="awake-button awake-secondary-button awake-fw-700 awake-py-15 awake-px-32 awake-mt-29 awake-mb-50 awake-ms-auto awake-me-auto">
                        {t('error.button')}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ErrorSectionComponent;
