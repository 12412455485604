import type { FilterCardResultPropsInterface } from '@shared/components/filters/components/results-section/interfaces/FilterCardResultProps.interface';
import ExperienceCardComponent from '@shared/components/experience-card/ExperienceCard.component';

function FilterCardResultComponent(props: FilterCardResultPropsInterface): JSX.Element {
	return (
		<div className="col-12 awake-mb-40 ">
			<ExperienceCardComponent
				orientation="horizontal"
				key={props.resultItemExperience.id}
				experience={props.resultItemExperience}></ExperienceCardComponent>
		</div>
	);
}

export default FilterCardResultComponent;
