export enum PaymentGateway {
    PAYPAL = 1,
    PLACE_TO_PAY = 2,
    KUSHKI = 3,
    PAYZEN = 4,
    PUNTOS_COLOMBIA = 5
}

export enum PaymentAPIsEnum {
    CreateLink = ':entity/:id/payment',
    TransactionDetails = ':entity/payment/:transactionId'
}

export enum PaymentEntity {
    RequestBooking = 'request-bookings',
    GiftCard = 'gift-cards',
    JourneyReservation = 'journeys'
}

export enum TransactionState {
    rejected = 'REJECTED',
    pending = 'PENDING',
    approved = 'APPROVED',
    created = 'CREATED',
    expired = 'EXPIRED'
}

export const DefaultPaymentGateway = PaymentGateway.PAYZEN;