import WhatsAppIcon from '@assets/common/whats-app-icon.svg';
import '@shared/components/whats-app-button/WhatsAppButton.component.scss';
import { useAppContext } from '@shared/context/App.context';
import type { WhatsAppProps } from '@shared/interfaces/WhatsAppProps.interface';
import { useEffect, useState } from 'react';
import { WhatsAppVisibilityComponent } from './WhatsAppVisibility.component';

function WhatsAppComponent(props: WhatsAppProps): JSX.Element {
	const { appContextValue, setAppContextMethod } = useAppContext();
	const [isClose, setIsClose] = useState(true);
	const [response, setResponse] = useState(true);

	const handleClick = (): void => {
		sessionStorage.setItem('showWhatsAppMessage', '1');
		setAppContextMethod({
			showWhatsAppMessage: true
		});
		setIsClose(true);
	};

	return (
		<>
			{isClose && appContextValue.showWhatsAppMessage && response ? (
				<>
					<div className="row whats-app-button-component-glob">
						<WhatsAppVisibilityComponent placeholder={props.placeholder} response={response} message={props.message} messageToSend={props.messageToSend} />
					</div>
					<a
						onClick={() => { handleClick() }}
						className="whats-app-button-component-end box-shadow"
						id="whatsapp-sticky-footer">
						<div className="awake-bg-green awake-ht-60 awake-wt-60 awake-br-50 awake-p-12 box-shadow-wt-icon">
							<img src={WhatsAppIcon} alt="WhatsApp Icon" />
						</div>
					</a>
				</>
			) : (
				<a
					onClick={() => { handleClick() }}
					className="whats-app-button-component-end box-shadow"
					id="whatsapp-sticky-footer">
					<div className="awake-bg-green awake-ht-60 awake-wt-60 awake-br-50 awake-p-12 box-shadow-wt-icon">
						<img src={WhatsAppIcon} alt="WhatsApp Icon" />
					</div>
				</a>
			)}
		</>
	);
}

export default WhatsAppComponent;
