import type { BaseScreen } from '@shared/interfaces/Screen.interface';

export function ScreenBase({
  children,
  show = true
}: BaseScreen): JSX.Element {
  return (
    <>
      {show && children}
    </>
  );
}