import { useAppContext } from '@shared/context/App.context';
import { useEffect, useState } from 'react';
import { Seo } from './Seo.component';

export const DefaultSeoData = (): JSX.Element => {
  const appContext = useAppContext();

  const [lang, setLang] = useState<string>(appContext.appContextValue.currentPageConfiguration?.language.code!);

  useEffect(() => {
    if (appContext.appContextValue.currentPageConfiguration?.language.code) {
      setLang(appContext.appContextValue.currentPageConfiguration?.language.code);
    }
  }, [appContext.appContextValue.currentPageConfiguration?.language.code]);

  const data = {
    title: 'Awake Travel | Viajes de naturaleza',
    description: 'Turismo de viajes de naturaleza, aventura y ecoturismo. Viaja con locales y apoya a la conservación de paraísos naturales. ¡Cotiza al instante!',
    url: 'https://awake.travel/',
    image: 'https://awake.travel/favicon.ico'
  }

  return (
    <>
      <Seo
        htmlAttributes={{
          lang
        }}
        data={data}
      />
    </>
  );
};