import ValueProposition from '../home/components/value-proposition/ValueProposition.component';
import { useTranslation } from 'react-i18next';
import WhatsAppComponent from '@shared/components/whats-app-button/WhatsAppButton.component';
import BannerJoinTheGroup from './components/BannerJoinTheGroup.component';
import JoinTheGroup from './JoinTheGroup.component';

const JoinTheGroupPage = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<>
			<div className="awake-bg-page-background">
				<div className="mb-4">
					<BannerJoinTheGroup />
				</div>
				<JoinTheGroup></JoinTheGroup>
				<ValueProposition />
				<WhatsAppComponent
					message={t('whatsapp.joinGroup')}
					messageToSend={t('whatsapp.joinGroup')}
				/>
			</div>
		</>
	);
};

export default JoinTheGroupPage;
