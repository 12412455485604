import type { ProfileLabelsInterface } from '@profile/interface/ProfileLabels.interface';

export const ProfileLabelsES: ProfileLabelsInterface = {
	editProfileSectionLabels: {
		formEditViewLabels: {
			city: 'Ciudad',
			cityPlaceholder: 'Bogotá',
			birthdate: 'Fecha de nacimiento',
			birthdatePlaceholder: 'DD/MM/AAAA',
			name: '*Nombre y apellido',
			namePlaceholder: 'Maria Camila Rodriguez Gomez',
			email: '*Correo electrónico',
			emailPlaceholder: 'ejemplo@gmail.com',
			phone: '*Número de celular',
			phonePlaceholder: '310 555 5555',
			address: 'Dirección',
			addressPlaceholder: 'Cll 1 # 23 - 45',
			title: 'Editar perfil',
			editProfileSuccess: 'Perfil actualizado correctamente'
		},
		changePasswordViewLabels: {
			title: 'Cambia tu contraseña',
			changePassConfirmation: '*Confirmar contraseña nueva',
			changePassNew: '*Contraseña nueva',
			changePass: '*Contraseña actual',
			passwords: 'Las contraseñas',
			changePasswordSuccess: 'Contraseña actualizada correctamente'
		},
		deleteAccountViewLabels: {
			title: 'Eliminar cuenta',
			areYouSure: '¿Estás seguro de eliminar tu cuenta?',
			confirmDelete: 'Sí, eliminar',
			whyYouWantToLeave: '¿Cuentanos por que te quieres ir de Awake?',
			textAreaPlaceholder: 'Escribe aqui',
			accountDeleted: 'Tu cuenta ha sido eliminada',
			returnToHomeButtonLabel: 'Volver al home'
		},
		updateButtonLabel: 'Actualizar'
	},
	bookingsSectionLabels: {
		title: 'Mis reservas',
		experience: 'Experiencia',
		journey: 'Travesía',
		viewMoreButtonLabel: 'Ver más',
		viewSummary: 'Ver resumen de compra',
		state: 'Estado',
		pendingHostApprobation: 'Pendiente confirmación anfitrión',
		confirmedByHost: 'Confirmada por anfitrion',
		canceled: 'Cancelada',
		paid: 'Pagado',
		payLabel: 'Pagar '
	},
	notBookingsSectionLabels: {
		viewMoreButtonLabel: 'Descrubir destinos',
		content:
			'No tienes experiencias reservadas, te invitamos a dar en el siguiente botón y descubrir los mejores destinos de naturaleza.'
	},
	notificationsSectionLabels: {
		sms: 'Mensaje',
		read: 'Leída',
		markAsRead: 'Marcar como leida',
		date: 'Fecha',
		title: 'Notificaciones',
		titleNotNotification: 'No tienes ninguna notificación ',
		viewMoreButtonLabel: 'Ver más'
	},
	savedExperiencesSectionLabels: {
		title: 'Experiencias guardadas',
		viewMoreButtonLabel: 'Ver más'
	},
	referralsSectionLabels: {
		title: 'Referidos',
		availableCredit: 'Crédito disponible: ',
		refersTitlePart1: 'Refiere y gana ',
		refersTitlePart2: 'de credito para futuros viajes',
		contentPart1:
			'Invita a tus amigos que aún no estén registrados en Awake. Recibirán hasta COP $ ',
		contentPart2: 'de descuento en su primera reserva (y tú obtendrás hasta COP $ ',
		contentPart3: 'de crédito)',
		emailInputPlaceholder: '*Correo electronico',
		linkInputPlaceholder: ' https://awake.travel/es/refer/LDEANTONIOL',
		sendButtonLabel: 'Enviar invitación',
		sendButtonLabels: 'Enviar invitaciones',
		copyLinkButtonLabel: 'Copiar link',
		titleReceived: 'Recibido',
		titlePending: 'Pendiente',
		titleSuccessful: 'Exitoso'
	},
	ReferralsShared: {
		joinUsAt: '¡Únete a Awake y recibe $ ',
		joinUsAtTwo: 'de descuento en tu primer viaje!',
		invitesYou: ' te invita a esta gran familia, crea una cuenta y redime tu credito',
		thisOffer:
			'Esta oferta esta disponible solo para nuevos usuarios. Sujeto a términos y condiciones',
		easyStartTitle: 'Inicia fácil, seguro y rápido con:',
		goggleButton: 'Inicia con Google',
		faceboockButton: 'Inicia con Facebook',
		complete: 'Para completar el registro necesitamos los siguientes datos:',
		inputEmail: '*Correo electrónico',
		inputMailBevestigen: '*Confirmar correo electrónico ',
		inputName: '*Nombre y apellido',
		inputPhone: '*Número de celular',
		checkAccept: 'Acepto los términos y condiciones',
		createAccountButton: 'Crear cuenta',
		userDoesNotExist: 'El usuario que envia la invitacion no existe por favor validar'
	},
	handleSubmitErrorMessage: 'Por favor, introduce correos electrónicos válidos.',
	handleSubmitErrorMessageFalse: 'Ocurrió un error al enviar el correo electrónico:',
	smsErrorsEmails: 'Correos maximos por envio 5',
	shared: 'Link copiado',
	sendEmail: 'Email enviado con exito',
	whatsAppMessage: `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_AWAKE_MAIN_PHONE_NUMBER}&text=¡Hola!+%c2%bfMe+puedes+ayudar+con+más+información+sobre+las+experiencias+de+la+página+web%3f`,
	NoticeForRegistrationForm: {
		sms: 'Para continuar con tu reserva, es necesario que diligencies el formulario de registro,',
		clickHere: 'pulsa aquí',
		start: 'para iniciar.',
		Important: 'Importante'
	}
};

export const ProfileLabelsEN: ProfileLabelsInterface = {
	editProfileSectionLabels: {
		formEditViewLabels: {
			city: 'City',
			cityPlaceholder: 'Bogotá',
			birthdate: 'Date of birth',
			birthdatePlaceholder: 'DD/MM/YYYY',
			name: '*First and last name',
			namePlaceholder: 'Matthew Murphy',
			email: '*E-mail',
			emailPlaceholder: 'example@gmail.com',
			phone: '*Phone Number',
			phonePlaceholder: '310 555 5555',
			address: 'Address',
			addressPlaceholder: '170 oak ave',
			title: 'Edit profile',
			editProfileSuccess: 'Proifle updated successfully'
		},
		changePasswordViewLabels: {
			title: 'Change your password',
			changePassConfirmation: '*Confirm new password',
			changePassNew: '*New password',
			changePass: '*Current password',
			passwords: 'Passwords',
			changePasswordSuccess: 'Password updated successfully'
		},
		deleteAccountViewLabels: {
			title: 'Delete account',
			areYouSure: 'Are you sure to delete your account?',
			confirmDelete: 'Yes, delete',
			whyYouWantToLeave: '¿Tell us why you want to leave Awake?',
			textAreaPlaceholder: 'Type here',
			accountDeleted: 'Your account has been deleted',
			returnToHomeButtonLabel: 'Return to home'
		},
		updateButtonLabel: 'Update'
	},
	bookingsSectionLabels: {
		title: 'Bookings',
		experience: 'Experience',
		journey: 'Journey',
		viewMoreButtonLabel: 'See more',
		viewSummary: 'View purchase summary',
		state: 'State',
		pendingHostApprobation: 'Pending host confirmation',
		confirmedByHost: 'Confirmed by host',
		canceled: 'Canceled',
		paid: 'Paid',
		payLabel: 'Pay '
	},
	notBookingsSectionLabels: {
		viewMoreButtonLabel: 'Discover destinations',
		content:
			'If you have no experiences booked, we invite you to click on the following button and discover the best nature destinations.'
	},
	notificationsSectionLabels: {
		sms: 'Message',
		read: 'Read',
		markAsRead: 'Mark as read',
		date: 'Date',
		title: 'Notifications',
		titleNotNotification: 'You have no notification',
		viewMoreButtonLabel: 'See more'
	},
	savedExperiencesSectionLabels: {
		title: 'Saved',
		viewMoreButtonLabel: 'See more'
	},
	referralsSectionLabels: {
		title: 'Referrals',
		availableCredit: 'Available credit: ',
		refersTitlePart1: 'Refer and win ',
		refersTitlePart2: ' credit for future travel',
		contentPart1:
			'Invita a tus amigos que aún no estén registrados en Awake. Recibirán hasta COP $ 70,000  50,000 de crédito)',
		contentPart2: 'de descuento en su primera reserva (y tú obtendrás hasta COP $ ',
		contentPart3: 'de crédito)',
		emailInputPlaceholder: '*E-mail',
		linkInputPlaceholder: ' https://awake.travel/es/refer/LDEANTONIOL',
		sendButtonLabel: 'Send invitation',
		sendButtonLabels: 'Send invitations',
		copyLinkButtonLabel: 'Copy link',
		titleReceived: 'Received',
		titlePending: 'Pendiente',
		titleSuccessful: 'Exitoso'
	},
	ReferralsShared: {
		joinUsAt: 'Join Awake and get $ ',
		joinUsAtTwo: 'off your first trip!',
		invitesYou:
			'Laura De Antonio invites you to join this great family, create an account and redeem your credit.',
		thisOffer: 'This offer is available for new users only. Subject to terms and conditions',
		easyStartTitle: 'Start easy, safe and fast with:',
		goggleButton: 'Start with Google',
		faceboockButton: 'Start with Facebook',
		complete: 'To complete the registration we need the following data:',
		inputEmail: '*📨E-mail',
		inputMailBevestigen: '*Confirm e-mail address',
		inputName: '*Name and surname',
		inputPhone: '*Cell phone number',
		checkAccept: 'I accept the terms and conditions',
		createAccountButton: 'Create an account',
		userDoesNotExist: 'El usuario que envia la invitacion no existe por favor validar'
	},
	handleSubmitErrorMessage: 'Please enter valid e-mail addresses.',
	handleSubmitErrorMessageFalse: 'An error occurred while sending the email: ',
	smsErrorsEmails: 'Maximum postage per shipment 5',
	shared: 'Link copied',
	sendEmail: 'Email sent successfully',
	whatsAppMessage: `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_AWAKE_MAIN_PHONE_NUMBER}&text=Hello!+Can+you+help+me+with+more+information+about+the+experiences+on+the+website%3f`,
	NoticeForRegistrationForm: {
		sms: 'To continue with your booking, you need to fill in the registration form,',
		clickHere: 'click here',
		start: 'to start.',
		Important: 'Important'
	}
};
