import * as Yup from 'yup';
import {
    emailFormatValidation,
    onlyLetter
} from '@shared/services/validations/FormValidations.service';
import type { FormValidationsLabelsInterface } from '@shared/interfaces/FormValidationLabels.interface';
import type { AuthLabelsInterface } from '@auth/interfaces/AuthLabels.interface';
import { phoneValidation } from '@shared/validations/UserPhoneForm.validations';

export function RegisterFormValidations(
    authLabels: AuthLabelsInterface,
    FormValidationsLabels: FormValidationsLabelsInterface,
    referralCode?: boolean
): any {
    const validations: any = Yup.object({
        email: Yup.string()
            .matches(emailFormatValidation.value, FormValidationsLabels.emailFormatValidation)
            .email(FormValidationsLabels.emailFormatValidation)
            .required(FormValidationsLabels.requiredField),
        confirmationEmail: Yup.string()
            .oneOf([Yup.ref('email')], authLabels.emails + ' ' + FormValidationsLabels.mustMatch)
            .required(FormValidationsLabels.requiredField),
        name: Yup.string()
            .min(5, '5 ' + FormValidationsLabels.minLength)
            .matches(onlyLetter.value, FormValidationsLabels.onlyLetter)
            .required(FormValidationsLabels.requiredField),
        phone: phoneValidation(FormValidationsLabels.invalid).required()
    });

    if (referralCode) {
        validations.referralCode = Yup.string().required(FormValidationsLabels.requiredField)
    }

    return validations;
}
