import '@auth/Auth.page.scss';
import type { AuthPropsInterface } from '@auth/interfaces/AuthProps.interface';
import { AuthPageOptionsEnum } from '@auth/enums/AuthPageOptions.enum';
import LoginComponent from '@auth/components/login/Login.component';
import RecoverComponent from '@auth/components/recover/Recover.component';
import RegisterComponent from '@auth/components/register/Register.component';
import { useContext, useEffect, useState } from 'react';
import { AuthLabelsEN, AuthLabelsES } from '@auth/labels/Auth.labels';
import { AppContext } from '@shared/context/App.context';
import {
    FormValidationsLabelsEN,
    FormValidationsLabelsES
} from '@shared/labels/FormValidations.labels';
import { defaultPageConfigs } from '@shared/services/set-configs/defaultPageConfigs';

function AuthPage(props: AuthPropsInterface): JSX.Element {
    const [authLabels, setAuthLabels] = useState(AuthLabelsES);
    const [formValidationsLabels, setFormValidationsLabels] = useState(FormValidationsLabelsEN);
    const { appContextValue } = useContext(AppContext);

    const setAuthViewMethod = (view: AuthPageOptionsEnum): void => {
        props.setAuthView(view);
    };

    useEffect(() => {
        if (
            appContextValue.currentPageConfiguration?.language.code ===
            defaultPageConfigs.language.code
        ) {
            setAuthLabels(AuthLabelsES);
            setFormValidationsLabels(FormValidationsLabelsES);
        } else {
            setAuthLabels(AuthLabelsEN);
            setFormValidationsLabels(FormValidationsLabelsEN);
        }
    }, [
        appContextValue.currentPageConfiguration
    ]);

    return (
        <div className="auth-page">
            {props.authView === AuthPageOptionsEnum.LoginView && (
                <LoginComponent
                    authLabels={authLabels}
                    formValidationsLabels={formValidationsLabels}
                    setAuthViewMethod={setAuthViewMethod}
                    toggleAuthPageMethod={props.toggleAuthPageMethod}></LoginComponent>
            )}
            {props.authView === AuthPageOptionsEnum.RecoverView && (
                <RecoverComponent
                    authLabels={authLabels}
                    formValidationsLabels={formValidationsLabels}
                    setAuthViewMethod={setAuthViewMethod}
                    toggleAuthPageMethod={props.toggleAuthPageMethod}></RecoverComponent>
            )}
            {props.authView === AuthPageOptionsEnum.RegisterView && (
                <RegisterComponent
                    authLabels={authLabels}
                    formValidationsLabels={formValidationsLabels}
                    setAuthViewMethod={setAuthViewMethod}
                    toggleAuthPageMethod={props.toggleAuthPageMethod}></RegisterComponent>
            )}
        </div>
    );
}

export default AuthPage;
