import type { DropdownMenuPropsInterface } from '@header/interfaces/DropdownMenuPropsInterface';
import type { CurrencyInfoDataInterface } from '@shared/interfaces/Currency.interface';
import type { LanguagesInfoDataInterface } from '@shared/interfaces/Languages.interface';
import flag from '@assets/home/impact/flag-col.png';
import flagEn from '@assets/home/impact/flag-en.png';

function DropdownMenuComponent(props: DropdownMenuPropsInterface): JSX.Element {
    return (
        <>
            <ul className="dropdown-menu dropdown-menu-lg-end dropdown-menu-md-end awake-br-0 awake-mt-12 awake-pb-0 awake-pt-0 awake-mr-n-70">
                {props.items.map(
                    (
                        item: LanguagesInfoDataInterface | CurrencyInfoDataInterface,
                        index: number
                    ) => (
                        <li key={index} onClick={() => props.setConfigData(item)}>
                            <a className="dropdown-item dropdown-menu__item awake-pt-13 awake-pb-13 awake-px-32 d-inline-flex align-items-center">
                                {item.code === 'es' && (
                                    <img className="awake-ht-15 awake-mx-10" src={flag} alt="" />
                                )}
                                {item.code === 'en' && (
                                    <img className="awake-ht-15 awake-mx-10" src={flagEn} alt="" />
                                )}
                                <label className="awake-heading-6 awake-fw-700 awake-text-secondary awake-mb-0 awake-ms-20">
                                    {item.code} - {item.name}
                                </label>
                            </a>
                        </li>
                    )
                )}
            </ul>
        </>
    );
}

export default DropdownMenuComponent;
