import '@footer/Footer.component.scss';
import FooterLeftComponent from '@footer/components/footer-left/FooterLeft.component';
import FooterRightComponent from '@footer/components/footer-right/FooterRight.component';

function FooterComponent(): JSX.Element {

    return (
        <div className="container-fluid awake-bg-secondary footer-component">
            <div className="container py-5">
                <div className="row d-flex">
                    <div className="col-12 col-sm-10 col-xl-7 offset-sm-1 offset-xl-0">
                        <FooterLeftComponent></FooterLeftComponent>
                    </div>
                    <div className="col-12 col-sm-10 col-xl-4 offset-sm-1 mt-4 mt-lg-0">
                        <FooterRightComponent></FooterRightComponent>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterComponent;
