export type RouteKey = keyof typeof Route;

export enum Route {
	Home = '/:lang?',
	Destination = '/:lang?/{destination}/:slug',
	TravelGuide = '/:lang?/{travelGuide}/:slug',
	Journey = '/:lang?/{journey}/:slug',
	Filters = '/:lang?/{filters}',
	Search = '/:lang?/{filters}/s/:searchValue',
	Experience = '/:lang?/{experience}/:slug',
	AddOns = '/:lang?/{experience}/:slug/:experienceId/{addOns}',
	AddOn = '/:lang?/{experience}/:slug/:experienceId/{addOns}/:addOnId',
	Summary = '/:lang?/{experience}/:slug/{summary}/:experienceId',
	Booking = '/:lang?/{experience}/:slug/{booking}/:experienceId',
	BookingUpdate = '/:lang?/{experience}/:slug/{update}',
	Profile = '/:lang?/{profile}',
	ProfileOption = '/:lang?/{profile}/:optionMenu',
	ProfileUpdate = '/:lang?/{profile}/{update}',
	ProfileBookings = '/:lang?/{profile}/{bookings}',
	ProfileBookingDetail = '/:lang?/{profile}/{bookings}/:bookingId',
	ProfileBookingUpdate = '/:lang?/{profile}/{bookings}/:bookingId/{update}',
	ProfileNotifications = '/:lang?/{profile}/{notifications}',
	ProfileWishList = '/:lang?/{profile}/{wishList}',
	ProfileReferrals = '/:lang?/{profile}/{referrals}',
	ThankYouBooking = '/:lang?/{thankYou}/:bookingId/{payment}/:transactionId/{detail}/{booking}',
	RecoverAccount = '/:lang?/{recoverAccount}/:token',
	ReferralCode = '/:lang?/{referrals}/:user',
	DealForm = '/:lang?/{form}/:uuid',
	ThankYouGiftCard = '/:lang?/{thankYou}/:giftCardId/{payment}/:transactionId/{detail}/{giftCard}',
	GiftCard = '/:lang?/{giftCard}',
	DataProcessing = '/:lang?/{dataProcessing}',
	CookiesPolicy = '/:lang?/{cookiesPolicy}',
	JoinTheGroup = '/:lang?/{joinTheGroup}',
	CookiesLinktreatmentPolicies = 'https://awake.travel/autorizacion-tratamiento-datos',
	AbtestQuoter = 'experiencia/conoce-la-laguna-de-guarinocito-cascada-la-clara-mirador-embalse-amani-rio-manso-y-rio-la-miel-desde-la-dorada/?variantId=',
	FlashStickyUrl = '/destino/flash-sales?utm_campaign=flash-sales&utm_source=banner_sticky_home&utm_medium=owned&utm_content=promo',
	ClicUrl = 'https://clicair.co/?utm_campaign=alianza-awake&utm_source=awake-mkp&utm_medium=referral&utm_content=alianza-awake',
	WeLanding='/:lang?/{weLanding}'
}

export const RouteKeys: RouteKey[] = Object.keys(Route) as RouteKey[];
