import AuthPage from '@root/modules/auth/Auth.page';
import ChangePasswordPage from '@root/modules/auth/ChangePassword.page';
import { AuthPageOptionsEnum } from '@root/modules/auth/enums/AuthPageOptions.enum';
import JoinTheGroupPage from '@root/modules/join-the-group/JoinTheGroupPage.component';
import JourneyPage from '@root/modules/journey/Journey.page';
import LandingProposedComponent from '@root/modules/landing-proposed-value/LandingProposed.component';
import ProfileReferralsSharedComponent from '@root/modules/profile/components/referrals-profile/ProfileReferralsShared.component';
import { CookiesPolicyComponent } from '@shared/components/cookies/components/CookiesPolicy.component';
import DataProcessingComponent from '@shared/components/data-processing/DataProcessing.component';
import FooterComponent from '@shared/components/footer/Footer.component';
import HeaderComponent from '@shared/components/header/Header.component';
import LoadLoadingComponent from '@shared/components/loading/LoadLoading.component';
import ModalViewComponent from '@shared/components/modal-view/ModalView.component';
import { DefaultSeoData } from '@shared/components/seo/DefaultData.component';
import { JourneyPageSkeleton } from '@shared/components/skeletons/JourneyPage.skeleton';
import { TravelGuidePageSkeleton } from '@shared/components/skeletons/TravelGuidePage.skeleton';
import { AppContextProvider } from '@shared/context/App.context';
import { Route as RouteEnum, RouteKeys, type RouteKey } from '@shared/enums/Routes.enum';
import { SessionStorageKeys } from '@shared/enums/SessionStorageKeys.enum';
import { LangGuard } from '@shared/guards/LangGuard';
import { ProtectedRoute, UnauthenticatedRoute } from '@shared/guards/ProtectedRoute';
import type { AppContextInterfaceValue } from '@shared/interfaces/AppContext.interface';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import { type CurrencyInfoDataInterface } from '@shared/interfaces/Currency.interface';
import {
	type CurrencyCode,
	type Language,
	type LanguageCode,
	type Prefix
} from '@shared/interfaces/DefaultPageConfigs.interface';
import { type LanguagesInfoDataInterface } from '@shared/interfaces/Languages.interface';
import { authPageWithBack } from '@shared/services/auth-service/Auth.service';
import type { AuthPageResponseInterface } from '@shared/services/auth-service/interfaces/AuthPageResult.interface';
import {
	getCategories,
	getCurrencies,
	getDestinations,
	getLanguages,
	translatePath
} from '@shared/services/get-configs/getConfigs.service';
import { getAccessToken } from '@shared/services/request/Request.service';
import {
	CurrencyNames,
	LangCurrency,
	LanguageNames,
	defaultPageConfigs
} from '@shared/services/set-configs/defaultPageConfigs';
import {
	clearUserAuth,
	getAuthSession,
	getSessionStorageClientItem,
	setClientAuth
} from '@shared/services/storage/SessionStorage.service';
import {
	getRoute,
	isACatchError,
	replacePrefixes,
	setDestinationsSearch,
	setUtmData
} from '@shared/services/utils/Utils.services';
import moment from 'moment';
import 'moment/locale/es.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Route,
	Routes,
	matchRoutes,
	useLocation,
	useNavigate,
	useSearchParams
} from 'react-router-dom';

const HomePage = React.lazy(async () => await import('@home/Home.page'));
const ReserveSummaryPage = React.lazy(
	async () => await import('@root/modules/reserve-summary/ReserveSummary.page')
);
const FiltersPage = React.lazy(async () => await import('@filters-page/FilterPage.page'));
const TravelGuidePage = React.lazy(async () => await import('@travel-guide/TravelGuide.page'));
const ExperiencePage = React.lazy(async () => await import('@experience/Experience.page'));
const ProfilePage = React.lazy(async () => await import('@profile/Profile.page'));
const ErrorPage = React.lazy(async () => await import('@error-page/Error.page'));
const AddOnsPage = React.lazy(async () => await import('@add-ons/AddOns.page'));
const BookingPage = React.lazy(async () => await import('@booking/Booking.page'));
const UpdateBookingPage = React.lazy(async () => await import('@booking/UpdateBooking.page'));

const BookingThankYouPage = React.lazy(
	async () => await import('@root/modules/thank-you/BookingThankYou.page')
);
const GiftCardThankYouPage = React.lazy(
	async () => await import('@root/modules/thank-you/GiftCardThankYou.page')
);
const JourneyReservationThankYouPage = React.lazy(
	async () => await import('@root/modules/thank-you/JourneyReservationThankYou.page')
);
const FormPage = React.lazy(async () => await import('@form/Form.page'));
const GiftCardPage = React.lazy(async () => await import('@gift-card/GiftCard.page'));

function RoutingComponent(): JSX.Element {
	const { i18n } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const [currentLang, setCurrentLang] = useState<LanguageCode>();
	const [currentCurrency, setCurrentCurrency] = useState<CurrencyCode>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { pathname } = location;
	const [lang] = pathname.split('/').filter((param) => param !== '');

	const isSpecialRoute = pathname.startsWith('/referidos');
	const currencyCode =
		sessionStorage.getItem('currency') !== 'null'
			? sessionStorage.getItem('currency')
			: defaultPageConfigs.currency.code;
	let language = defaultPageConfigs.language;
	let currency = defaultPageConfigs.currency;

	const storeLanguage = (lang: LanguageCode): void => {
		sessionStorage.setItem('lang', lang);
		setCurrentLang(lang);
	};

	const storeCurrency = (currency: CurrencyCode): void => {
		sessionStorage.setItem('currency', currency);
		setCurrentCurrency(currency);
	};

	if (defaultPageConfigs.allowedLanguages.includes(lang as LanguageCode)) {
		language = {
			code: lang as LanguageCode,
			name: LanguageNames[lang as LanguageCode]
		};
	}

	if (defaultPageConfigs.allowedCurrencies.includes(currencyCode as CurrencyCode)) {
		currency = {
			code: currencyCode as CurrencyCode,
			name: CurrencyNames[currencyCode as CurrencyCode]
		};
	}

	const [appContext, setAppContextValue] = useState<Partial<AppContextInterfaceValue>>({
		categoriesInfo: [],
		currenciesInfo: [],
		currentPageConfiguration: {
			language,
			currency
		},
		destinationsInfo: [],
		languagesInfo: [],
		logout: () => {
			setAppContextMethod({
				hasLogin: false
			});
			clearUserAuth();
			navigate(getRoute({ route: RouteEnum.Home }));
		},
		currentFilters: {},
		showLogin: false,
		showSearch: false,
		showLoginGoogleOneTap: true,
		updatingBooking: false,
		hasLogin: Boolean(getAuthSession()),
		showWhatsAppMessage: false
	});

	const setLanguage = async ({ code: lang }: LanguagesInfoDataInterface): Promise<void> => {
		if (lang !== currentLang) {
			setIsLoading(true);

			const [path] = await Promise.all([
				getNewPath(currentLang!, lang as LanguageCode),
				i18n.changeLanguage(lang)
			]);

			storeLanguage(lang as LanguageCode);
			storeCurrency(LangCurrency[lang as Language] as CurrencyCode);

			setAppContextMethod({
				currentPageConfiguration: {
					...appContext.currentPageConfiguration!,
					language: {
						code: lang,
						name: LanguageNames[lang as LanguageCode]
					},
					currency: {
						code: LangCurrency[lang as Language] as CurrencyCode,
						name: CurrencyNames[LangCurrency[lang as Language] as CurrencyCode]
					}
				}
			});

			navigate(path);

			setIsLoading(false);
		}
	};

	const setCurrency = (currency: CurrencyInfoDataInterface): void => {
		if (currency.code !== currentCurrency) {
			storeCurrency(currency.code as CurrencyCode);

			setAppContextMethod({
				currentPageConfiguration: {
					...appContext.currentPageConfiguration!,
					currency
				}
			});
		}
	};

	const getNewPath = async (
		currentLang: LanguageCode,
		newLang: LanguageCode
	): Promise<string> => {
		const routes: Array<{ path: string; name: RouteKey }> = [];

		RouteKeys.forEach((routeKey) => {
			routes.push({
				path: replacePrefixes(RouteEnum[routeKey], currentLang),
				name: routeKey
			});
		});

		const matchedRoutes = matchRoutes(routes, location);

		if (!matchedRoutes) {
			return getRoute({ route: RouteEnum.Home, toLanguage: newLang });
		}

		const [route] = matchedRoutes;
		const { lang: langParam, ...routeParams } = route.params;

		if (!routeParams.slug) {
			return getRoute({ route: RouteEnum[route.route.name], params: routeParams as any, toLanguage: newLang });
		}

		const pathData = getTranslatePathData(route.route.path, route.pathname);

		const path = await translatePath({
			path: pathData.path,
			language: newLang
		});

		if (!path) {
			return getRoute({ route: RouteEnum.Home, toLanguage: newLang });
		}

		if (!pathData.end) {
			return path;
		}

		return `${path}/${getPathSliced(getRoute({ route: RouteEnum[route.route.name], params: routeParams as any, searchParams: '', toLanguage: newLang }), -pathData.end)}`;
	}

	const getTranslatePathData = (routePath: string, fullPath: string): { path: string; end: number; } => {
		const parts = routePath.split(':slug').filter(char => char.length);

		if (parts.length === 1) {
			return {
				path: fullPath,
				end: 0
			};
		}

		const end = parts.at(1)?.split('/').filter(char => char.length).length!;

		return {
			path: getPathSliced(fullPath, 0, -end),
			end
		}
	}

	const getPathSliced = (path: string, start?: number, end?: number): string => {
		return path.split('/').filter(char => char.length).slice(start, end).join('/');
	}

	const authPage = (): void => {
		authPageWithBack().subscribe({
			next: async (result: CatchErrorInterface | AuthPageResponseInterface) => {
				if (!isACatchError(result)) {
					setClientAuth(result.data);
				} else {
					console.error(result.error);
				}
			}
		});
	};

	useEffect(() => {
		if (getAuthSession() && getAuthSession().expiresIn < Date.now()) {
			clearUserAuth();
			authPage();
		} else {
			if (
				!getSessionStorageClientItem(SessionStorageKeys.accessToken) ||
				getSessionStorageClientItem(SessionStorageKeys.expiresIn) < Date.now()
			) {
				authPage();
			}
		}
		setUtmData(searchParams);
	}, [location]);

	useEffect(() => {
		appContext.currentPageConfiguration?.currency.code === defaultPageConfigs.currency.code
			? moment.locale('es-ES')
			: moment.locale('en-US');

		setCurrentLang(appContext.currentPageConfiguration!.language.code as LanguageCode);

		const onHasToken = (): any =>
			new Promise<void>((resolve) => {
				const interval = setInterval(() => {
					const hasToken: boolean = Boolean(getAccessToken());

					if (hasToken) {
						clearInterval(interval);
						resolve();
					}
				}, 1);
			});

		const loadPageInfo = async (): Promise<void> => {
			await onHasToken().then(async () => {
				const [_currencies, _destinations, _languages, _categories] = await Promise.all([
					getCurrencies(appContext.currentPageConfiguration),
					getDestinations(appContext.currentPageConfiguration),
					getLanguages(appContext.currentPageConfiguration),
					getCategories(appContext.currentPageConfiguration)
				]);

				setAppContextMethod({
					categoriesInfo: (_categories as any).data,
					destinationsInfo: (_destinations as any).data,
					currenciesInfo: (_currencies as any).data,
					languagesInfo: (_languages as any).data
				});

				setDestinationsSearch((_destinations as any).data);
			});
		};

		loadPageInfo()
			.then(() => {
				console.log('Page started with successful');
			})
			.catch((exception) => {
				console.error(exception);
			});
	}, [appContext.currentPageConfiguration]);

	useEffect(() => {
		storeLanguage(language.code);
		storeCurrency(currency.code);

		const changeI18nLanguage = async (lang: string): Promise<void> => {
			await i18n.changeLanguage(lang);
		};

		if (defaultPageConfigs.allowedLanguages.includes(lang as LanguageCode)) {
			if (i18n.language !== lang) {
				void changeI18nLanguage(lang);
			}
		}
	}, []);

	const setAppContextMethod: (newAppContextValue: Partial<AppContextInterfaceValue>) => void = (
		newAppContextValue: Partial<AppContextInterfaceValue>
	): void => {
		setAppContextValue({
			...appContext,
			...newAppContextValue
		});
	};

	const getPathByPrefix = (prefix: keyof Prefix, path: string): string => {
		const langPrefix = defaultPageConfigs.prefix[prefix][currentLang ?? language.code];

		return path.replace('{prefix}', langPrefix);
	};

	const getPathByPrefixes = (prefixes: Array<keyof Prefix>, path: string): string => {
		prefixes.forEach((prefix) => {
			path = getPathByPrefix(prefix, path.replace(`{${prefix}}`, '{prefix}'));
		});

		return path;
	};
	const [toggleAuthPage, setToggleAuthPage] = useState(true);
	const [authView, setAuthView] = useState(AuthPageOptionsEnum.LoginView);

	const toggleAuthPageMethod = (): void => {
		setToggleAuthPage(!toggleAuthPage);
		if (!toggleAuthPage) {
			setAuthView(AuthPageOptionsEnum.LoginView);
		}
	};

	return (
		<React.Suspense fallback={<LoadLoadingComponent />}>
			<AppContextProvider
				value={{
					appContextValue: appContext!,
					setAppContextMethod,
					setLanguage,
					setCurrency
				}}>
				<DefaultSeoData />
				<HeaderComponent />

				<div className="router-outlet">
					<Routes>
						<Route path=":lang?" element={<LangGuard />}>
							<Route 
								path={getPathByPrefix('weLanding', '{prefix}')} element={
									<React.Suspense fallback={<LoadLoadingComponent />}>
										<LandingProposedComponent />
									</React.Suspense>
								} />
							<Route
								path=""
								element={
									<React.Suspense fallback={<LoadLoadingComponent />}>
										<HomePage />
									</React.Suspense>
								}>
								<Route
									path="login"
									element={
										<ModalViewComponent
											modalClassName="awake-modal__background"
											modalState={toggleAuthPage}
											setModalState={toggleAuthPageMethod}>
											<AuthPage
												authView={authView}
												setAuthView={setAuthView}
												toggleAuthPageMethod={toggleAuthPageMethod}
											/>
										</ModalViewComponent>
									}
								/>
							</Route>
							<Route
								path={getPathByPrefix('filters', '{prefix}/s?/:searchValue?')}
								element={
									<React.Suspense fallback={<LoadLoadingComponent />}>
										<FiltersPage />
									</React.Suspense>
								}
							/>
							<Route
								path={getPathByPrefix('destination', '{prefix}/:slug')}
								element={
									<React.Suspense fallback={<LoadLoadingComponent />}>
										<FiltersPage />
									</React.Suspense>
								}
							/>
							<Route
								path={getPathByPrefix('travelGuide', '{prefix}/:slug')}
								element={
									<React.Suspense fallback={<TravelGuidePageSkeleton />}>
										{isLoading && <TravelGuidePageSkeleton />}
										{!isLoading && <TravelGuidePage />}
									</React.Suspense>
								}
							/>
							<Route
								path={getPathByPrefix('journey', '{prefix}/:slug')}
								element={
									<React.Suspense fallback={<JourneyPageSkeleton />}>
										<JourneyPage isLoading={isLoading} />
									</React.Suspense>
								}
							/>
							<Route
								path={getPathByPrefix(
									'experience',
									'{prefix}/:slug/*'
								)}>
								<Route
									path=""
									element={
										<React.Suspense fallback={<LoadLoadingComponent />}>
											<ExperiencePage isLoading={isLoading} />
										</React.Suspense>
									}
								/>
								<Route
									path={getPathByPrefix('summary', '{prefix}/:experienceId')}
									element={
										<React.Suspense fallback={<LoadLoadingComponent />}>
											<ReserveSummaryPage />
										</React.Suspense>
									}
								/>
								<Route
									path={getPathByPrefix('booking', '{prefix}/:experienceId')}
									element={
										<React.Suspense fallback={<LoadLoadingComponent />}>
											<ReserveSummaryPage />
										</React.Suspense>
									}
								/>
								<Route
									path={getPathByPrefix('update', '{prefix}')}
									element={
										<React.Suspense fallback={<LoadLoadingComponent />}>
											<ExperiencePage updating={true} />
										</React.Suspense>
									}
								/>
								<Route path={getPathByPrefix('addOns', ':experienceId/{prefix}/*')}>
									<Route
										path=":addOnId"
										element={
											<React.Suspense fallback={<LoadLoadingComponent />}>
												<AddOnsPage />
											</React.Suspense>
										}
									/>
									<Route
										path=""
										element={
											<React.Suspense fallback={<LoadLoadingComponent />}>
												<AddOnsPage />
											</React.Suspense>
										}
									/>
								</Route>
								<Route path="*" element={<ErrorPage />} />
							</Route>
							<Route
								path={getPathByPrefix('profile', '{prefix}/:optionMenu?')}
								element={
									<React.Suspense fallback={<LoadLoadingComponent />}>
										<ProtectedRoute>
											<ProfilePage />
										</ProtectedRoute>
									</React.Suspense>
								}
							/>
							<Route
								path={getPathByPrefixes(
									['profile', 'bookings'],
									'{profile}/{bookings}/:bookingId'
								)}
								element={
									<React.Suspense fallback={<LoadLoadingComponent />}>
										<ProtectedRoute>
											<BookingPage />
										</ProtectedRoute>
									</React.Suspense>
								}
							/>

							<Route
								path={getPathByPrefixes(
									['profile', 'bookings', 'update'],
									'{profile}/{bookings}/:bookingId/{update}'
								)}
								element={
									<React.Suspense fallback={<LoadLoadingComponent />}>
										<ProtectedRoute>
											<UpdateBookingPage />
										</ProtectedRoute>
									</React.Suspense>
								}
							/>

							<Route
								path={getPathByPrefix('referrals', '{prefix}/:userCode')}
								element={
									<React.Suspense>
										<UnauthenticatedRoute>
											<ProfileReferralsSharedComponent />
										</UnauthenticatedRoute>
									</React.Suspense>
								}
							/>
							<Route
								path={getPathByPrefix('form', '{prefix}/:uuid')}
								element={
									<React.Suspense>
										<ProtectedRoute>
											<FormPage />
										</ProtectedRoute>
									</React.Suspense>
								}
							/>
							<Route
								path={getPathByPrefixes(
									['thankYou', 'payment', 'detail', 'booking'],
									'{thankYou}/:bookingId/{payment}/:transactionId/{detail}/{booking}'
								)}
								element={
									<React.Suspense fallback={<LoadLoadingComponent />}>
										<ProtectedRoute>
											<BookingThankYouPage />
										</ProtectedRoute>
									</React.Suspense>
								}
							/>
							<Route
								path={getPathByPrefixes(
									['thankYou', 'payment', 'detail', 'giftCard'],
									'{thankYou}/:giftCardId/{payment}/:transactionId/{detail}/{giftCard}'
								)}
								element={
									<React.Suspense fallback={<LoadLoadingComponent />}>
										<ProtectedRoute>
											<GiftCardThankYouPage />
										</ProtectedRoute>
									</React.Suspense>
								}
							/>
							<Route
								path={getPathByPrefixes(
									['thankYou', 'payment', 'detail', 'journey'],
									'{thankYou}/:journeyReservationId/{payment}/:transactionId/{detail}/{journey}'
								)}
								element={
									<React.Suspense fallback={<LoadLoadingComponent />}>
										<ProtectedRoute>
											<JourneyReservationThankYouPage />
										</ProtectedRoute>
									</React.Suspense>
								}
							/>
							<Route
								path={getPathByPrefix('recoverAccount', '{prefix}/:token')}
								element={
									<React.Suspense fallback={<LoadLoadingComponent />}>
										<ChangePasswordPage />
									</React.Suspense>
								}
							/>
							<Route
								path={getPathByPrefix('giftCard', '{prefix}')}
								element={
									<React.Suspense fallback={<LoadLoadingComponent />}>
										<GiftCardPage />
									</React.Suspense>
								}
							/>
							<Route
								path={getPathByPrefix('joinTheGroup', '{prefix}')}
								element={
									<React.Suspense fallback={<LoadLoadingComponent />}>
										<JoinTheGroupPage />
									</React.Suspense>
								}
							/>
							<Route
								path={getPathByPrefix('cookiesPolicy', '{prefix}')}
								element={<CookiesPolicyComponent />}
							/>

							<Route
								path={getPathByPrefix('dataProcessing', '{prefix}')}
								element={
									<React.Suspense fallback={<LoadLoadingComponent />}>
										<DataProcessingComponent />
									</React.Suspense>
								}
							></Route>

							<Route path="*" element={<ErrorPage />} />
						</Route>
						<Route path="*" element={<ErrorPage />} />
					</Routes>
				</div>

				<FooterComponent />
			</AppContextProvider>
		</React.Suspense>
	);
}

export default RoutingComponent;
