import { DefaultPaymentGateway, PaymentAPIsEnum, type PaymentEntity } from '@shared/enums/Payment.enum';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import type { Response as HttpResponse } from '@shared/interfaces/Http.interface';
import type { PageConfigurationInterface } from '@shared/interfaces/PageConfiguration.interface';
import type { BaseDetails, CreatePaymentLinkRequest, PaymentLink, TransactionResponse } from '@shared/interfaces/Payment.interface';
import { sendRequest } from '@shared/services/request/Request.service';

export const getTransactionDetails = async <Details extends BaseDetails>({
	entity,
	id
}: {
	entity: PaymentEntity;
	id: string;
}): Promise<TransactionResponse<Details> | CatchErrorInterface> => {
	return await sendRequest({
		url: PaymentAPIsEnum.TransactionDetails.replace(':entity', entity).replace(':transactionId', id),
		method: 'GET'
	});
}

export const createPayment = async ({
	id,
	entity,
	currentPageConfiguration
}: {
	id: number;
	entity: PaymentEntity;
	currentPageConfiguration: PageConfigurationInterface; 
}): Promise<HttpResponse<PaymentLink> | CatchErrorInterface> => {
	const data: CreatePaymentLinkRequest = {
		gatewayId: DefaultPaymentGateway
	};

	return await sendRequest({
		url: PaymentAPIsEnum.CreateLink.replace(':entity', entity).replace(':id', id.toString()),
		method: 'POST',
		currentPageConfiguration,
		body: data
	});
}