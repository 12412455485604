import { useResponsive } from '@shared/services/utils/Utils.services';
import { useTranslation } from 'react-i18next';

const ScopeSessionLandingProposed = ({
	img,
	title,
	description
}: {
	img: string;
	title: string;
	description: string;
}): JSX.Element => {
	const { isMobile, isTablet, isDesktop } = useResponsive();
	const { t } = useTranslation();

	const titleScope = t(isDesktop ? title : title);
	const descriptionScope = t(description);

	return (
		<>
			{!isDesktop && (
				<div className="row g-0">
					<div className="col-12 d-flex justify-content-center">
						<img className="w-75" src={img} alt="" />
					</div>
					<div className="text-start m-4 col-11">
						<h1
							className="awake-heading-2 awake-fw-900"
							dangerouslySetInnerHTML={{ __html: titleScope }}></h1>
						<p className="awake-heading-4">{descriptionScope}</p>
					</div>
				</div>
			)}
			{isDesktop && (
				<div className="row g-0 d-flex justify-content-center m-4">
					<div className="col-5 ">
						<img className="w-100" src={img} alt="" />
					</div>
					<div className="text-start m-4 col-5">
						<h1
							className="awake-heading-2 awake-fw-900"
							dangerouslySetInnerHTML={{ __html: titleScope }}></h1>
						<p className="awake-heading-4">{descriptionScope}</p>
					</div>
				</div>
			)}
		</>
	);
};

export default ScopeSessionLandingProposed;