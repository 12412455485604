import type { HeaderButtonPropsInterface } from '@header/interfaces/HeaderButtonProps.interface';
import DropdownMenuComponent from '@header/components/dropdown-menu/DropdownMenu.component';
import '@header/components/header-button/HeaderButton.component.scss';
import type { CurrencyInfoDataInterface } from '@shared/interfaces/Currency.interface';
import type { LanguagesInfoDataInterface } from '@shared/interfaces/Languages.interface';
import flag from '@assets/home/impact/flag-col.png';
import flagEn from '@assets/home/impact/flag-en.png';

function HeaderButtonComponent(props: HeaderButtonPropsInterface): JSX.Element {
	const setConfigData = function (
		newValue: CurrencyInfoDataInterface | LanguagesInfoDataInterface
	): void {
		props.buttonMethod(newValue);
	};

	const getName = (): string => {
		const language = props.items.find((item) => item.code === props.code);

		if (language) {
			return language.code.toUpperCase();
		}

		return '$ ' + props.text!;
	};

	return (
		<li className="nav-item dropdown header-button-component">
			<a
				className="nav-link dropdown-toggle"
				href="#"
				role="button"
				data-bs-toggle="dropdown"
				aria-expanded="false">
				{props.icon && <span className={props.icon}></span>}
				{props.code === 'es' && (
					<img className="awake-ht-15 awake-mx-10" src={flag} alt="" />
				)}
				{props.code === 'en' && (
					<img className="awake-ht-15 awake-mx-10" src={flagEn} alt="" />
				)}
				<span className={props.textClass + 'awake-heading-6 awake-fw-700'}>
					{getName()}
				</span>
			</a>
			<DropdownMenuComponent items={props.items} setConfigData={setConfigData} />
		</li>
	);
}

export default HeaderButtonComponent;
