import { type ReactNode, createContext, useContext, useMemo } from 'react';
import type { AppContextInterface } from '@shared/interfaces/AppContext.interface';
import { toggleExperienceWishList } from '@shared/services/wish-list/WishList.service';
import { type CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import { type WishListResponseInterface } from '@shared/services/wish-list/interfaces/WishListResult.interface';
import { isACatchError } from '@shared/services/utils/Utils.services';
import { type ExperienceDataInterface } from '@shared/interfaces/Experience.interface';
import { showToastError, showToastSuccess } from '@shared/services/toast-service/toast.service';
import { useTranslation } from 'react-i18next';

export const AppContext: React.Context<AppContextInterface> = createContext({} as any);

export const useAppContext = (): AppContextInterface => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useApp should be used inside a AppContextProvider');
  }

  return context;
}




export const AppContextProvider: React.FC<{ value: AppContextInterface, children: ReactNode }> = ({ value, children }) => {
	const { t } = useTranslation();

  const updateParentList = (experienceToSend: ExperienceDataInterface): void => {
		value.setAppContextMethod({
			lastFavoriteExperienceModified: experienceToSend
		});
	};
  
  const hasLogin = (experience:any): any => {
    if (value.appContextValue.hasLogin) {      
      return true;
    }
    const experienceCopy = { ...experience };
      experienceCopy.inWishList = true;
      value.setAppContextMethod({
        showLogin: true,
        lastFavoriteExperienceModifiedTemp: experienceCopy
      });
      return false;
  };

  const toggleWishList = (experience:any): void => {
		toggleExperienceWishList(experience.id)
			.then((result: CatchErrorInterface | WishListResponseInterface) => {
				if (!isACatchError(result)) {
					const experienceCopy = { ...experience };
					experienceCopy.inWishList = result.data.inWishList;
					updateParentList(experienceCopy);
					showToastSuccess(
						experienceCopy.inWishList
							? t('experienceCard.wishList.added')
							: t('experienceCard.wishList.removed')
					);
				} else {
					console.error(result.error);
					showToastError(result.message);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const wishListAction = (experience:any): void => {
		if (hasLogin(experience)) {
			toggleWishList(experience);
		}
	};

  value.wishListAction = wishListAction;
  
  return (
      <AppContext.Provider value={value} >
          {children}
      </AppContext.Provider>
  );
}
