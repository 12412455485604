import awakeBrand from '@assets/common/awake-brand-green.svg';
import type { AuthComponentPropsInterface } from '@auth/interfaces/AuthComponentProps.interface';
import { AuthPageOptionsEnum } from '@auth/enums/AuthPageOptions.enum';
import { useFormik } from 'formik';
import { recoverUserPasswordFormValidations } from '@auth/components/recover/validations/RecoverUserPasswordForm.validations';
import type { RecoverUserPasswordFormInterface } from '@auth/components/recover/interfaces/RecoverUserPasswordForm.interface';
import { useEffect, useRef, useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import type { RecoverUserPasswordResponseInterface } from '@shared/services/auth-service/interfaces/RecoverUserPasswordResult.interface';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import { recoverUserPassword } from '@shared/services/auth-service/Auth.service';
import { isACatchError } from '@shared/services/utils/Utils.services';
import { showToastError, showToastSuccess } from '@shared/services/toast-service/toast.service';
import { AppContext } from '@shared/context/App.context';

function RecoverComponent(props: AuthComponentPropsInterface): JSX.Element {
    const { appContextValue, setAppContextMethod } = useContext(AppContext);
    const [createAccountLink, setCreateAccountLink] = useState(true);    

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: recoverUserPasswordFormValidations(props.formValidationsLabels),
        onSubmit: (values: RecoverUserPasswordFormInterface) => {
            recoverUserPassword(values)
                .then((result: CatchErrorInterface | RecoverUserPasswordResponseInterface) => {
                    if (!isACatchError(result)) {
                        formik.resetForm();
                        showToastSuccess(props.authLabels.recoverSuccess, 'top-center');
                    } else {
                        showToastError(result.message.message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    props.toggleAuthPageMethod();
                });
        }
    });

    useEffect(()=>{
        setAppContextMethod({
            showLogin:false
        });
    },[appContextValue.showLogin])

    useEffect(() => {
        setCreateAccountLink(appContextValue?.authView == null);
        setTimeout(() => {
            setAppContextMethod({
                authView: null
            });
        }, 1000);        
    }, []);
 
    return (
        <div className="recover-component container-fluid awake-ps-container awake-pe-container">
            <div className="row">
                <div className="col-12 text-center">
                    <img className="login-component__logo" src={awakeBrand} alt="awake-logo"></img>
                </div>
            </div>
            <div className="row awake-mt-10">
                <div className="col-12 text-center">
                    <label className="awake-heading-3 awake-fw-700">
                        {props.authLabels.recoverYourAccount}
                    </label>
                </div>
            </div>
            <Form onSubmit={formik.handleSubmit}>
                <div className="row awake-mt-20">
                    <div className={'col-12' + (window.innerWidth < 1200 ? 'awake-mb-26' : '')}>
                        <div
                            className={`${
                                formik.touched.email && formik.errors.email
                                    ? 'invalid'
                                    : formik.values.email !== ''
                                        ? 'valid'
                                        : 'awake-input__black'
                            } awake-ms-auto awake-me-auto awake-wt-276 awake-input`}>
                            <label htmlFor="email" className="awake-input__label awake-bg-white">
                                <span className="icon-account_circle awake-input__label__icon awake-link-icon awake-me-10"></span>
                                <span className="awake-text-complementary">
                                    {props.authLabels.email}
                                </span>
                            </label>
                            <input
                                name="email"
                                className="awake-ht-55 awake-input__input awake-bg-white"
                                placeholder={props.authLabels.emailPlaceholder}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}></input>
                            {formik.touched.email && formik.errors.email ? (
                                <div className="invalid-message">{formik.errors.email}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="row awake-mt-20">
                    <div className="col-12 text-center">
                        <label className="awake-heading-4">{props.authLabels.doNotWorryInfo}</label>
                    </div>
                </div>
                <div className="row awake-mt-10 awake-mb-20">
                    <div className="col-12 text-center">
                        <button
                            type='submit'
                            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                            className={
                                'awake-secondary-button awake-fw-700 awake-py-15 awake-px-32 awake-mt-10'
                            }>
                            {props.authLabels.sendButtonLabel}
                        </button>
                    </div>
                </div>
            </Form>
            {createAccountLink && (
                <div className="row awake-mb-40">
                    <div className="col-12 text-center">
                        <label
                            onClick={() =>
                                props.setAuthViewMethod(AuthPageOptionsEnum.RegisterView)
                            }
                            className="awake-heading-5 awake-fw-700 awake-text-primary awake-d-block awake-cursor-pointer">
                            {props.authLabels.createAccountOption}
                        </label>
                    </div>
                </div>
            )}
        </div>
    );
}

export default RecoverComponent;
