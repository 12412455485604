import { Route } from '@shared/enums/Routes.enum';
import { getAuthSession } from '@shared/services/storage/SessionStorage.service';
import { getRoute } from '@shared/services/utils/Utils.services';
import { Navigate } from 'react-router-dom';
import type { ProtectedRoutePropsInterface } from './interfaces/ProtectedRouteProps.interface';

export const ProtectedRoute = (props: ProtectedRoutePropsInterface): any => {
    return validateSession(props, true);
};

export const UnauthenticatedRoute = (props: ProtectedRoutePropsInterface): any => {
    return validateSession(props, false);
};

const validateSession = (props: ProtectedRoutePropsInterface, authenticated: boolean): any => {
    if (Boolean(getAuthSession()) === authenticated) {
        return props.children;
    }

    return <Navigate to={getRoute({ route: Route.Home })} replace />;
};
