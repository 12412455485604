import { useEffect, useState } from 'react';
import { getGropusInfo } from './services/JoinTheGroup.service';
import CardJoinTheGroup from './components/CardJoinTheGroup.componet';
import { useResponsive, useGetMonthName } from '@shared/services/utils/Utils.services';
import { useTranslation } from 'react-i18next';
import { JoinTheGroupSkeleton } from '@shared/components/skeletons/JoinTheGroup.skeleton';

const JoinTheGroup = (): JSX.Element => {
	const [data, setData] = useState<any[]>([]);
	const [selectedYear, setSelectedYear] = useState<string | null>(null);
	const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
	const { isMobile, isTablet, isDesktop } = useResponsive();
	const { t } = useTranslation();
	const getMonthName = useGetMonthName();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const fetchGroupsDataAndSetState = async (): Promise<void> => {
			try {
				const result = await getGropusInfo('groups');
				if ('data' in result && Array.isArray(result.data)) {
					setData(result.data);
					setIsLoading(false);
					const currentYear = new Date().getFullYear();
					setSelectedYear(currentYear.toString());
					if (result.data.length > 0) {
						setSelectedMonth(result.data[0].month);
					}
				} else {
					console.error('Error fetching data: Data format is incorrect');
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchGroupsDataAndSetState().catch((error) => {
			console.error('Error handling data:', error);
		});
	}, []);

	const handleYearChange = (year: string): any => {
		setSelectedYear(year);
		setSelectedMonth(null);
	};

	const handleMonthChange = (month: string): any => {
		setSelectedMonth(month);
	};

	const uniqueYears: string[] = [];

	for (const monthData of data) {
		if (!uniqueYears.includes(monthData.year)) {
			uniqueYears.push(monthData.year);
		}
	}

	const filteredData = selectedYear
		? selectedMonth
			? data.filter(
					(monthData) =>
						monthData.year === selectedYear && monthData.month === selectedMonth
			  )
			: data.filter((monthData) => monthData.year === selectedYear)
		: data;

	const getDuration = (duration: string): JSX.Element => {
		const [day, night] = duration.split(' ');
		return (
			<div className="d-flex justify-content-end">
				<div className="rounded awake-bg-white float-right mt-2 me-2 p-1">
					<div className="d-flex align-items-baseline justify-content-center">
						<label className="awake-heading-6 awake-fw-700 awake-text-black me-1">
							0{day}
						</label>
						<span className="icon-clear_day awake-icon-black awake-fw-700 awake-heading-6"></span>
					</div>
					{night && (
						<div className="d-flex align-items-baseline justify-content-center">
							<label className="awake-heading-6 awake-fw-700 awake-text-black me-1">
								0{night}
							</label>
							<span className="icon-night awake-icon-black awake-fw-700 awake-heading-6"></span>
						</div>
					)}
				</div>
			</div>
		);
	};

	const handleMonthNavClick = (month: any): any => {
		setSelectedMonth(month);
	};

	const next = (): any => {
		if (selectedMonth !== null) {
			const monthIndex = data.findIndex((monthData) => monthData.month === selectedMonth);
			if (monthIndex !== -1) {
				const nextMonthIndex = (monthIndex + 1) % data.length;
				setSelectedMonth(data[nextMonthIndex].month);
			}
		}
	};

	const prev = (): any => {
		if (selectedMonth !== null) {
			const monthIndex = data.findIndex((monthData) => monthData.month === selectedMonth);
			if (monthIndex !== -1) {
				const prevMonthIndex = (monthIndex - 1 + data.length) % data.length;
				setSelectedMonth(data[prevMonthIndex].month);
			}
		}
	};

	return (
		<>
			{isLoading ? (
				<JoinTheGroupSkeleton />
			) : (
				<div className="awake-bg-page-background">
					<div
						className={
							isDesktop
								? 'container-sm g-0 ps-sm-0 promotion-with-price d-flex flex-column gap-3'
								: ''
						}>
						<div className={isMobile ? 'row m-1 mt-2 position-relative' : 'row '}>
							<div className="col-12">
								<div className="row g-0 awake-text-secondary">
									<div
										className={
											isMobile
												? 'col-12 awake-heading-4 awake-text-secondary awake-fw-700 mb-4 mt-2'
												: 'col-lg-5 awake-fw-700 awake-heading-4 mb-2 mt-2 awake-text-secondary'
										}>
										{t('joinTheGroup.chooseMonth')}
									</div>
									<div className="col-lg-2 col-4 col-md-3 col-xl-2 me-2">
										<select
											onChange={(e) => handleYearChange(e.target.value)}
											className="form-select"
											value={selectedYear ?? ''}>
											{uniqueYears.map((year, index) => (
												<option key={index} value={year}>
													{year}
												</option>
											))}
										</select>
									</div>
									{selectedYear && (
										<div className="col-lg-2 col-5 col-md-3 col-xl-2">
											<select
												onChange={(e) => handleMonthChange(e.target.value)}
												className="form-select"
												value={selectedMonth ?? ''}>
												{data
													.filter(
														(monthData) =>
															monthData.year === selectedYear
													)
													.map((monthData, index) => (
														<option key={index} value={monthData.month}>
															{getMonthName(monthData.month)}
														</option>
													))}
											</select>
										</div>
									)}
								</div>
							</div>
						</div>
						<>
							{filteredData.map((monthData, index) => (
								<>
									{monthData.duration && getDuration(monthData.duration)}
									<div
										key={index}
										className={isMobile ? 'row mt-3 m-1' : 'row  '}>
										<div className="col-12 col-lg-8">
											<div className="awake-heading-3 awake-text-secondary awake-fw-900">
												{t('joinTheGroup.calendar')}{' '}
												{`${getMonthName(monthData.month)} ${
													monthData.year
												}`}
											</div>
											<div className="awake-heading-6">
												{t('joinTheGroup.observation')}
											</div>
										</div>
										<div className="col-4 cursor-pointer-link pt-2">
											{!isMobile && !isTablet && (
												<>
													<div
														onClick={next}
														className="icon-array  mb-2 awake-ht-30 awake-wt-150 awake-br-50 box-shadow-wt-icon d-flex justify-content-center align-items-center icon-chevron_right float-end cursor-pointer-link">
														{filteredData.length !== 0 ? (
															<label className="awake-heading-6 m-2 awake-fw-700 cursor-pointer-link">
																{t('joinTheGroup.nextMonthButton')}
															</label>
														) : (
															<div></div>
														)}
													</div>
													<div
														onClick={prev}
														className="icon-array me-2 mb-2 awake-ht-30 awake-wt-50 awake-br-50 box-shadow-wt-icon d-flex justify-content-center align-items-center icon-chevron_left float-end cursor-pointer-link"></div>
												</>
											)}
										</div>
										{monthData.items.map((card: any, cardIndex: any) => (
											<div
												className="col-12 col-md-12 col-lg-12 col-xl-6 mt-3 g-0 mb-3"
												key={cardIndex}>
												<CardJoinTheGroup
													title={card.title}
													image={card.image}
													url={card.url}
													price={card.price}
													dates={card.dates}
													duration={card.duration}
													startPoint={card.startPoint}
												/>
											</div>
										))}
									</div>
								</>
							))}
						</>
					</div>
					{isDesktop && (
						<nav className="nav d-flex justify-content-center awake-mt-50 cursor-pointer-link">
							{data.map((mont, index) => (
								<a
									key={index}
									className={`nav-link text-decoration-none  ${
										selectedMonth === mont.month
											? 'selected-month'
											: 'awake-text-black'
									}`}
									onClick={() => handleMonthNavClick(mont.month)}>
									{getMonthName(mont.month)}
								</a>
							))}
						</nav>
					)}

					{isMobile && (
						<>
							<div className="d-flex justify-content-center cursor-pointer-link mb-3 mt-4">
								<div
									onClick={prev}
									className="awake-bg-white icon-array me-2 mb-2 awake-ht-40 awake-wt-50 awake-br-50 box-shadow-wt-icon d-flex justify-content-center align-items-center icon-chevron_left float-end cursor-pointer-link"></div>
								<div
									onClick={next}
									className="awake-bg-white icon-array me-2 mb-2 awake-ht-40 awake-wt-150 awake-br-50 box-shadow-wt-icon d-flex justify-content-center align-items-center icon-chevron_right float-end cursor-pointer-link">
									{filteredData.length !== 0 ? (
										<label className="awake-heading-6 m-2 awake-fw-700 cursor-pointer-link">
											{t('joinTheGroup.nextMonthButton')}
										</label>
									) : (
										<div></div>
									)}
								</div>
							</div>
							<nav className="nav d-flex justify-content-start mb-4">
								{data.map((mont, index) => (
									<a
										key={index}
										className={`nav-link text-decoration-none  ${
											selectedMonth === mont.month
												? 'selected-month'
												: 'awake-text-black'
										}`}
										onClick={() => handleMonthNavClick(mont.month)}>
										{getMonthName(mont.month)}
									</a>
								))}
							</nav>
						</>
					)}
					{isTablet && (
						<>
							<nav className="nav d-flex justify-content-center mb-4 awake-mt-50">
								{data.map((mont, index) => (
									<a
										key={index}
										className={`nav-link text-decoration-none  ${
											selectedMonth === mont.month
												? 'selected-month'
												: 'awake-text-black'
										}`}
										onClick={() => handleMonthNavClick(mont.month)}>
										{getMonthName(mont.month)}
									</a>
								))}
							</nav>

							<div className="d-flex justify-content-center cursor-pointer-link mb-4">
								<div
									onClick={prev}
									className="icon-array me-2 mb-2 awake-ht-30 awake-wt-50 awake-br-50 box-shadow-wt-icon d-flex justify-content-center align-items-center icon-chevron_left float-end cursor-pointer-link"></div>
								<div
									onClick={next}
									className="icon-array me-2 mb-2 awake-ht-30 awake-wt-150 awake-br-50 box-shadow-wt-icon d-flex justify-content-center align-items-center icon-chevron_right float-end cursor-pointer-link">
									{filteredData.length !== 0 ? (
										<label className="awake-heading-6 m-2 awake-fw-700 cursor-pointer-link">
											{t('joinTheGroup.nextMonthButton')}
										</label>
									) : (
										<div></div>
									)}
								</div>
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
};

export default JoinTheGroup;
