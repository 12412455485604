import { Route } from '@shared/enums/Routes.enum';
import { showToastSuccess } from '@shared/services/toast-service/toast.service';
import { getRoute } from '@shared/services/utils/Utils.services';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import './Cookies.component.scss';

export const CookiesComponent = (): JSX.Element => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const isAtSameRoute = pathname === getRoute({ route: Route.CookiesPolicy });
	const hasAcceptedPolicies = document.cookie.includes('acceptedCookies=true');
	const hasClosedPolicies = document.cookie.includes('cookiesPoliciesClosed=true');
	const [activeCookies, setActiveCookies] = useState(!hasAcceptedPolicies);
	const [showIcon, setShowIcon] = useState<boolean>(!isAtSameRoute ? hasClosedPolicies : false);

	const setCookies = (): void => {
		const expirationDate = new Date();
		expirationDate.setDate(expirationDate.getDate() + 30);
		document.cookie = `acceptedCookies=true; expires=${expirationDate.toUTCString()}; path=/;`;
		setActiveCookies(false);
		showToastSuccess(t('cookies.messageAcceptedPolicies'));
	};

	const close = (): void => {
		setShowIcon(true);
		document.cookie = 'cookiesPoliciesClosed=true;';
	}

	const showPopup = (): void => {
		setShowIcon(false);
	}

	useEffect(() => {
		if (!hasAcceptedPolicies && !hasClosedPolicies && !isAtSameRoute) {
			const timeout = setTimeout(() => {
				close();
			}, 5000);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, []);

	return (
		<>
			{activeCookies && (
				<>
					<div className='cookies-container'>
						{showIcon ? (
							<div
								className="open-icon flicker"
								onClick={showPopup}
							>
								<div className="awake-bg-white awake-ht-60 awake-wt-60 awake-br-50 awake-p-12 box-shadow-wt-icon icon-container d-flex justify-content-center align-items-center">
									<span className='icon-lock'></span>
								</div>
							</div>
						) : (
							<div className='row awake-br-8 shadow-lg p-2 bg-body rounded cookies-component'>
								<div className='position-relative'>
									{!isAtSameRoute && (
										<span className='icon-close close-button position-absolute' onClick={close}></span>
									)}

									<div className='col-12 awake-mt-10 awake-mb-10'>
										<label className='awake-text-complementary'>{t('cookies.title')}</label>
									</div>

									<div className='row g-0'>
										<div className='col-12 d-flex justify-content-evenly'>
											{!isAtSameRoute && (
												<a
													href={getRoute({ route: Route.CookiesPolicy })}
													target='_blank'
													rel='noreferrer'
													onClick={close}
													className='btn btn-outline-primary awake-br-30 pe-auto d-flex align-items-center'
												>
													{t('cookies.buttonPolicy')}
												</a>
											)}

											<button
												onClick={setCookies}
												className='btn btn-primary awake-br-30 pe-auto'>
												{t('cookies.acceptButton')}
											</button>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</>
			)}
		</>
	);
};
