import { isUsingGtm } from '@shared/services/utils/Environment.util';
import { getAuthSession } from '../storage/SessionStorage.service';

export const updateDataLayer: () => void = () => {     
    if(isUsingGtm()){
        const data: {
            'url hostname': Location,
            userId?: number
        } = {
            'url hostname': window.location
        };
    
        if (getAuthSession()) {
            data.userId = getAuthSession().id;
        }
                
        (window as any).dataLayer.push(data);
    }
};
