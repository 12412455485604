import { useResponsive } from '@shared/services/utils/Utils.services';
import logo from '@assets/journey/logo.svg';
import { useTranslation } from 'react-i18next';

function HeaderComponent({title, footer}: {title:string, footer?: boolean}): JSX.Element {
    const { isMobile, isTablet, isDesktop } = useResponsive();
    const { t } = useTranslation();

    return <>
        <div className={`${footer ?? isDesktop ? 'border-end' : ''} text-center py-md-3 px-1 px-lg-0`}>
            <h2 className='awake-fw-700  awake-text-white pe-2 awake-heading-2'>
                {t('journey.title')} <label className='awake-text-journey'>{title}</label>!
            </h2>
        </div>
        <div>
            <img
                className={`awake-cover-image awake-wt-200 ${isMobile ?? isTablet ? 'border-bottom awake-wt-per-100' : ''}`}
                src={logo}
            />
        </div>
    </>
}
export default HeaderComponent;
