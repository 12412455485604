import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { defaultPageConfigs } from '@shared/services/set-configs/defaultPageConfigs';
import { type LanguageCode } from '@shared/interfaces/DefaultPageConfigs.interface';
import { ErrorRoute } from '@shared/routing/Error.route';

export const LangGuard = (): any => {
    const { lang } = useParams();
    const { pathname, search, hash } = useLocation();

    if (!lang) {
        return <Outlet />;
    }

    if (defaultPageConfigs.allowedLanguages.includes(lang as LanguageCode)) {
        if (lang === defaultPageConfigs.language.code) {
            const path = getPathWithoutLang(pathname, lang);

            return <Navigate to={`${path}${search}${hash}`} />;
        }

        return <Outlet />;
    }

    return ErrorRoute;
};

const getPathWithoutLang = (pathname: string, lang: string): string => {
    if (pathname === `/${lang}`) {
        return '/';
    }

    return pathname.replace(`/${lang}/`, '/');
};
