import type { ChangePasswordComponentPropsInterface } from '@auth/components/change-password/interfaces/ChangePasswordComponentProps.interface';
import type { ChangeUserPasswordFormInterface } from '@auth/components/change-password/interfaces/ChangeUserPasswordForm.interface';
import { changeUserPasswordFormValidations } from '@auth/components/change-password/validations/ChangeUserPasswordForm.validations';
import { Route } from '@shared/enums/Routes.enum';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import { changeUserPassword } from '@shared/services/auth-service/Auth.service';
import type { ChangeUserPasswordResponseInterface } from '@shared/services/auth-service/interfaces/ChangeUserPasswordResult.interface';
import { showToastError } from '@shared/services/toast-service/toast.service';
import { getRoute, isACatchError } from '@shared/services/utils/Utils.services';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

function ChangePasswordComponent(props: ChangePasswordComponentPropsInterface): JSX.Element {
    const navigate = useNavigate();
    const [success, setSuccess] = useState<boolean>(false);
    const { token } = useParams();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmationPassword: ''
        },
        validationSchema: changeUserPasswordFormValidations(
            props.authLabels,
            props.formValidationsLabels
        ),
        onSubmit: (values: ChangeUserPasswordFormInterface) => {
            changeUserPassword({ ...values, token })
                .then((result: CatchErrorInterface | ChangeUserPasswordResponseInterface) => {
                    if (!isACatchError(result)) {
                        formik.resetForm();
                        setSuccess(true);
                    } else {
                        showToastError(result.message.message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    });
    return (
        <div className="change-password-component container-fluid awake-ps-container awake-pe-container awake-mb-169">
            {success ? (
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-6 awake-mt-80">
                        <div className="row g-0 awake-br-8 awake-border-primary awake-p-22">
                            <div className="col-12">
                                <div className="row g-0">
                                    <div className="col-auto">
                                        <span className="icon-error_circle_rounded awake-me-10"></span>
                                    </div>
                                    <div className="col">
                                        <h5 className="awake-heading-5 awake-fw-700">
                                            {props.authLabels.recoverSuccessTitle}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <h5 className="awake-heading-5">
                                    {props.authLabels.recoverSuccessMessage}
                                </h5>
                            </div>
                            <div
                                className={`col-12 awake-ms-auto awake-me-auto ${window.innerWidth > 768
                                    ? 'awake-mb-n-40'
                                    : 'awake-mt-13 text-center'
                                    }`}>
                                <Link
                                    to={getRoute({ route: Route.Home })}
                                    className={`awake-button awake-secondary-button awake-fw-700 awake-ms-auto  awake-py-15 awake-px-32 ${window.innerWidth > 768
                                        ? 'float-end awake-me-40'
                                        : 'awake-mt-13'
                                        }`}>
                                    {props.authLabels.start}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-12 awake-mt-80">
                        <Form onSubmit={formik.handleSubmit}>
                            <div className="row awake-mt-10">
                                <div className="col-12">
                                    <label className="awake-heading-3 awake-fw-700">
                                        {props.authLabels.changeYourPasswordTitle}
                                    </label>
                                </div>
                            </div>
                            <div className="row awake-mt-10">
                                <div className="col-12">
                                    <label className="awake-heading-5">
                                        {props.authLabels.changePasswordLinkInfo}
                                    </label>
                                </div>
                            </div>
                            <div className="row awake-mt-20">
                                <div
                                    className={
                                        'col-12 col-lg-3 col-xl-3 ' +
                                        (window.innerWidth < 1200 ? 'awake-mb-26' : '')
                                    }>
                                    <div
                                        className={`${formik.touched.email && formik.errors.email
                                            ? 'invalid'
                                            : formik.values.email !== ''
                                                ? 'valid'
                                                : 'awake-input__black'
                                            } ${window.innerWidth > 1200
                                                ? ''
                                                : ' awake-ms-auto awake-me-auto'
                                            } awake-wt-240 awake-input`}>
                                        <label
                                            htmlFor="name"
                                            className="awake-input__label awake-bg-white">
                                            <span className="icon-account_circle awake-input__label__icon awake-link-icon awake-me-10"></span>
                                            <span className="awake-text-complementary">
                                                {props.authLabels.email}
                                            </span>
                                        </label>
                                        <input
                                            name="email"
                                            className="awake-ht-55 awake-input__input awake-bg-white"
                                            placeholder={props.authLabels.emailPlaceholder}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}></input>
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className="invalid-message">
                                                {formik.errors.email}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className={
                                        'col-12 col-lg-3 col-xl-3 ' +
                                        (window.innerWidth < 1200 ? 'awake-mb-26' : '')
                                    }>
                                    <div
                                        className={`${formik.touched.password && formik.errors.password
                                            ? 'invalid'
                                            : formik.values.password !== ''
                                                ? 'valid'
                                                : 'awake-input__black'
                                            } ${window.innerWidth > 1200
                                                ? ''
                                                : ' awake-ms-auto awake-me-auto'
                                            } awake-wt-250 awake-input`}>
                                        <label
                                            htmlFor="name"
                                            className="awake-input__label awake-bg-white">
                                            <span className="icon-account_circle awake-input__label__icon awake-link-icon awake-me-10"></span>
                                            <span className="awake-text-complementary">
                                                {props.authLabels.newPasswordPlaceholder}
                                            </span>
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            className="awake-ht-55 awake-input__input awake-bg-white"
                                            placeholder={props.authLabels.passwordPlaceholder}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}></input>
                                        {formik.touched.password && formik.errors.password ? (
                                            <div className="invalid-message">
                                                {formik.errors.password}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className={
                                        'col-12 col-lg-3 col-xl-3 ' +
                                        (window.innerWidth < 1200 ? 'awake-mb-26' : '')
                                    }>
                                    <div
                                        className={`${formik.touched.confirmationPassword &&
                                            formik.errors.confirmationPassword
                                            ? 'invalid'
                                            : formik.values.confirmationPassword !== ''
                                                ? 'valid'
                                                : 'awake-input__black'
                                            } ${window.innerWidth > 1200
                                                ? ''
                                                : ' awake-ms-auto awake-me-auto'
                                            } awake-wt-250 awake-input`}>
                                        <label
                                            htmlFor="confirmationPassword"
                                            className="awake-input__label awake-bg-white">
                                            <span className="icon-account_circle awake-input__label__icon awake-link-icon awake-me-10"></span>
                                            <span className="awake-text-complementary">
                                                {props.authLabels.newPasswordConfirmPlaceholder}
                                            </span>
                                        </label>
                                        <input
                                            type="password"
                                            name="confirmationPassword"
                                            className="awake-ht-55 awake-input__input awake-bg-white"
                                            placeholder={props.authLabels.passwordPlaceholder}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.confirmationPassword}></input>
                                        {formik.touched.confirmationPassword &&
                                            formik.errors.confirmationPassword ? (
                                            <div className="invalid-message">
                                                {formik.errors.confirmationPassword}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row awake-mt-40">
                                    <div
                                        className={
                                            'col-12 col-lg-2 col-xl-2 ' +
                                            (window.innerWidth < 1200
                                                ? 'awake-mb-26'
                                                : 'text-center')
                                        }>
                                        <button
                                            disabled={
                                                formik.isSubmitting ||
                                                !formik.dirty ||
                                                !formik.isValid
                                            }
                                            className={
                                                'awake-secondary-button awake-fw-700 awake-py-15 awake-px-32 awake-mt-10'
                                            }>
                                            {props.authLabels.updateButtonLabel}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ChangePasswordComponent;
