import * as Yup from 'yup';
import { emailFormatValidation } from '@shared/services/validations/FormValidations.service';
import type { FormValidationsLabelsInterface } from '@shared/interfaces/FormValidationLabels.interface';

export function loginFormValidations(FormValidationsLabels: FormValidationsLabelsInterface): any {
    return Yup.object({
        username: Yup.string()
            .matches(emailFormatValidation.value, FormValidationsLabels.emailFormatValidation)
            .email(FormValidationsLabels.emailFormatValidation)
            .required(FormValidationsLabels.requiredField),
        password: Yup.string().required(FormValidationsLabels.requiredField)
    });
}
