import awakeBrand from '@assets/common/awake-brand.png';
import sicBrand from '@assets/common/sic-brand.png';
import type { LanguagesInfoDataInterface } from '@shared/interfaces/Languages.interface';
import type { CurrencyInfoDataInterface } from '@shared/interfaces/Currency.interface';
import { useContext } from 'react';
import { AppContext } from '@shared/context/App.context';
import flag from '@assets/home/impact/flag-col.png';
import flagEn from '@assets/home/impact/flag-en.png';
import { useTranslation } from 'react-i18next';

function FooterRightComponent(): JSX.Element {
  const { appContextValue, setLanguage, setCurrency } =
    useContext(AppContext);
    const { t } = useTranslation();

  const getName = (): string => {
    if (appContextValue.languagesInfo) {
      const language = appContextValue.languagesInfo!.find(
        (item) => item.code === appContextValue.currentPageConfiguration!.language.code
      );

      if (language) {
        return language.name;
      }
    }

    return appContextValue.currentPageConfiguration!.language.name;
  };

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex flex-row align-items-center gap-3">
        <img src={awakeBrand} className="awake-ht-50"></img>
        <img src={sicBrand} className="footer-component__sic-icon"></img>
      </div>

      <div className="d-flex flex-row">
        <div className="col-4 d-flex flex-row">
          <a
            className="dropdown-toggle dropdown__toggle-title awake-cursor-pointer awake-fw-700 d-flex flex-row align-items-center"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {appContextValue.currentPageConfiguration?.language.code === 'es' ? (
              <img className="awake-wt-18 awake-ht-18 me-2" src={flag} alt="" />
            ) : (
              <img className="awake-wt-18 awake-ht-18 me-2" src={flagEn} alt="" />
            )}
            <label className='awake-cursor-pointer'>
              {getName()}
            </label>
          </a>
          <div className="dropdown-menu rounded-0">
            {appContextValue.languagesInfo?.map(
              (lang: LanguagesInfoDataInterface, index: number) => (
                <a
                  key={index}
                  className="dropdown-item awake-cursor-pointer"
                  onClick={async () => await setLanguage(lang)}>
                  <div className="row g-0">
                    <div className="col">
                      <label className="awake-heading-6 awake-language-label awake-fw-700 awake-text-secondary awake-cursor-pointer">
                        {lang.code} - {lang.name}
                      </label>
                    </div>
                  </div>
                </a>
              )
            )}
          </div>
        </div>
        <div className="col-4 d-flex flex-row">
          <a
            className="dropdown-toggle dropdown__toggle-title awake-cursor-pointer awake-fw-700 d-flex flex-row align-items-center"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false">
            <span className="icon-attach_money awake-icon-primary me-2"></span>
            {appContextValue.currentPageConfiguration?.currency.code}
          </a>
          <div className="dropdown-menu rounded-0">
            {appContextValue.currenciesInfo?.map(
              (currency: CurrencyInfoDataInterface, index: number) => (
                <a
                  key={index}
                  className="dropdown-item awake-cursor-pointer"
                  onClick={() => setCurrency(currency)}>
                  <div className="row">
                    <div className="col d-flex flex-row align-items-center gap-3">
                      <span className="icon-attach_money"></span>
                      <label className="awake-heading-6 awake-fw-700 awake-text-secondary awake-cursor-pointer">
                        {currency.code} - {currency.name}
                      </label>
                    </div>
                  </div>
                </a>
              )
            )}
          </div>
        </div>
      </div>

      <div className="d-flex">
        <label className="awake-heading-6 awake-text-white">
          {t('footer.copyright')}
        </label>
      </div>
    </div>
  );
}

export default FooterRightComponent;
