import * as Yup from 'yup';
import type { FormValidationsLabelsInterface } from '@shared/interfaces/FormValidationLabels.interface';
import BaseJoi from 'joi';
import phoneNumber from 'joi-phone-number'
import { t } from 'i18next';

export const validatePhone = (value: any): boolean => {
    const Joi = BaseJoi.extend(phoneNumber);

    const schema = Joi.string().phoneNumber({
        format: 'international',
        defaultCountry: 'CO',
        strict: true
    });

    const { error } = schema.validate(value);

    return !error;
};

export const phoneValidation = (message: string): Yup.StringSchema => Yup.string().test('phoneIsValid', message, validatePhone)

export function userPhoneSchema(): any {
    return Yup.object({
        phone: phoneValidation(t('validations.invalid')).required(t('validations.requiredField'))
    });
}
