import { useResponsive } from '@shared/services/utils/Utils.services';
import { useTranslation } from 'react-i18next';
import backgroundLiving from '@assets/landingProposed/backgroup.png';

const LivingArrangementsProposedComponent = ({
	img,
	title,
	description,
	icon,
	text
}: {
	img: string;
	title: string;
	description: string;
	icon: string;
	text: string;
}): JSX.Element => {
	const { isMobile, isTablet, isDesktop } = useResponsive();
	const { t } = useTranslation();

	const titleLiving = t(title);
	const descriptionLiving = t(description);
	const textLiving = t(text);
	return (
		<>
			{!isDesktop && (
				<>
					<div className="d-flex justify-content-center mt-4">
						<img src={img} alt="" className="w-100" />
					</div>

					<h1 className="text-start mt-4 awake-heading-2 awake-fw-900">{titleLiving}</h1>
					<h5
						className="awake-heading-5"
						dangerouslySetInnerHTML={{ __html: descriptionLiving }}></h5>
					<img src={icon} alt="" />
					<label
						className="awake-heading-6 ms-4"
						dangerouslySetInnerHTML={{ __html: textLiving }}></label>
				</>
			)}
			{isDesktop && (
				<>
					<div className="position-relative awake-mt-100 awake-mb-100 ">
						<div className="d-flex justify-content-end">
							<img
								className="awake-wt-900 awake-ht-190 m-4"
								src={backgroundLiving}
								alt=""
							/>
						</div>
						<div className="position-absolute top-50 start-50 translate-middle w-100">
							<div className="row align-items-center justify-content-center">
								<div className="col-4 d-flex justify-content-center me-4">
									<img className={isTablet ? 'awake-wt-400 mt-4' : 'awake-wt-900 mt-4 '} src={img} alt="" />
								</div>
								<div className="col-4 ms-4 mt-4">
									<h1 className="text-start awake-heading-2 awake-fw-900">
										{titleLiving}
									</h1>
									<h5
										className="awake-heading-5 mb-4"
										dangerouslySetInnerHTML={{
											__html: descriptionLiving
										}}></h5>

									<div className="mt-4 position-absolute">
										<img src={icon} alt="" />
										<label
											className="awake-heading-6 "
											dangerouslySetInnerHTML={{
												__html: textLiving
											}}></label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default LivingArrangementsProposedComponent;