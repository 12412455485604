import { useResponsive } from '@shared/services/utils/Utils.services';
import { useTranslation } from 'react-i18next';
import joinTheGroupImgBanner from '@assets/join-the-group/join-the-group-banner.png';
import joinTheGroupImgBannerMobile from '@assets/join-the-group/join-the-group-banner-mobile.png';
import joinTheGroupImgBannerTablet from '@assets/join-the-group/join-the-group-banner-tablet.png';

const BannerJoinTheGroup = (): JSX.Element => {
	const { isMobile, isTablet } = useResponsive();
	const { t } = useTranslation();
	const bannerImg = isTablet
		? joinTheGroupImgBannerTablet
		: isMobile
		? joinTheGroupImgBannerMobile
		: joinTheGroupImgBanner;
	return (
		<div className='position-relative'>
			<img className='w-100' src={bannerImg} alt='' />
			<div className='position-absolute top-50 start-50 translate-middle awake-fw-700'>
				{!isMobile && !isTablet && (
					<>
						<label className='awake-mt-120 awake-text-white'>
							{t('joinTheGroup.banner.title')}{' '}
						</label>{' '}
						<label className='awake-text-primary'> {t('joinTheGroup.banner.calendar')}</label>
					</>
				)}
			</div>
		</div>
	);
};

export default BannerJoinTheGroup;
