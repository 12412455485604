import { Helmet, type HelmetProps } from 'react-helmet';

export const Seo = ({
  data: {
    title,
    description,
    image,
    url
  },
  ...props
}: HelmetProps & {
  data: {
    title: string;
    description: string;
    url: string;
    image: string;
  }
}): JSX.Element => {
  return (
    <>
      <Helmet {...props}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={url} />
      </Helmet>
    </>
  );
};