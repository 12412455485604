import { formatTo } from '@shared/services/utils/Utils.services';
import type { Journey, Quoter as Props } from '../interfaces/Response.interface';
import moment from 'moment';
import imgPay from '@assets/img-pay/paymentMethods.png';
import PhoneInput from 'react-phone-input-2';
import { Form } from 'react-bootstrap';
import { useJourneyQuoter } from '../hooks/JourneyQuoter.hook';
import { useTranslation } from 'react-i18next';

export const Quoter = ({
  id,
  duration,
  travel: { start: startDate, end: endDate },
  people,
  price,
  title
}: Props & Pick<Journey, 'title' | 'id'>): JSX.Element => {
  const { t } = useTranslation();

  const {
    formikUserPhone,
    isLoading,
} = useJourneyQuoter(id)

  return (
    <div className="d-flex flex-column awake-bg-white rounded p-4 p-lg-5 gap-4" style={{ boxShadow: '0px 4px 4px 0px #0000000D' }}>
      <div className="d-flex flex-column col-md-8 col-lg-12 offset-md-2 offset-lg-0">
        <div className="d-flex flex-row justify-content-center">
          <h6 className="awake-heading-6 awake-fw-700 awake-text-complementary">
            {t('journey.quoter.summary')}
          </h6>
        </div>
        <div className="d-flex justify-content-center justify-content-lg-start">
          <h5 className="awake-heading-5 awake-fw-700 awake-text-secondary">
            {title}
          </h5>
        </div>
        <div className="d-flex flex-column gap-3">
          <div className="flex-row">
            <div className="d-flex flex-column">
              <div className="d-flex flex-row justify-content-between">
                <h5 className="awake-heading-5 awake-fw-400 awake-text-secondary">
                  {t('journey.quoter.duration')}:
                </h5>
                <h5 className="awake-heading-5 awake-fw-700 awake-text-journey text-end">
                  {duration}
                </h5>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex flex-row justify-content-between">
                <h5 className="awake-heading-5 awake-fw-400 awake-text-secondary">
                  {t('journey.quoter.date')}:
                </h5>
                <h5 className="awake-heading-5 awake-fw-700 awake-text-journey text-end">
                  {t('global.date.default', { start: moment(startDate).format('ddd, DD MMM'), end: moment(endDate).format('ddd, DD MMM') })}
                </h5>
              </div>
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-row justify-content-between">
                <h5 className="awake-heading-5 awake-fw-400 awake-text-secondary">
                  {t('journey.quoter.quantity')}:
                </h5>
                <h5 className="awake-heading-5 awake-fw-700 awake-text-journey text-end">
                  {t('global.people', { count: people })}
                </h5>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <h6 className="awake-heading-6 awake-fw-400 awake-text-secondary">{t('journey.quoter.littleLetter')}</h6>
              </div>
            </div>
            <hr className="awake-border-complementary"></hr>

            <div className="d-flex flex-column gap-4">
              <div className="d-flex flex-row justify-content-between pt-2">
                <h4 className="awake-heading-4 awake-fw-400 awake-text-secondary">
                  {t('journey.quoter.totalValue')}
                </h4>
                <h3 className="awake-heading-3 awake-fw-700 awake-text-secondary text-end">
                  {formatTo(price)}
                </h3>
              </div>
            </div>
            <hr className="awake-border-complementary"></hr>
            <div className="d-flex flex-column gap-4">
              <Form className='d-flex flex-column gap-4' onSubmit={formikUserPhone.handleSubmit}>
                <div className="d-flex flex-column gap-4">
                  <h4 className="awake-heading-4 awake-fw-700 awake-text-secondary">
                    {t('journey.quoter.phone')}
                  </h4>
                  <div
                    className={`${formikUserPhone.errors.phone
                      ? 'invalid'
                      : formikUserPhone.values.phone !== ''
                        ? 'valid'
                        : ''
                      } awake-input`}>
                    <PhoneInput
                      country={'co'}
                      onChange={(value, data, event, formattedValue) => {
                        formikUserPhone.handleChange({
                          target: {
                            name: 'phone',
                            value
                          }
                        });
                      }}
                      value={formikUserPhone.values.phone}
                      onBlur={formikUserPhone.handleBlur}
                      inputClass="awake-ht-55 awake-input awake-bg-white"
                    />
                    {formikUserPhone.errors.phone ? (
                      <div className="invalid-message">
                        {formikUserPhone.errors.phone}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center">
                  <button
                    id='btn-separate-quota'
                    className="awake-button awake-journey-button awake-fw-700 awake-py-15 awake-px-32"
                    disabled={!formikUserPhone.isValid}
                  >
                    <span
                      className={`wake-link-icon awake-me-10 awake-icon-secondary ${isLoading
                        ? 'icon-spinning'
                        : 'icon-shopping_cart'
                        }`}
                    ></span>
                    {t('journey.quoter.pay')}
                  </button>
                </div>
              </Form>
              <div className="d-flex flex-column gap-1">
                <h5 className="awake-heading-5 awake-fw-700 awake-text-secondary">
                  {t('journey.quoter.card')}:
                </h5>
                <div className="">
                  <img src={imgPay} alt="img"></img>
                </div>
                <h5 className="awake-heading-5 awake-fw-400 awake-text-secondary">
                  {t('journey.quoter.payCurrency')}:
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}