import imgUnlogged from '@assets/header/unlogged.png';
import AuthPage from '@auth/Auth.page';
import '@header/Header.component.scss';
import ProfileMobileMenuComponent from '@header/components/profile-mobile-menu/ProfileMobileMenu.component';
import RightMenuComponent from '@header/components/right-menu/RightMenu.component';
import GoogleOneTapLoginComponent from '@root/modules/auth/components/login/GoogleOneTapLogin.component';
import { AuthPageOptionsEnum } from '@root/modules/auth/enums/AuthPageOptions.enum';
import { HeaderLabelsEN, HeaderLabelsES } from '@shared/components/header/labels/Header.labels';
import { AppContext } from '@shared/context/App.context';
import { defaultPageConfigs } from '@shared/services/set-configs/defaultPageConfigs';
import { getAuthSession } from '@shared/services/storage/SessionStorage.service';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CookiesComponent } from '../cookies/Cookies.component';
import ModalViewComponent from '../modal-view/ModalView.component';
import { ScreenDesktop } from '../screens/ScreenDesktop.component';
import { ScreenDimensions } from '../screens/ScreenDimensions.component';
import DropdownMenuProfile from './components/dropdown-menu-profile/DropdownMenuProfile.component';
import HomeSearch from './components/home-search/HomeSearch.component';
import MenuComponent from './components/left-menu/components/mobile-menu/MobileMenu.component';

function HeaderComponent(): JSX.Element {
	const { appContextValue } = useContext(AppContext);
	const [toggleLeftMenu, setToggleLeftMenu] = useState(false);
	const [toggleProfileMenu, setToggleProfileMenu] = useState(false);
	const [toggleAuthPage, setToggleAuthPage] = useState(false);
	const [authView, setAuthView] = useState(AuthPageOptionsEnum.LoginView);
	const [headerLabels, setHeaderLabels] = useState(HeaderLabelsES);
	const { t } = useTranslation();

	const toggleLeftMenuMethod = (): void => {
		setToggleLeftMenu(!toggleLeftMenu);
	};

	const toggleProfileMenuMethod = (): void => {
		setToggleProfileMenu(!toggleProfileMenu);
	};
	const toggleAuthPageMethod = (): void => {
		setToggleAuthPage(!toggleAuthPage);
		if (!toggleAuthPage) {
			setAuthView(AuthPageOptionsEnum.LoginView);
		}
	};

	const searchHeaderRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (appContextValue.showLogin) {
			if (appContextValue?.authView) {
				setAuthView(appContextValue.authView);
			}
			setToggleAuthPage(true);
		}
	}, [appContextValue.showLogin]);

	useEffect(() => {
		if (
			appContextValue.currentPageConfiguration?.language.code ===
			defaultPageConfigs.language.code
		) {
			setHeaderLabels(HeaderLabelsES);
		} else {
			setHeaderLabels(HeaderLabelsEN);
		}
	}, [appContextValue.currentPageConfiguration]);

	return (
		<>
			<div className="header-component awake-bg-header-background">
				<GoogleOneTapLoginComponent />
				<nav className="navbar navbar-expand-lg awake-ht-48">
					<div className="container-fluid awake-ps-container awake-pe-container position-relative">
						<div className="awake-wt-per-100 d-flex justify-content-center align-items-center ">
							<ScreenDimensions mobile>
								<>
									{appContextValue.hasLogin ? (
										<>
											<li className="awake-button-header d-flex cursor-pointer-link awake-p-absolute awake-right-100 me-2">
												<HomeSearch></HomeSearch>
											</li>
											<li className="awake-button-header d-flex cursor-pointer-link awake-p-absolute awake-right-30">
												<DropdownMenuProfile
													items={headerLabels.rightMenu.items}
													icon={'icon-account_circle'}
													toggle={toggleLeftMenuMethod}
												/>
											</li>
										</>
									) : (
										<>
											<li className="awake-button-header d-flex cursor-pointer-link awake-p-absolute awake-right-100 me-2">
												<HomeSearch></HomeSearch>
											</li>
											<li className="awake-button-header d-flex cursor-pointer-link awake-p-absolute awake-right-10 awake-bg-white awake-ht-40 rounded pe-2 ps-2 awake-right-30">
												<a
													className="nav-link"
													href="#"
													role="button"
													onClick={() => toggleAuthPageMethod()}>
													<img
														className="awake-wt-25 mt-2 me-2"
														src={imgUnlogged}></img>
												</a>
												<a
													className="nav-link awake-mt-10"
													href="#"
													onClick={toggleLeftMenuMethod}>
													<span className="icon-dehaze accordion"></span>
												</a>
											</li>
										</>
									)}
									<RightMenuComponent
										toggleAuthPageMethod={() => toggleAuthPageMethod()}
										headerLabels={headerLabels}
									/>
								</>
							</ScreenDimensions>
							<ScreenDimensions tablet>
								<>
									<ul className="navbar-nav awake-p-absolute awake-right-60 d-flex align-items-center">
										<li className="awake-button-header d-flex cursor-pointer-link">
											<HomeSearch></HomeSearch>
										</li>

										{appContextValue.hasLogin ? (
											<>
												<DropdownMenuProfile
													items={headerLabels.rightMenu.items}
													icon={'icon-account_circle'}
													toggle={toggleLeftMenuMethod}
												/>
											</>
										) : (
											<>
												<li className="awake-button-header d-flex cursor-pointer-link  awake-bg-white awake-ht-40 rounded pe-2 ps-2 ">
													<a
														className="nav-link"
														href="#"
														role="button"
														onClick={() => toggleAuthPageMethod()}>
														<label className="awake-fw-700 cursor-pointer-link ms-2 ">
															{t('header.logIn')}
														</label>
														<img
															className="awake-wt-25 ms-2 me-2"
															src={imgUnlogged}></img>
													</a>
													<a
														className="nav-link awake-mt-2"
														href="#"
														onClick={toggleLeftMenuMethod}>
														<span className="icon-dehaze accordion"></span>
													</a>
												</li>
											</>
										)}
									</ul>

									<RightMenuComponent
										toggleAuthPageMethod={() => toggleAuthPageMethod()}
										headerLabels={headerLabels}
									/>
								</>
							</ScreenDimensions>
							<ScreenDesktop>
								<>
									<ul className="navbar-nav awake-p-absolute awake-right-60 d-flex align-items-center">
										<li className="awake-button-header d-flex cursor-pointer-link">
											<HomeSearch></HomeSearch>
										</li>

										{appContextValue.hasLogin ? (
											<>
												<DropdownMenuProfile
													items={headerLabels.rightMenu.items}
													icon={'icon-account_circle'}
													toggle={toggleLeftMenuMethod}
												/>
											</>
										) : (
											<>
												<li className="awake-button-header d-flex cursor-pointer-link  awake-bg-white awake-ht-40 rounded pe-2 ps-2 ">
													<a
														className="nav-link"
														href="#"
														role="button"
														onClick={() => toggleAuthPageMethod()}>
														<label className="awake-fw-700 cursor-pointer-link ms-2">
															{t('header.logIn')}
														</label>
														<img
															className="awake-wt-25 ms-2"
															src={imgUnlogged}></img>
													</a>
													<a
														className="nav-link"
														href="#"
														onClick={toggleLeftMenuMethod}>
														<span className="icon-dehaze accordion"></span>
													</a>
												</li>
											</>
										)}
									</ul>

									<RightMenuComponent
										toggleAuthPageMethod={() => toggleAuthPageMethod()}
										headerLabels={headerLabels}
									/>
								</>
							</ScreenDesktop>
						</div>
					</div>

					<MenuComponent
						headerLabels={headerLabels}
						show={toggleLeftMenu}
						onClosed={() => {
							setToggleLeftMenu(false);
						}}
						onOpenAuthView={view => {
							setToggleAuthPage(true);
							setAuthView(view);
						}}
					/>

					{getAuthSession() && (
						<ProfileMobileMenuComponent
							toggleProfileMenuMethod={toggleProfileMenuMethod}
							toggleProfileMenu={toggleProfileMenu}
							headerLabels={headerLabels}
						/>
					)}
				</nav>

				<div id="modalUser">
					<ModalViewComponent
						modalClassName="awake-modal__background"
						modalState={toggleAuthPage}
						setModalState={toggleAuthPageMethod}>
						<AuthPage
							authView={authView}
							setAuthView={setAuthView}
							toggleAuthPageMethod={toggleAuthPageMethod}
						/>
					</ModalViewComponent>
				</div>
			</div>

			<CookiesComponent></CookiesComponent>
		</>
	);
}

export default HeaderComponent;
