import { footerData } from '@footer/data/Footer.Data';
import type { FooterDataInterface } from '@footer/interfaces/FooterLabels.interface';
import { ScreenMobile } from '@shared/components/screens/ScreenMobile.component';
import { AppContext } from '@shared/context/App.context';
import { Route } from '@shared/enums/Routes.enum';
import { getRoute, useResponsive } from '@shared/services/utils/Utils.services';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
function FooterLeftComponent(): JSX.Element {
	const { appContextValue } = useContext(AppContext);
	const { isMobile } = useResponsive();
	const { t } = useTranslation();

	const exploreItems: FooterDataInterface[] = footerData.explore;
	const contactItems: { data: FooterDataInterface[]; phones: string[] } = footerData.contact;

	return (
		<div className="row">
			<div className="col-12 col-sm-3 col-xl-3">
				<div
					className="d-flex mb-3"
					data-bs-toggle={isMobile && 'collapse'}
					data-bs-target="#destinationsCollapse"
					aria-expanded="false"
					aria-controls="destinationsCollapse">
					<label className="awake-heading-5 awake-text-white awake-fw-700 awake-pb-5 footer-component__collapse-action border-bottom">
						{t('footer.destinations.title')}
					</label>
					<ScreenMobile>
						<>
							<span className="icon-expand_more footer-component__collapse-icon"></span>
							<span className="icon-expand_less footer-component__collapse-icon"></span>
						</>
					</ScreenMobile>
				</div>
				<div
					className={`${isMobile ? 'collapse pb-3' : 'gap-3 d-flex flex-column'}`}
					id="destinationsCollapse">
					{React.Children.toArray(
						appContextValue.destinationsInfo &&
							appContextValue.destinationsInfo!.map((destination) => (
								<Link
									to={destination.url}
									className="awake-heading-5 footer-component__collapse__text awake-text-white awake-cursor-pointer d-block no-underline py-2 py-sm-0">
									{destination.name}
								</Link>
							))
					)}
					<div className=" awake-heading-4">
						<Link
							className="awake-heading-5 footer-component__collapse__text awake-text-white awake-cursor-pointer d-block no-underline py-2 py-sm-0"
							to={getRoute({
								route: Route.Journey,
								params: { slug: t('journey.item.mobileMenu.url') }
							})}>
							🦋{t('journey.journey')}
						</Link>
					</div>
					<div className=" awake-heading-4">
						<Link
							className="awake-heading-5 footer-component__collapse__text awake-text-white awake-cursor-pointer d-block no-underline py-2 py-sm-0"
							to={getRoute({
								route: Route.WeLanding
							})}>
							{t('footer.we')}
						</Link>
					</div>
				</div>
			</div>
			<div className="col-12 col-sm-4 col-xl-4">
				<div
					className="d-flex mb-3"
					data-bs-toggle={isMobile && 'collapse'}
					data-bs-target="#exploreCollapse"
					aria-expanded="false"
					aria-controls="exploreCollapse">
					<label className="awake-heading-5 awake-text-white awake-fw-700 awake-pb-5 footer-component__collapse-action border-bottom">
						{t('footer.explore.title')}
					</label>
					<ScreenMobile>
						<>
							<span className="icon-expand_more footer-component__collapse-icon "></span>
							<span className="icon-expand_less footer-component__collapse-icon"></span>
						</>
					</ScreenMobile>
				</div>

				<div
					className={`${isMobile ? 'collapse pb-3' : 'gap-3 d-flex flex-column'}`}
					id="exploreCollapse">
					{React.Children.toArray(
						exploreItems.length &&
							exploreItems.map((explore) => (
								<>
									{explore.isRoute ? (
										<Link
											to={explore.href!}
											className="footer-component__collapse__text awake-heading-5 awake-text-white awake-cursor-pointer no-underline d-block py-2 py-sm-0">
											{t(`footer.explore.${explore.text}`)}
										</Link>
									) : (
										<a
											href={explore.href}
											target="_blank"
											rel="noreferrer"
											className="footer-component__collapse__text awake-heading-5 awake-text-white awake-cursor-pointer no-underline d-block py-2 py-sm-0">
											{t(`footer.explore.${explore.text}`)}
										</a>
									)}
								</>
							))
					)}
				</div>
			</div>
			<div className="col-12 col-sm-5 col-xl-5">
				<div
					className="d-flex mb-3"
					data-bs-toggle={isMobile && 'collapse'}
					data-bs-target="#contactCollapse"
					aria-expanded="false"
					aria-controls="contactCollapse">
					<label className="awake-heading-5 awake-text-white awake-fw-700 awake-pb-5 footer-component__collapse-action border-bottom">
						{t('footer.contact.title')}
					</label>
					<ScreenMobile>
						<>
							<span className="icon-expand_more footer-component__collapse-icon "></span>
							<span className="icon-expand_less footer-component__collapse-icon"></span>
						</>
					</ScreenMobile>
				</div>

				<div
					className={`gap-3 ${isMobile ? 'collapse' : 'd-flex flex-column'}`}
					id="contactCollapse">
					<label className="awake-heading-5 awake-text-white  d-block py-2 py-sm-0">
						{t('footer.contact.schedule')}
					</label>
					<label className="awake-heading-5 awake-text-white  d-block py-2 py-sm-0">
						{t('footer.contact.general')} {contactItems.phones[0]}
						<br></br>
						{t('footer.contact.agency')} {contactItems.phones[1]}
						<br></br>
						{t('footer.contact.email')}{' '}
						<a
							href="mailto:info@awake.travel"
							className="awake-heading-5 awake-text-white no-underline">
							info@awake.travel
						</a>
					</label>
					{React.Children.toArray(
						contactItems.data!.map((contact) => (
							<>
								{contact.isRoute ? (
									<Link
										to={contact.href!}
										className="footer-component__collapse__text awake-heading-5 awake-text-white awake-cursor-pointer no-underline d-block py-2 py-sm-0">
										{t(`footer.contact.${contact.text}`)}
									</Link>
								) : (
									<a
										href={contact.href}
										target="_blank"
										rel="noreferrer"
										className="footer-component__collapse__text awake-heading-5 awake-text-white awake-cursor-pointer no-underline d-block py-2 py-sm-0">
										{t(`footer.contact.${contact.text}`)}
									</a>
								)}
							</>
						))
					)}
				</div>
			</div>
		</div>
	);
}

export default FooterLeftComponent;
