import { Route } from '@shared/enums/Routes.enum';
import { getRoute } from '@shared/services/utils/Utils.services';

export const footerData: any = {
    explore: [
        {
            text: 'us',
            href: 'https://awake.travel/files/19/Quienes%20somos%20pagina.pdf'
        },
        {
            text: 'manifest',
            href: 'https://awake.travel/files/19/Manifiesto%20pagina.pdf'
        },
        {
            text: 'becomeAHost',
            href: 'https://awake.travel/files/19/PDF%20Anfitriones%20pagina.pdf'
        },
        {
            text: 'awakeBio',
            href: 'https://awakebio.awake.travel/'
        },
        {
            text: 'awakeU',
            href: 'https://u.awake.travel/'
        },
        {
            text: 'workWithUs',
            href: 'https://destinos.awake.travel/ofertas_awake/'
        },
        {
            text: 'referrals',
            href: 'https://sia.awake.travel/files/19/Referidos%202023.pdf'
        },
        {
            text: 'giftCard',
            href: getRoute({ route: Route.GiftCard }),
            isRoute: true
        },
        {
            text: 'blog',
            href: 'https://awake.travel/blog'
        }
    ],
    contact: {
        data: [
            {
                text: 'register',
                href: 'https://awake.travel/files/shares/RNT.pdf'
            },
            {
                text: 'commonQuestions',
                href: 'https://awake.travel/files/19/Preguntas%20frecuentes%202023.pdf'
            },
            {
                text: 'termsAndConditions',
                href: 'https://awake.travel/terminosycondiciones'
            },
            {
                text: 'pqr',
                href: 'https://destinos.awake.travel/pqrs/'
            },
            {
                text: 'dataProcessing',
                href: getRoute({ route: Route.DataProcessing }),
                isRoute: true
            }
        ],
        phones: [
            `(+57) ${process.env.REACT_APP_AWAKE_MAIN_PHONE_NUMBER?.substring(2)}`,
            `(+57) ${process.env.REACT_APP_AWAKE_AGENCY_PHONE_NUMBER?.substring(2)}`
        ]
    }
}
