import { type CredentialResponse, useGoogleOneTapLogin } from '@react-oauth/google';
import { type ProfileInfoUserData } from '@root/modules/profile/interface/ProfileUserData.interface';
import { getUserProfile } from '@root/modules/profile/service/UserProfile.service';
import { AppContext } from '@shared/context/App.context';
import { type CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import { authUserWithGoogleTap } from '@shared/services/auth-service/Auth.service';
import { type AuthPageResponseDataInterface } from '@shared/services/auth-service/interfaces/AuthPageResult.interface';
import {
	getAuthSession,
	setAuthUser,
	setUserData
} from '@shared/services/storage/SessionStorage.service';
import { isACatchError } from '@shared/services/utils/Utils.services';
import { useContext, useEffect, useState } from 'react';
import { showToastError, showToastSuccess } from '@shared/services/toast-service/toast.service';
import ToastComponent from '@shared/components/toast/ToastIcon.component';
import { AuthLabelsEN, AuthLabelsES } from '@auth/labels/Auth.labels';
import { defaultPageConfigs } from '@shared/services/set-configs/defaultPageConfigs';

function ShowOneTapComponent(): JSX.Element {
	const { appContextValue, setAppContextMethod } = useContext(AppContext);
	const [authLabels, setAuthLabels] = useState(AuthLabelsES);

	useEffect(() => {
		if (
			appContextValue.currentPageConfiguration?.language.code ===
			defaultPageConfigs.language.code
		) {
			setAuthLabels(AuthLabelsES);
		} else {
			setAuthLabels(AuthLabelsEN);
		}
	}, [appContextValue.currentPageConfiguration]);

	const setSession = (): void => {
		getUserProfile()
			.then((result: CatchErrorInterface | ProfileInfoUserData) => {
				if (!isACatchError(result)) {
					setUserData(result.data);
					setAppContextMethod({
						hasLogin: true
					});
					showToastSuccess(
						<ToastComponent
							message={
								authLabels.loginSuccess +
								' ' +
								getAuthSession().username
							}></ToastComponent>,
						'top-right',
						false
					);
				} else {
					if (result.message.statusCode === 401) {
						showToastError(authLabels.userAlreadyExists);
					} else {
						console.error(result.error);
					}
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	useGoogleOneTapLogin({
		onSuccess: (response: CredentialResponse): void => {
			authUserWithGoogleTap(response.credential!)
				.then((result: CatchErrorInterface | AuthPageResponseDataInterface) => {
					if (!isACatchError(result)) {
						if (setAuthUser(result).success) {
							setSession();
						} else {
							showToastError('Error');
						}
					} else {
						showToastError(
							authLabels.smsErrorLoginWhasgtApp
						);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	});

	return <></>;
}

function GoogleOneTapLoginComponent(): JSX.Element {
	const { appContextValue } = useContext(AppContext);
	const [showLogin, setShowLogin] = useState<boolean>();
	const { setAppContextMethod } = useContext(AppContext);

	useEffect(() => {
		setShowLogin(!appContextValue.hasLogin);
		setAppContextMethod({
			showLoginGoogleOneTap: !appContextValue.hasLogin
		});
	}, [appContextValue.hasLogin]);

	return <>{showLogin && appContextValue.showLoginGoogleOneTap && <ShowOneTapComponent />}</>;
}

export default GoogleOneTapLoginComponent;
