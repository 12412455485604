import { useResponsive } from '@shared/services/utils/Utils.services';
import { useTranslation } from 'react-i18next';

const ManifestProposedComponent = ({
	title,
	img,
	list,
	children
}: {
	title: string;
	img: string;
	list: Array<{ description: string }>; // Ajustar la tipificación
	children: JSX.Element;
}): JSX.Element => {
	const { isMobile, isTablet, isDesktop } = useResponsive();
	const { t } = useTranslation();

	const titleManifest = t(title);
	const listManifest = list.map((d) => t(d.description));

	return (
		<>
			{!isDesktop && (
				<div>
					<h1
						className="text-center mt-4 awake-heading-1 awake-fw-900 mb-4"
						dangerouslySetInnerHTML={{ __html: titleManifest }}></h1>
					<div className="d-flex justify-content-center mb-4">
						<img src={img} alt="" className="w-75" />
					</div>

					<ul>
						{listManifest.map((d, index) => (
							<li
								key={index}
								dangerouslySetInnerHTML={{ __html: d }}
								className="m-2"></li>
						))}
					</ul>
					{children}
				</div>
			)}
			{isDesktop && (
				<div className="row d-flex justify-content-center m-4">
					<div className="col-6">
						<h1
							className="text-center mt-4 awake-heading-1 awake-fw-900 mb-4"
							dangerouslySetInnerHTML={{ __html: titleManifest }}></h1>
						<ul>
							{listManifest.map((d, index) => (
								<li key={index} dangerouslySetInnerHTML={{ __html: d }}></li>
							))}
						</ul>
						{children}
					</div>
					<div className="col-5">
						<div className="d-flex justify-content-center mb-4">
							<img src={img} alt="" className="w-75" />
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ManifestProposedComponent;