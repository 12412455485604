import Skeleton from 'react-loading-skeleton';

export const JourneyPageSkeleton = (): JSX.Element => {
  return (
    <>
      <div className="journey-skeleton">
        <Skeleton />
      </div>
    </>
  );
};