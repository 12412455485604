import type { DropdownMenuProfilePropsInterface } from '@header/interfaces/DropdownMenuPropsInterface';
import { AppContext } from '@shared/context/App.context';
import { useContext } from 'react';
import type { MenuItem } from '@shared/interfaces/ProfileMenuLabels.interface';
import { getAuthSession } from '@shared/services/storage/SessionStorage.service';
import { Link } from 'react-router-dom';
import { getRoute, useResponsive } from '@shared/services/utils/Utils.services';
import '@header/Header.component.scss';
import imgLogin from '@assets/header/login.png';

function DropdownMenuProfile(props: DropdownMenuProfilePropsInterface): JSX.Element {
	const { appContextValue } = useContext(AppContext);
	const { isMobile } = useResponsive();
	return (
		<>
			<li className="awake-button-header d-flex cursor-pointer-link awake-bg-white awake-ht-40 rounded pe-2">
				<a
					onClick={props.toggle}
					className={
						isMobile
							? 'nav-link dropdown-toggle header-component-icon mt-2'
							: 'nav-link dropdown-toggle header-component-icon'
					}
					href="#"
					role="button"
					aria-expanded="false">
					{!isMobile ? (
						<label className="awake-fw-700 cursor-pointer-link me-0">
							{getAuthSession().username}
						</label>
					) : (
						''
					)}
					{props.icon && <img className="awake-wt-25 me-1 ms-2" src={imgLogin}></img>}
				</a>
				<a
					className={isMobile ? 'nav-link awake-mt-10' : 'nav-link awake-mt-2'}
					href="#"
					onClick={props.toggle}>
					<span className="icon-dehaze accordion"></span>
				</a>

				<ul className="dropdown-menu dropdown-menu-end ps-4 position-absolute">
					<li>
						<div className="dropdown-item dropdown-menu__item dropdown-menu__profile awake-pt-22 awake-pb-22">
							<div className="row">
								<div className="col-7">
									<div className="text-end">
										<span className="dropdown-menu__item__icon icon-account_circle"></span>
									</div>
								</div>
								<div className="col-5">
									<span className="icon-close float-end"></span>
								</div>
							</div>
							<div className="row">
								<div className="col-auto awake-ms-auto awake-me-auto">
									<label className="awake-heading-5 awake-fw-700 awake-text-secondary awake-mb-0">
										{getAuthSession().username}
									</label>
								</div>
							</div>
						</div>
					</li>
					{props.items.map((item: MenuItem, index: number) => (
						<li key={index}>
							<Link
								to={item.url ? getRoute({ route: item.url }) : ''}
								className="dropdown-item dropdown-menu__item awake-pt-18 awake-pb-18 awake-px-50"
								onClick={() => (!item.url ? appContextValue.logout!() : '')}>
								<div className="row align-items-center">
									<div className="col-auto d-inline-flex">
										<span
											className={`dropdown-menu__item__icon awake-fw-700 ${item.icon}`}></span>
									</div>
									<div className="col-auto">
										<label className="awake-heading-5 awake-text-secondary awake-mb-0 dropdown-menu__item__text">
											{item.text}
										</label>
									</div>
								</div>
							</Link>
						</li>
					))}
				</ul>
			</li>
		</>
	);
}

export default DropdownMenuProfile;
