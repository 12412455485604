export enum ProfileAPIsEnum {
    UserProfileInfoAPIUrl = 'users/profile',
    UserRequestBookingsAPIUrl = 'request-bookings',
    UserOrdersAPIUrl = 'orders',
    UserNotificationsAPIUrl = 'users/notifications',
    UserMarkAsViewedNotificationAPIUrl = 'users/notifications/:id/toggle-viewed',
    UserGetWishListAPIUrl = 'users/wish-list',
    UserChangePasswordAPIUrl = 'users/password/change',
    UserAccountDeleteUrl = 'users/profile',
    UserToggleNotificationUrl = 'users/notifications/{id}/toggle-viewed',
    UserProfileReferralsAPIUrl = 'users/referrals',
}
