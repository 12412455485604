import {type PageConfigurationInterface } from '@shared/interfaces/PageConfiguration.interface';
import {type CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import {type RequestInterface } from '@shared/services/request/interfaces/Request.interface';
import { JoinTheGroupAPIsEnum } from '../enums/JoinTheGroupAPIs.enum';
import { sendRequest } from '@shared/services/request/Request.service';
import {type JoinGroupProps } from '../interfaces/JoinTheGroupInfo.interface';

export const getGropusInfo: (
	groups: string,
	currentPageConfiguration?: PageConfigurationInterface
) => Promise<JoinGroupProps | CatchErrorInterface> = async (
	destination: string,
	currentPageConfiguration?: PageConfigurationInterface
): Promise<JoinGroupProps | CatchErrorInterface> => {
	const data: RequestInterface = {
		url: JoinTheGroupAPIsEnum.GroupsFilter,
		method: 'GET',
		currentPageConfiguration
	};
	return await sendRequest(data);
};