import type { BaseScreen } from '@shared/interfaces/Screen.interface';
import { useResponsive } from '@shared/services/utils/Utils.services';
import { ScreenBase } from './ScreenBase.component';

export function ScreenDesktop({
  ...base
}: BaseScreen): JSX.Element {
  const { isDesktop } = useResponsive();

  return (
    <>
      {isDesktop && <ScreenBase {...base}></ScreenBase>}
    </>
  );
}