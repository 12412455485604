import { type Route } from '@shared/enums/Routes.enum';

export enum MENU_ITEM_ID {
    EDIT = 1,
    RESERVATIONS = 2,
    NOTIFICATIONS = 3,
    WISH_LIST = 4,
    REFERRALS = 5,
    LOGOUT = 6
}

export interface MenuItem {
    id: MENU_ITEM_ID;
    icon: string;
    text: string;
    textMobile: string;
    url?: Route;
}
