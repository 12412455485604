import awakeBrand from '@assets/common/awake-brand-green.svg';
import '@auth/components/login/Login.component.scss';
import { useRef, useState, useContext, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import type { AuthComponentPropsInterface } from '@auth/interfaces/AuthComponentProps.interface';
import { useFormik } from 'formik';
import type { LoginFormInterface } from '@auth/components/login/interfaces/LoginForm.interface';
import { loginFormValidations } from '@auth/components/login/validations/LoginForm.validations';
import { AuthPageOptionsEnum } from '@auth/enums/AuthPageOptions.enum';
import {
	authUserWithGoogle,
	authUserWithPasswordAndEmail
} from '@shared/services/auth-service/Auth.service';
import type { CatchErrorInterface } from '@shared/interfaces/CatchError.interface';
import { isACatchError } from '@shared/services/utils/Utils.services';
import {
	getAuthSession,
	setAuthUser,
	setUserData
} from '@shared/services/storage/SessionStorage.service';
import { showToastError, showToastSuccess } from '@shared/services/toast-service/toast.service';
import ToastComponent from '@shared/components/toast/ToastIcon.component';
import { AppContext } from '@shared/context/App.context';
import {
	ExperienceCardLabelsEN,
	ExperienceCardLabelsES
} from '@shared/components/experience-card/labels/ExperienceCard.labels';
import { defaultPageConfigs } from '@shared/services/set-configs/defaultPageConfigs';
import ReCAPTCHA from 'react-google-recaptcha';
import { getUserProfile } from '@profile/service/UserProfile.service';
import type { ProfileInfoUserData } from '@profile/interface/ProfileUserData.interface';
import type { AuthPageResponseDataInterface } from '@shared/services/auth-service/interfaces/AuthPageResult.interface';
import { useGoogleLogin } from '@react-oauth/google';

function LoginComponent(props: AuthComponentPropsInterface): JSX.Element {
	const [savePassword, setSavePassword] = useState<boolean>(false);
	const { appContextValue, setAppContextMethod } = useContext(AppContext);
	const [experienceCardLabels, setExperienceCardLabels] = useState(ExperienceCardLabelsES);
	const recaptchaLoginRef = useRef<ReCAPTCHA>(null);

	const verifyFavorite = (): void => {
		if (appContextValue.lastFavoriteExperienceModifiedTemp) {
			setAppContextMethod({
				showLogin: false,
				lastFavoriteExperienceModified: appContextValue.lastFavoriteExperienceModifiedTemp,
				lastFavoriteExperienceModifiedTemp: undefined
			});
			showToastSuccess(experienceCardLabels.wishList.added);
		}
	};

	const googleLogin = useGoogleLogin({
		flow: 'auth-code',
		onSuccess: async (tokenResponse) => {
			authUserWithGoogle(tokenResponse.code)
				.then((result: CatchErrorInterface | AuthPageResponseDataInterface) => {
					if (!isACatchError(result)) {
						if (setAuthUser(result).success) {
							getUserProfile()
								.then((result: CatchErrorInterface | ProfileInfoUserData) => {
									if (!isACatchError(result)) {
										setUserData(result.data);
										showToastSuccess(
											<ToastComponent
												message={
													props.authLabels.loginSuccess +
													' ' +
													getAuthSession().username
												}></ToastComponent>,
											'top-right',
											false
										);
										setAppContextMethod({
											showLogin: false,
											hasLogin: true
										});
									} else {
										if (result.message.statusCode === 401) {
											showToastError(props.authLabels.userAlreadyExists);
										} else {
											console.error(result.error);
										}
									}
								})
								.catch((error) => {
									console.error(error);
								});
							props.toggleAuthPageMethod();
							verifyFavorite();
						} else {
							showToastError('Error');
						}
					} else {
						if (result.message.statusCode === 401) {
							showToastError(props.authLabels.userAlreadyExists);
						} else {
							console.error(result.error);
						}
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	});

	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
			'g-recaptcha-response': ''
		},
		validationSchema: loginFormValidations(props.formValidationsLabels),
		onSubmit: async (values: LoginFormInterface) => {
			await recaptchaLoginRef.current?.executeAsync();
			authUserWithPasswordAndEmail(values)
				.then((result: CatchErrorInterface | AuthPageResponseDataInterface) => {
					if (!isACatchError(result)) {
						if (setAuthUser(result).success) {
							getUserProfile()
								.then((result: CatchErrorInterface | ProfileInfoUserData) => {
									if (!isACatchError(result)) {
										setUserData(result.data);
										setAppContextMethod({
											showLogin: false,
											hasLogin: true
										});
										showToastSuccess(
											<ToastComponent
												message={
													props.authLabels.loginSuccess +
													' ' +
													getAuthSession().username
												}></ToastComponent>,
											'top-right',
											false
										);
									} else {
										console.error(result.error);
									}
								})
								.catch((error) => {
									console.error(error);
								});
							props.toggleAuthPageMethod();
							verifyFavorite();
						} else {
							showToastError('Error');
						}
					} else {
						showToastError(result.message.message);
						formik.resetForm();
					}
					recaptchaLoginRef.current?.reset();
				})
				.catch((error) => {
					console.error(error);
				});
		}
	});

	useEffect(() => {
		if (
			appContextValue.currentPageConfiguration?.language.code ===
			defaultPageConfigs.language.code
		) {
			setExperienceCardLabels(ExperienceCardLabelsES);
		} else {
			setExperienceCardLabels(ExperienceCardLabelsEN);
		}
	}, [appContextValue.currentPageConfiguration]);

	return (
		<div className="login-component container-fluid awake-ps-container awake-pe-container">
			<Form onSubmit={formik.handleSubmit}>
				<div className="row">
					<div className="col-12 text-center">
						<img
							className="login-component__logo"
							src={awakeBrand}
							alt="awake-logo"></img>
					</div>
				</div>
				<div className="row awake-mt-10">
					<div className="col-12 text-center">
						<label className="awake-heading-3 awake-fw-700">
							{props.authLabels.title}
						</label>
					</div>
				</div>
				<div className="row awake-mt-10">
					<div className="col-12">
						<label className="awake-heading-4">{props.authLabels.subtitle}</label>
					</div>
				</div>
				<div className="row awake-mt-20">
					<div
						className={
							'col-12 col-lg-6 col-xl-6 ' +
							(window.innerWidth < 1200 ? 'awake-mb-20' : '')
						}>
						<button
							type="button"
							className={
								'awake-google-button awake-fw-700 awake-d-block awake-ps-20 awake-wt-276 awake-ht-57 ' +
								(window.innerWidth < 1200
									? 'awake-ms-auto awake-me-auto'
									: 'awake-ms-auto')
							}
							onClick={() => {
								googleLogin();
							}}>
							<a className="icon-google awake-link-icon awake-me-5"></a>
							<label className="awake-heading-5 awake-fw-700 awake-cursor-pointer">
								{props.authLabels.startWithGoogle}
							</label>
						</button>
					</div>
				</div>
				<div className="row awake-mt-40">
					<div
						className={
							'col-12 col-lg-6 col-xl-6 ' +
							(window.innerWidth < 1200 ? 'awake-mb-30' : '')
						}>
						<div
							className={`${
								formik.touched.username && formik.errors.username
									? 'invalid'
									: formik.values.username !== ''
									? 'valid'
									: 'awake-input__black'
							} ${
								window.innerWidth > 1200
									? 'float-end'
									: ' awake-ms-auto awake-me-auto'
							} awake-wt-276 awake-input`}>
							<label htmlFor="username" className="awake-input__label awake-bg-white">
								<span className="icon-account_circle awake-input__label__icon awake-link-icon awake-me-10"></span>
								<span className="awake-text-complementary">
									{props.authLabels.email}
								</span>
							</label>
							<input
								type="email"
								name="username"
								className="awake-ht-55 awake-input__input awake-bg-white"
								placeholder={props.authLabels.emailPlaceholder}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.username}></input>
							{formik.touched.username && formik.errors.username ? (
								<div className="invalid-message">{formik.errors.username}</div>
							) : null}
						</div>
					</div>
					<div
						className={
							'col-12 col-lg-6 col-xl-6 ' +
							(window.innerWidth < 1200 ? 'awake-mb-10' : '')
						}>
						<div
							className={`${
								formik.touched.password && formik.errors.password
									? 'invalid'
									: formik.values.password !== ''
									? 'valid'
									: 'awake-input__black'
							} ${
								window.innerWidth > 1200 ? '' : ' awake-ms-auto awake-me-auto'
							} awake-wt-276 awake-input`}>
							<label htmlFor="name" className="awake-input__label awake-bg-white">
								<span className="icon-edit awake-input__label__icon awake-link-icon awake-me-10"></span>
								<span className="awake-text-complementary">
									{props.authLabels.password}
								</span>
							</label>
							<input
								type="password"
								name="password"
								className="awake-ht-55 awake-input__input awake-bg-white"
								placeholder={props.authLabels.passwordPlaceholder}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.password}></input>
							{formik.touched.password && formik.errors.password ? (
								<div className="invalid-message">{formik.errors.password}</div>
							) : null}
						</div>
					</div>
				</div>
				<div className="row awake-mt-40">
					<div className="col-auto awake-ms-auto awake-me-auto">
						<div>
							<ReCAPTCHA
								id="login-captcha"
								ref={recaptchaLoginRef}
								sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY!}
								onChange={(value) => {
									formik.values['g-recaptcha-response'] = value ?? '';
									void formik.setFieldTouched('g-recaptcha-response');
								}}
								size="invisible"
							/>
						</div>
					</div>
				</div>
				<div className="row awake-mt-30" hidden={window.innerWidth < 1200}>
					<div className="col-6">
						<label
							onClick={() => props.setAuthViewMethod(AuthPageOptionsEnum.RecoverView)}
							className="awake-heading-5 awake-fw-700 awake-text-primary awake-ms-auto awake-wt-276 awake-d-block awake-cursor-pointer">
							{props.authLabels.forgotPassword}
						</label>
					</div>
					<div className="col-6">
						<Form.Check
							className="login-component__checkbox awake-d-inline-block awake-me-5"
							type={'checkbox'}
							id={'checkbox-save-password'}
							checked={savePassword}
							onChange={(e) => {
								setSavePassword(!savePassword);
							}}
						/>
						<label className="awake-heading-5 awake-ms-10">
							{props.authLabels.rememberPassword}
						</label>
					</div>
				</div>
				<div className="row awake-mt-20" hidden={window.innerWidth > 1200}>
					<div className="col-12 text-center">
						<Form.Check
							className="login-component__checkbox awake-d-inline-block awake-me-5"
							type={'checkbox'}
							id={'checkbox-save-password'}
							checked={savePassword}
							onChange={(e) => {
								setSavePassword(!savePassword);
							}}
						/>
						<label className="awake-heading-5 awake-ms-10 awake-cursor-pointer">
							{props.authLabels.rememberPassword}
						</label>
					</div>
				</div>
				<div className="row awake-mb-10" hidden={window.innerWidth > 1200}>
					<div className={`col-12 ${window.innerWidth < 768 ? 'awake-mt-10' : ''}`}>
						<label
							onClick={() => props.setAuthViewMethod(AuthPageOptionsEnum.RecoverView)}
							className={
								'awake-heading-5 awake-fw-700 awake-text-primary  awake-d-block awake-cursor-pointer' +
								(window.innerWidth < 1200
									? ' text-center'
									: ' awake-ms-auto awake-wt-276')
							}>
							{props.authLabels.forgotPassword}
						</label>
					</div>
				</div>
				<div className="row">
					<div
						className={`col-12 col-lg-6 col-xl-6 ${
							window.innerWidth < 768 ? 'awake-mt-10' : ''
						}`}>
						<label
							onClick={() =>
								props.setAuthViewMethod(AuthPageOptionsEnum.RegisterView)
							}
							className={
								'awake-heading-5 awake-fw-700 awake-text-primary  awake-d-block awake-cursor-pointer' +
								(window.innerWidth < 1200
									? ' text-center'
									: ' awake-ms-auto awake-wt-276')
							}>
							{props.authLabels.createAccountOption}
						</label>
					</div>
				</div>
				<div className="row awake-mt-10 awake-mb-20">
					<div className="col-12 text-center">
						<button
							id="btn-login"
							disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
							type="submit"
							className={
								'awake-secondary-button awake-fw-700 awake-py-15 awake-px-32 awake-mt-10'
							}>
							{props.authLabels.nextButtonLabel}
						</button>
					</div>
				</div>
			</Form>
		</div>
	);
}

export default LoginComponent;
