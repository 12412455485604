import type { MobileMenuItemPropsInterface } from '@header/interfaces/MobileMenuItemProps.interface';
import type { CurrencyInfoDataInterface } from '@shared/interfaces/Currency.interface';
import type { LanguagesInfoDataInterface } from '@shared/interfaces/Languages.interface';
import { useResponsive } from '@shared/services/utils/Utils.services';

function MobileMenuCollapseComponent(props: MobileMenuItemPropsInterface): JSX.Element {
    const {isMobile}=useResponsive()
    return (
        <div className='col-12'>
            <div
                className='row g-0 mobile-menu-component__item'
                data-bs-toggle='collapse'
                data-bs-target={`#${props.menuName}`}
                aria-expanded='false'>
                <div className='col-auto'>
                    <span
                        className={`${props.icon}  d-flex align-items-center ${isMobile ? 'mobile-menu-component__icon-mobile':''}`}></span>
                </div>
                <div className='col'>
                    <h3 className={isMobile ? 'awake-cursor-pointer awake-heading-5 awake-fw-700 awake-text-secondary awake-mb-0 awake-ms-20 awake-text-white':' awake-heading-5 awake-fw-700 awake-text-secondary awake-mb-0 awake-ms-20 awake-cursor-pointer'}>
                        {props.text}
                    </h3>
                </div>
            </div>
            <div className='col-12 collapse' id={`${props.menuName}`}>
                {props.menu?.map(
                    (
                        item: LanguagesInfoDataInterface | CurrencyInfoDataInterface,
                        index2: number
                    ) => (
                        <div
                            className='row g-0 awake-bg-white mobile-menu-component__item mobile-menu-component__item__submenu d-flex align-items-center'
                            key={index2}
                            onClick={() => props.buttonMethod(item)}>
                            <div className='col-auto'>
                                <span className='mobile-menu-component__item__submenu__icon icon-brightness d-flex align-items-center'></span>
                            </div>
                            <div className='col'>
                                <h5 className='mobile-menu-component__item__submenu__text awake-heading-5 awake-text-secondary awake-mb-0 awake-ms-20'>
                                    {item.name}
                                </h5>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
}

export default MobileMenuCollapseComponent;
