import imgLogin from '@assets/header/login.png';
import { AuthPageOptionsEnum } from '@auth/enums/AuthPageOptions.enum';
import { FooterAPIsEnum } from '@footer/enums/FooterAPIs.enum';
import '@header/components/left-menu/components/mobile-menu/MobileMenu.component.scss';
import MobileMenuCollapseWithChildComponent from '@header/components/left-menu/components/mobile-menu/components/mobile-menu-collapse-with-child/MobileMenuCollapseWithChild.component';
import MobileMenuCollapseComponent from '@header/components/left-menu/components/mobile-menu/components/mobile-menu-collapse/MobileMenuCollapse.component';
import type { HeaderLabelsLeftMenuDataInterface } from '@header/interfaces/HeaderLabels.interface';
import type { LeftMenuPropsInterface } from '@header/interfaces/LeftMenuProps.interface';
import OffCanvasComponent from '@shared/components/off-canvas/OffCanvas.component';
import { AppContext } from '@shared/context/App.context';
import { Route } from '@shared/enums/Routes.enum';
import { type CategoriesInfoDataInterface } from '@shared/interfaces/CategoriesInfo.interface';
import { getAuthSession } from '@shared/services/storage/SessionStorage.service';
import { getRoute, useResponsive } from '@shared/services/utils/Utils.services';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHref, useNavigate } from 'react-router-dom';

function MenuComponent(props: LeftMenuPropsInterface): JSX.Element {
	const { appContextValue, setLanguage, setCurrency } = useContext(AppContext);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { isMobile } = useResponsive();

	const experienceTypeMenuOptions: Array<Pick<CategoriesInfoDataInterface, 'name' | 'url'>> = [
		...(appContextValue.categoriesInfo ? appContextValue.categoriesInfo : []),
		{
			name: t('home.pricelessExperiences.title'),
			url: useHref(FooterAPIsEnum.ulrPriceless)
		}
	];

	const handleClose = (): void => {
		if (props.onClosed) {
			props.onClosed();
		}
	};

	const handleAction = (event: React.MouseEvent<HTMLElement>, action: () => void): void => {
		event.preventDefault();
		action();
		handleClose();
	};

	const handleOpenAuthView = (
		event: React.MouseEvent<HTMLElement>,
		view: AuthPageOptionsEnum
	): void => {
		handleAction(event, () => props.onOpenAuthView(view));
	};

	const goToProfile = (event: React.MouseEvent<HTMLElement>): void => {
		handleAction(event, () => navigate(getRoute({ route: Route.ProfileUpdate })));
	};

	const goToBookings = (event: React.MouseEvent<HTMLElement>): void => {
		handleAction(event, () => navigate(getRoute({ route: Route.ProfileBookings })));
	};

	const menuItemsWithNew = appContextValue.destinationsInfo
		? [
			...appContextValue.destinationsInfo,
			{
				children: [
					{
						name: t('journey.item.mobileMenu.childrenName'),
						url: getRoute({
							route: Route.Journey, params: {
								slug: t('journey.item.mobileMenu.url')
							}
						}),
						image: '',
						children: []
					}
				],
				name: t('journey.item.mobileMenu.name'),
				url: '',
				image: ''
			}
		]
		: [];
	return (
		<OffCanvasComponent show={props.show} setShow={() => { handleClose() }}
			title={t('header.modal.title')}
			icon='icon-search'
			customClass='header-menu'
			header={false}
		>
			<div
				className={`${isMobile
					? 'w-100 h-100 awake-bg-secondary'
					: 'awake-bg-white h-100'} mobile-menu-component`}>
				<div className="awake-bg-blue-clear">
					<div className="row g-0 position-relative">
						{appContextValue.hasLogin ? (
							<>
								<div
									className={
										isMobile
											? 'col-1 awake-m-20 mt-4 me-4'
											: 'col-1 awake-ms-50 mt-4 ms-4'
									}>
									<img className="awake-wt-45" src={imgLogin}></img>
								</div>
								<div className={isMobile ? 'col-3 mt-4 ms-1' : 'col-3 mt-4 ms-3'}>
									<h4 className="awake-fw-700">
										{getAuthSession().username}
										<Link
											to="#"
											onClick={goToProfile}
											className="text-decoration-none">
											<h6
												className={
													isMobile
														? 'awake-heading-6 awake-text-secondary text-wrap mt-0 mb-0'
														: 'awake-heading-6 awake-text-secondary text-wrap mt-0 mb-0'
												}>
												{t('header.menuLogin.profile')}
												<span className="icon-chevron_right ms-2"></span>
											</h6>
										</Link>
									</h4>
								</div>
								<div className="col-5 mt-4 mb-4 d-flex justify-content-end">
									<div
										className={
											isMobile
												? 'awake-fw-700 rounded awake-primary-button p-2 w-100 text-center '
												: 'awake-fw-700 rounded awake-primary-button awake-pt-12 w-100 text-center ms-4'
										}>
										<Link
											to="#"
											onClick={goToBookings}
											className={`text-decoration-none awake-text-secondary d-flex justify-content-center align-items-center ${isMobile ? 'mt-1' : 'mt-0'
												}`}>
											{t('header.menuLogin.button')}
										</Link>
									</div>
								</div>
								<div className="col-1">
									<span
										className="icon-close m-4 position-absolute top-0 end-0 cursor-pointer-link"
										onClick={handleClose}></span>
								</div>
							</>
						) : (
							<>
								<div className="col-11 ms-4 awake-mt-40">
									<label className="awake-fw-700 awake-heading-4">
										{t('header.menu.title')}
									</label>
								</div>
								<div className="col-11 ms-4">
									<label className="awake-heading-6">
										{t('header.menu.subTitle')}
									</label>
								</div>
								<div className="row g-0 d-flex justify-content-center pt-4 pb-4">
									<div
										className={
											isMobile
												? 'col-5 p-2 awake-have-account-button cursor-pointer-link text-center awake-bg-primary '
												: 'col-5 p-2 awake-have-account-button cursor-pointer-link text-center awake-bg-primary '
										}
										onClick={(e) =>
											handleOpenAuthView(e, AuthPageOptionsEnum.RegisterView)
										}>
										<span className="icon-create-count icon-account_circle">
											{' '}
											<label
												className={
													isMobile
														? 'awake-heading-6 awake-fw-700 cursor-pointer-link'
														: 'awake-heading-6 awake-fw-700 cursor-pointer-link'
												}>
												{t('header.menu.btnCreateCount')}
											</label>
										</span>
									</div>

									<div
										className="col-5 p-2 awake-have-account-button cursor-pointer-link text-center ms-3"
										onClick={(e) =>
											handleOpenAuthView(e, AuthPageOptionsEnum.LoginView)
										}>
										<label className="awake-heading-6 awake-fw-700 cursor-pointer-link">
											{t('header.menu.btnCount')}
										</label>
									</div>
								</div>
								<div className="col-1">
									<span
										className="icon-close m-4 position-absolute top-0 end-0 cursor-pointer-link"
										onClick={handleClose}></span>
								</div>
							</>
						)}
					</div>
				</div>

				<div className="row g-0 awake-mt-28 awake-mb-70 awake-mb-150">
					<React.Fragment>
						<MobileMenuCollapseWithChildComponent
							toggleLeftMenuMethod={handleClose}
							menuName="parent-0"
							text={props.headerLabels.leftMenu.destinations.title}
							icon={props.headerLabels.leftMenu.destinations.icon}
							menu={menuItemsWithNew}
							showSubmenuMobile={true}
							background="awake-bg-secondary-2"
							hasSubmenu={true}
						/>

						<MobileMenuCollapseWithChildComponent
							toggleLeftMenuMethod={handleClose}
							menuName="parent-1"
							text={props.headerLabels.leftMenu.experiences.title}
							icon={props.headerLabels.leftMenu.experiences.icon}
							menu={experienceTypeMenuOptions}
							showSubmenuMobile={true}
							hasSubmenu={false}
						/>
						{props.headerLabels.leftMenu.data.map(
							(menu: HeaderLabelsLeftMenuDataInterface, index: number) => (
								<React.Fragment key={`parent-${index}`}>
									{menu.showMobile && (
										<MobileMenuCollapseWithChildComponent
											toggleLeftMenuMethod={handleClose}
											menuName={`parent-${index + 2}`}
											text={menu.title}
											icon={menu.icon}
											menu={menu.data}
											href={menu.href}
											isRoute={menu.isRoute}
											showSubmenuMobile={menu.showSubmenuMobile}
										/>
									)}
								</React.Fragment>
							)
						)}
					</React.Fragment>

					{/** button and search */}

					<div className="col-12">
						<div className="row g-0 justify-content-center awake-mt-47">
							<div className="col-auto">
								<Link
									to={getRoute({ route: Route.Filters })}
									onClick={handleClose}
									className="awake-primary-button awake-fw-700 awake-ms-auto awake-me-auto awake-d-block awake-px-20 no-underline d-flex align-items-center">
									{props.headerLabels.leftMenu.mobile.buttons.experience}
								</Link>
							</div>
							<hr className="awake-text-white awake-mt-30 awake-b-1"></hr>
						</div>
					</div>
					{/** lang */}
					<MobileMenuCollapseComponent
						menuName="langs"
						text={appContextValue.currentPageConfiguration?.language.name}
						icon="icon-emoji_flags"
						menu={appContextValue.languagesInfo ? appContextValue.languagesInfo : []}
						buttonMethod={setLanguage}
					/>

					{/** currency */}
					<MobileMenuCollapseComponent
						menuName="currency"
						text={appContextValue.currentPageConfiguration?.currency.name}
						icon="icon-attach_money"
						menu={appContextValue.currenciesInfo ? appContextValue.currenciesInfo : []}
						buttonMethod={setCurrency}
					/>
				</div>
			</div>
		</OffCanvasComponent>
	);
}

export default MenuComponent;
