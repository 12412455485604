import { Route } from '@shared/enums/Routes.enum';
import { MENU_ITEM_ID, type MenuItem } from '@shared/interfaces/ProfileMenuLabels.interface';

export const MenuItemsES: MenuItem[] = [
    {
        id: MENU_ITEM_ID.EDIT,
        icon: 'icon-edit',
        text: 'Editar perfil',
        textMobile: 'Editar',
        url: Route.ProfileUpdate
    },
    {
        id: MENU_ITEM_ID.RESERVATIONS,
        icon: 'icon-shopping_cart',
        text: 'Mis reservas',
        textMobile: 'Reservas',
        url: Route.ProfileBookings
    },
    {
        id: MENU_ITEM_ID.NOTIFICATIONS,
        icon: 'icon-notifications',
        text: 'Notificaciones',
        textMobile: 'Notificaciones',
        url: Route.ProfileNotifications
    },
    {
        id: MENU_ITEM_ID.WISH_LIST,
        icon: 'icon-heart_plus',
        text: 'Experiencias guardadas',
        textMobile: 'Guardadas',
        url: Route.ProfileWishList
    },
    {
        id: MENU_ITEM_ID.REFERRALS,
        icon: 'icon-record_voice_over',
        text: 'Referidos',
        textMobile: 'Referidos',
        url: Route.ProfileReferrals
    },
    {
        id: MENU_ITEM_ID.LOGOUT,
        icon: 'icon-logout',
        text: 'Cerrar sesión',
        textMobile: 'Cerrar'
    }
];

export const MenuItemsEN: MenuItem[] = [
    {
        id: MENU_ITEM_ID.EDIT,
        icon: 'icon-edit',
        text: 'Edit profile',
        textMobile: 'Edit profile',
        url: Route.ProfileUpdate
    },
    {
        id: MENU_ITEM_ID.RESERVATIONS,
        icon: 'icon-shopping_cart',
        text: 'Bookings',
        textMobile: 'Bookings',
        url: Route.ProfileBookings
    },
    {
        id: MENU_ITEM_ID.NOTIFICATIONS,
        icon: 'icon-notifications',
        text: 'Notifications',
        textMobile: 'Notifications',
        url: Route.ProfileNotifications
    },
    {
        id: MENU_ITEM_ID.WISH_LIST,
        icon: 'icon-heart_plus',
        text: 'Saved',
        textMobile: 'Saved',
        url: Route.ProfileWishList
    },
    {
        id: MENU_ITEM_ID.REFERRALS,
        icon: 'icon-record_voice_over',
        text: 'Referrals',
        textMobile: 'Referrals',
        url: Route.ProfileReferrals
    },
    {
        id: MENU_ITEM_ID.LOGOUT,
        icon: 'icon-logout',
        text: 'Logout',
        textMobile: 'Logout'
    }
];
