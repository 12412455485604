import type { FilterLabelsInterface } from '@filters/interfaces/FiltersLabels.interface';
export const FiltersLabelsES: FilterLabelsInterface = {
	labels: {
		date: 'Fechas',
		favorite: 'Favoritos Awake',
		typeOfExperience: 'Tipo de experiencias',
		destinations: 'Destinos',
		durationOfTravel: 'Duración del viaje',
		rangeOfPrice: 'Rango de precio',
		quantityOfTravelers: 'Cantidad de viajeros',
		sealsOfImpact: 'Sellos de impacto',
		ecosystem: 'Ecosistemas',
		physicalDemand: 'Exigencia física'
	},
	icons: {
		date: 'icon-calendar_add_on',
		favorite: 'icon-flash_on',
		typeOfExperience: 'icon-sentiment_very_satisfied',
		destinations: 'icon-forest',
		durationOfTravel: 'icon-alarm',
		rangeOfPrice: 'icon-attach_money',
		quantityOfTravelers: 'icon-group',
		sealsOfImpact: 'icon-mark',
		ecosystem: 'icon-water',
		physicalDemand: 'icon-sports_martial_arts'
	},
	components: {
		rangeInstruction: 'Desliza el círculo a la derecha',
		clearFilter: 'Limpiar filtro ',
		travelers: 'Viajeros',
		calendarSelectADate: 'Elige una fecha',
		joinAGroup: 'Únete a un grupo',
		soonYear: 'Próximos',
		awakeRecommended: 'Recomendado por Awake',
		immediatelyReserves: 'Reservas inmediatas',
		environmentalLeaders: 'Líderes ambientales',
		socialLeaders: 'Líderes sociales',
		daysLabel: 'Días',
		lowDemand: 'Baja',
		highDemand: 'Alta',
		demandOne:
			'Actividades fáciles, sin grandes pendientes, altitud ni dificultad para la respiración. Recorridos en vehículo en su mayoría. Caminatas de máximo 1 hora.',
		demandTwo:
			'Actividades físicas suaves para personas con buen estado de salud. Terrenos planos en su mayoría y caminatas de 1 a 3 horas.',
		demandThree:
			'Actividades para personas activas con un poco de experiencia en terrenos de montaña. Expediciones con terrenos empinados y cambios de altura entre 100 y 400 metros. Caminatas de 3 a 5 horas.',
		demandFour:
			'Actividades que requieren esfuerzo físico, resistencia y condiciones óptimas de salud. Es necesario cargar equipos y alimentos. Cambios de altura entre 400 y 600 metros, y recorridos de 5 a 6 horas.',
		demandFive:
			'Actividades en terrenos complicados, pantanosos o con obstáculos. Posibles condiciones climáticas adversas y necesidad de cargar equipos y alimentos. Alturas superiores a 3500 metros y recorridos de más de 6 horas.',
		selectedText: 'Escogiste'
	},
	searchResultSection: {
		bestExperiences: 'Las mejores experiencias de naturaleza en ',
		colombiaLabel: 'Colombia',
		subtitle:
			'Viaja mientras generas un impacto social, ambiental y económico en las comunidades',
		result: 'Resultados',
		filterYourSearch: 'Filtra tu búsqueda',
		short: 'Ordenar',
		searchADestination: 'Buscar un destino',
		orderPriceMinorToMajor: 'Precio (de menor a mayor)',
		orderPriceMajorToMinor: 'Precio (de mayor a menor',
		orderDurationMinorToMajor: 'Duration (de menor a mayor)',
		orderDurationMajorToMinor: 'Duration (de mayor a menor',
		cleanFilter: 'Limpiar filtro'
	},
	resultsSection: {
		comments: 'comentarios',
		copMoney: 'COP',
		cantFind: '¿No encuentras lo que buscas?',
		fixYourSearch:
			'Ajusta tu búsqueda con nuestros filtros especializados, para hacer tu busqueda más efectiva.',
		filterMySearch: 'Filtrar mi busqueda',
		filterLabel: 'Filtros',
		viewMore: 'Ver más',
		empty: 'No tenemos experiencias para ese filtro',
		emptyDescription: 'Utiliza filtros más generales, para hacer tu busqueda más efectiva.'
	}
};
export const FiltersLabelsEN: FilterLabelsInterface = {
	labels: {
		date: 'Dates',
		favorite: 'Awake Favorites',
		typeOfExperience: 'Type of experiences',
		destinations: 'Destinations',
		durationOfTravel: 'Duration of the trip',
		rangeOfPrice: 'Price range',
		quantityOfTravelers: 'Number of travelers',
		sealsOfImpact: 'Impact seals',
		ecosystem: 'Ecosystems',
		physicalDemand: 'Physical demand'
	},
	icons: {
		date: 'icon-calendar_add_on',
		favorite: 'icon-flash_on',
		typeOfExperience: 'icon-sentiment_very_satisfied',
		destinations: 'icon-forest',
		durationOfTravel: 'icon-alarm',
		rangeOfPrice: 'icon-attach_money',
		quantityOfTravelers: 'icon-group',
		sealsOfImpact: 'icon-mark',
		ecosystem: 'icon-water',
		physicalDemand: 'icon-sports_martial_arts'
	},
	components: {
		rangeInstruction: 'Slide circle to the right',
		clearFilter: 'Clean filter',
		travelers: 'Travelers',
		calendarSelectADate: 'Select a date',
		joinAGroup: 'Join a group',
		soonYear: 'Next',
		awakeRecommended: 'Recommended by Awake',
		immediatelyReserves: 'Immediate booking',
		environmentalLeaders: 'Environmental leaders',
		socialLeaders: 'Social leaders',
		daysLabel: 'Days',
		lowDemand: 'Low',
		highDemand: 'High',
		demandOne:
			'Easy activities, without steep slopes, altitude or breathing difficulties. Mostly by vehicle. Hikes of maximum 1 hour.',
		demandTwo:
			'Mild physical activities for people in good health. Mostly flat terrain and walks of 1 to 3 hours.',
		demandThree:
			'Activities for active people with some experience in mountain terrain. Expeditions with steep terrain and altitude changes between 100 and 400 meters. Hikes from 3 to 5 hours.',
		demandFour:
			'Activities that require physical effort, endurance and optimal health conditions. It is necessary to carry equipment and food. Changes in altitude between 400 and 600 meters, and 5 to 6 hours of trekking.',
		demandFive:
			'Activities in complicated terrain, swampy or with obstacles. Possible adverse weather conditions and the need to carry equipment and food. Altitudes over 3500 meters and treks of more than 6 hours.',
		selectedText: 'Chose'
	},
	searchResultSection: {
		bestExperiences: 'Best nature experiences in',
		colombiaLabel: 'Colombia',
		subtitle: 'Travel while making a social, environmental and economic impact on communities',
		result: 'Results',
		filterYourSearch: 'Filter your search',
		short: 'Order at',
		searchADestination: 'Search for a destination',
		orderPriceMinorToMajor: 'Price (lowest to highest)',
		orderPriceMajorToMinor: 'Price (high to low)',
		orderDurationMinorToMajor: 'Duration (from low to high)',
		orderDurationMajorToMinor: 'Duration (highest to lowest)',
		cleanFilter: 'Clean filter'
	},
	resultsSection: {
		comments: 'comments',
		copMoney: 'USS',
		cantFind: '¿Cant find what youre looking for?',
		fixYourSearch:
			'Adjust your search with our specialized filters, to make your search more effective.',
		filterMySearch: 'Filter my search',
		filterLabel: 'Filters',
		viewMore: 'View more',
		empty: 'We don\'t have a experiences for that filter',
		emptyDescription: 'Use more general filters to make your search more effective.'
	}
};
