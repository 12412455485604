import type { BaseScreen } from '@shared/interfaces/Screen.interface';
import { useResponsive } from '@shared/services/utils/Utils.services';
import { ScreenBase } from './ScreenBase.component';

export function ScreenDimensions({
  mobile: showOnMobile = false,
  tablet: showOnTablet = false,
  desktop: showOnDesktop = false,
  ...base
}: BaseScreen & {
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
}): JSX.Element {
  const { isMobile, isTablet, isDesktop } = useResponsive();

  const showMobile = showOnMobile && isMobile;
  const showTablet = showOnTablet && isTablet;
  const showDesktop = showOnDesktop && isDesktop;

  const show = [showMobile, showTablet, showDesktop].includes(true);

  return (
    <>
      {show && <ScreenBase {...base}></ScreenBase>}
    </>
  );
}