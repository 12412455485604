import type { FormValidationsLabelsInterface } from '@shared/interfaces/FormValidationLabels.interface';

export const FormValidationsLabelsES: FormValidationsLabelsInterface = {
    onlyLetter: 'Este campo solo permite ingreso de letras',
    onlyAlphanumeric: 'Este campo permite el ingreso de números y letras',
    emailFormatValidation: 'Email inválido',
    numeric: 'Este campo solo admite números',
    minLength: 'es la cantidad mínima de caracteres',
    maxLength: 'es la cantidad máxima de caracteres',
    uniqueLength: 'Este campo debe contener',
    requiredField: 'El campo es requerido',
    requiredTerms: 'Debes aceptar los términos y condiciones',
    mustMatch: 'no coinciden. Por favor verifica e intentalo de nuevo',
    invalid: 'El campo es inválido',
    recaptcha: 'Confirma que no eres un robot',
    passwordFormatValidation:
        'La contraseña debe contener mínimo una letra mayúscula, una letra minúscula, un número y un caracter especial [!@#$%^&*]'
};

export const FormValidationsLabelsEN: FormValidationsLabelsInterface = {
    onlyLetter: 'Este campo solo permite ingreso de letras',
    onlyAlphanumeric: 'Este campo solo permite ingreso de letras',
    emailFormatValidation: 'Invalid email address',
    numeric: 'This field only accepts numbers',
    minLength: '{length} is the minimum quantity',
    maxLength: '{length} is the maximum quantity',
    uniqueLength: 'Este campo debe contener',
    requiredField: 'Field is required',
    requiredTerms: 'You must accept the terms and conditions',
    mustMatch: 'do not match. Please check and try again',
    invalid: 'Field is invalid',
    recaptcha: 'Confirm you are not a robot',
    passwordFormatValidation:
        'Password must contain a minimum uppercase letter, a lowercase letter, a number, and a special character [!@#$%^&*]'
};
