import { SessionStorageKeys, Storage } from '@shared/enums/SessionStorageKeys.enum';
import type { AuthPageResponseDataInterface } from '@shared/services/auth-service/interfaces/AuthPageResult.interface';
import type {
    ProfileDataInterfaces
} from '@profile/interface/ProfileUserData.interface';

function getStorage(storage: Storage): globalThis.Storage {
    if (storage === Storage.Local) {
        return localStorage
    }

    return sessionStorage;
}

export function getSessionStorageItem(sessionKey: SessionStorageKeys | any, storageType: Storage = Storage.Session): any {
    const storage = getStorage(storageType);

    let currentSessionData: any = storage.getItem(SessionStorageKeys.sessionStorageKey)
        ? storage.getItem(SessionStorageKeys.sessionStorageKey)
        : null;
    currentSessionData = currentSessionData ? JSON.parse(currentSessionData) : null;
    return currentSessionData ? currentSessionData[sessionKey] : null;
}

export function setSessionStorageItem(sessionKey: SessionStorageKeys | any, value: any, storageType: Storage = Storage.Session): void {
    const storage = getStorage(storageType);

    const currentSessionData: any = storage.getItem(SessionStorageKeys.sessionStorageKey);
    const newData = currentSessionData ? JSON.parse(currentSessionData) : {};
    newData[sessionKey] = value;
    storage.setItem(SessionStorageKeys.sessionStorageKey, JSON.stringify(newData));
}

export function getAuthSession(): any {
    return getSessionStorageItem(SessionStorageKeys.authUser, Storage.Local);
}

export function getSessionStorage(storageType: Storage = Storage.Session): any {
    const storage = getStorage(storageType);

    const currentSessionData: any = storage.getItem(SessionStorageKeys.sessionStorageKey)
        ? storage.getItem(SessionStorageKeys.sessionStorageKey)
        : null;
    return currentSessionData ? JSON.parse(currentSessionData) : null;
}

export function clearUserAuth(): void {
    const currentSessionData = getSessionStorage(Storage.Local);
    delete currentSessionData.authUser;
    localStorage.setItem(
        SessionStorageKeys.sessionStorageKey,
        JSON.stringify(currentSessionData)
    );
}

export function clearSessionStorage(): void {
    sessionStorage.removeItem(SessionStorageKeys.sessionStorageKey);
    localStorage.removeItem(SessionStorageKeys.sessionStorageKey);
}

export function clearSessionStorageClient(): void {
    sessionStorage.removeItem(SessionStorageKeys.sessionStorageClientKey);
}

export function setAuthUser(result: AuthPageResponseDataInterface): { success: boolean } {
    try {
        const authUser = {
            accessToken: result.access_token,
            tokenType: result.token_type,
            expiresIn: formatExpiresIn(result.expires_in)
        };
        setSessionStorageItem(SessionStorageKeys.authUser, authUser, Storage.Local);
        return { success: true };
    } catch (error) {
        return { success: false };
    }
}

export function setUserData(result: ProfileDataInterfaces): void {
    const authUser = getSessionStorage(Storage.Local).authUser;
    authUser.username = result.name;
    authUser.email = result.email;
    authUser.phone = result.phone;
    authUser.id = result.id;
    
    setSessionStorageItem(SessionStorageKeys.authUser, authUser, Storage.Local);
}

export function getSessionStorageClientItem(sessionKey: SessionStorageKeys): any {
    let currentSessionData: any = sessionStorage.getItem(SessionStorageKeys.sessionStorageClientKey)
        ? sessionStorage.getItem(SessionStorageKeys.sessionStorageClientKey)
        : null;
    currentSessionData = currentSessionData ? JSON.parse(currentSessionData) : null;
    return currentSessionData ? currentSessionData[sessionKey] : null;
}

export function setSessionStorageClientItem(sessionKey: SessionStorageKeys, value: any): void {
    const currentSessionData: any = sessionStorage.getItem(
        SessionStorageKeys.sessionStorageClientKey
    );
    const newData = currentSessionData ? JSON.parse(currentSessionData) : {};
    newData[sessionKey] = value;
    sessionStorage.setItem(SessionStorageKeys.sessionStorageClientKey, JSON.stringify(newData));
}

export function setClientAuth(data: AuthPageResponseDataInterface): void {
    setSessionStorageClientItem(SessionStorageKeys.accessToken, data.access_token);
    setSessionStorageClientItem(SessionStorageKeys.tokenType, data.token_type);
    setSessionStorageClientItem(SessionStorageKeys.expiresIn, formatExpiresIn(data.expires_in));
}

function formatExpiresIn(seconds: number): number {
    const date = new Date();
    return date.setSeconds(date.getSeconds() + seconds);
}
