import i18n, { type Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';

const isProduction = process.env.NODE_ENV === 'production';

const files = [
	'home',
	'magicFinder',
	'error',
	'whatsapp',
	'experience',
	'destination',
	'personalizedTrip',
	'giftCard',
	'validations',
	'payment',
	'cookies',
	'dataProcessing',
	'footer',
	'methodOfPAyment',
	'reserveSummary',
	'feedback',
	'stickies',
	'experiments',
	'quoter',
	'valueProposition',
	'joinTheGroup',
	'journey',
	'experienceCard',
	'summary',
	'addons',
	'stickyFooter',
	'booking',
	'global',
	'profile',
	'header',
	'landingProposed'
];

const resources = (): Resource => {
	const resource: Resource = {};

	const langs = ['es', 'en'];

	langs.forEach((lang) => {
		const translation: any = {};

		files.forEach((file) => {
			translation[file] = require(`./locales/${lang}/${file}.json`);
		});

		resource[lang] = {
			translation
		};
	});

	return resource;
};

void i18n
	.use(initReactI18next)
	.use(intervalPlural)
	.init({
		resources: resources(),
		debug: !isProduction,
		lng: 'es',
		fallbackLng: 'es',
		interpolation: {
			escapeValue: false // No escapar HTML
		}
	});
